import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Col, Row, DatePicker, Button, Input, Select,
} from 'antd';


const { RangePicker } = DatePicker;
const { Search } = Input;
const { Option } = Select;

function Filters({
  periodFrom, periodTo, onChangePeriod, isInitLoading, onChageSearch, shops, selectedShopArray, onChangeShop, selectedMarketplaces,
  onChangeMarketplace,
}) {
  const [dates, setDates] = useState([]);
  const [hackValue, setHackValue] = useState();
  const [value, setValue] = useState();
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setValue([]);
      setHackValue([]);
      setDates([]);
    } else {
      setHackValue(undefined);
    }
  };
  const isSevenDays = periodFrom.isSame(moment().subtract(7, 'day'), 'day') && periodTo.isSame(moment(), 'day');
  const isFourteenDays = periodFrom.isSame(moment().subtract(14, 'day'), 'day') && periodTo.isSame(moment(), 'day');
  const isThirtyDays = periodFrom.isSame(moment().subtract(30, 'day'), 'day') && periodTo.isSame(moment(), 'day');
  const isNinetyDays = periodFrom.isSame(moment().subtract(90, 'day'), 'day') && periodTo.isSame(moment(), 'day');
  return (
    <Col span={24}>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col>
          <Select
            style={{ width: '20vw' }}
            disabled={isInitLoading}
            value={selectedShopArray}
            showSearch
            allowClear
            mode="multiple"
            maxTagCount="responsive"
            onChange={onChangeShop}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {shops.map((u) => <Option key={u.id} value={u.id}>{u.name}</Option>)}
          </Select>
        </Col>
        <Col>
          <Select
            style={{ width: '20vw' }}
            disabled={isInitLoading}
            value={selectedMarketplaces}
            mode="multiple"
            maxTagCount="responsive"
            onChange={onChangeMarketplace}
          >
            {['Ozon', 'Wildberries'].map((u) => <Option key={u} value={u}>{u}</Option>)}
          </Select>
        </Col>
      </Row>
      <Row gutter={20} style={{ marginBottom: '10px' }}>
        <Col>
          <Button
            disabled={isInitLoading}
            type={isSevenDays ? 'primary' : 'default'}
            onClick={() => onChangePeriod(moment().subtract(7, 'day').startOf('day'), moment().startOf('day'))}
          >
            7 дней
          </Button>
        </Col>
        <Col>
          <Button
            disabled={isInitLoading}
            type={isFourteenDays ? 'primary' : 'default'}
            onClick={() => onChangePeriod(moment().subtract(14, 'day').startOf('day'), moment().startOf('day'))}
          >
            14 дней
          </Button>
        </Col>
        <Col>
          <Button
            disabled={isInitLoading}
            type={isThirtyDays ? 'primary' : 'default'}
            onClick={() => onChangePeriod(moment().subtract(30, 'day').startOf('day'), moment().startOf('day'))}
          >
            30 дней
          </Button>
        </Col>
        <Col>
          <Button
            disabled={isInitLoading}
            type={isNinetyDays ? 'primary' : 'default'}
            onClick={() => onChangePeriod(moment().subtract(90, 'day').startOf('day'), moment().startOf('day'))}
          >
            90 дней
          </Button>
        </Col>
        <Col>
          <RangePicker
            defaultValue={[periodFrom, periodTo]}
            showTime={false}
            format="YYYY-MM-DD"
            allowClear={false}
            disabled={isInitLoading}
            value={hackValue || value}
            disabledDate={disabledDate}
            onCalendarChange={(val) => {
              setDates(val);
              if (val.length === 2 && !!val[0] && !!val[1]) {
                onChangePeriod(
                  moment(val[0].startOf('day').format('YYYY-MM-DD')),
                  moment(val[1].add(1, 'day').startOf('day').format('YYYY-MM-DD')),
                );
              }
            }}
            onChange={(val) => setValue(val)}
            onOpenChange={onOpenChange}
          />
        </Col>
      </Row>
      <Row>
        <Search
          placeholder="название или артикул"
          onChange={onChageSearch}
          disabled={isInitLoading}
        />
      </Row>
    </Col>
  );
}

Filters.propTypes = {
  periodTo: PropTypes.shape().isRequired,
  periodFrom: PropTypes.shape().isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  shops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShopArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
  selectedMarketplaces: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeMarketplace: PropTypes.func.isRequired,
};


export default Filters;

