import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card } from 'antd';
import './TurnoverDesktop.css';
import Filters from './components/Filters/Filters';
import Widgets from './components/Widgets/Widgets';
import HistoryStocksChart from './components/HistoryStocksChart/HistoryStocksChart';
import TurnoverTable from './components/TurnoverTable/TurnoverTable';


function TurnoverDesktop({
  isLoading, periodFrom, periodTo, onChangePeriod, onChageSearch, shops, selectedShopArray, onChangeShop, selectedMarketplaces, onChangeMarketplace,
  widgetData, historyChartData, turnoverTableDatasource,
}) {
  return (
    <Col className="turnoverDesktop-wrapper">
      <Row className="turnoverDesktop-title">Оборачиваемость</Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Filters
            periodFrom={periodFrom}
            periodTo={periodTo}
            onChangePeriod={onChangePeriod}
            isInitLoading={isLoading}
            onChageSearch={onChageSearch}
            shops={shops}
            selectedShopArray={selectedShopArray}
            onChangeShop={onChangeShop}
            selectedMarketplaces={selectedMarketplaces}
            onChangeMarketplace={onChangeMarketplace}
          />
        </Card>
      </Row>
      <Widgets
        widgetData={widgetData}
        isLoading={isLoading}
      />
      <HistoryStocksChart dataSource={historyChartData} />
      <TurnoverTable dataSource={turnoverTableDatasource} isLoading={isLoading} />
    </Col>
  );
}

TurnoverDesktop.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  periodTo: PropTypes.shape().isRequired,
  periodFrom: PropTypes.shape().isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  shops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShopArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
  selectedMarketplaces: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeMarketplace: PropTypes.func.isRequired,
  widgetData: PropTypes.shape().isRequired,
  historyChartData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  turnoverTableDatasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default TurnoverDesktop;
