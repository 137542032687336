import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Form, Modal, Button, Input, Col, DatePicker, Radio,
} from 'antd';


const { TextArea } = Input;
function CommentModal({
  saveProductComment, cancelProductComment, isLoading, productCommentInEdit, productCommentRef,
}) {
  return (
    <Modal
      title="Рассылка"
      onCancel={cancelProductComment}
      visible
      width="80vw"
      bodyStyle={{ padding: '20px' }}
      maskClosable
      footer={[
        <Button type="primary" onClick={saveProductComment} disabled={isLoading}>Сохранить</Button>,
      ]}
    >
      <Form ref={productCommentRef} layout="vertical" style={{ width: '100%' }}>
        <Col span={12}>
          <Form.Item name="id" initialValue={productCommentInEdit.id} hidden />
          {!productCommentInEdit.id && (
          <Form.Item name="products" initialValue="solo" style={{ marginBottom: '10px' }}>
            <Radio.Group buttonStyle="solid">
              <Radio.Button value="solo">Только к этому</Radio.Button>
              <Radio.Button value="all">Ко всем связанным</Radio.Button>
            </Radio.Group>
          </Form.Item>
          )}
          <Form.Item
            name="date"
            initialValue={productCommentInEdit.date ? moment(productCommentInEdit.date, 'DD.MM.YYYY') : null}
            label="Дата:"
          >
            <DatePicker style={{ width: '100%' }} disabled={isLoading} />
          </Form.Item>
          <Form.Item
            name="comment"
            initialValue={productCommentInEdit.comment}
            label="Комментарий:"
            style={{ marginTop: '20px' }}
          >
            <TextArea style={{ width: '100%' }} disabled={isLoading} />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
}

CommentModal.propTypes = {
  productCommentInEdit: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  cancelProductComment: PropTypes.func.isRequired,
  saveProductComment: PropTypes.func.isRequired,
  productCommentRef: PropTypes.shape().isRequired,
};


export default CommentModal;

