import React from 'react';
import {
  DashboardOutlined, DatabaseOutlined, SettingOutlined, CalculatorOutlined, SyncOutlined,
} from '@ant-design/icons';
import { routes } from '../../../utils/routes';


const buttonsArray = [
  {
    name: 'Дашборд',
    id: 'dashboard',
    icon: <DashboardOutlined />,
    route: routes.dashboard,
  },
  {
    name: 'Товары',
    id: 'productCatalogue',
    icon: <DatabaseOutlined />,
    route: routes.productCatalogue,
  },
  {
    name: 'Управление',
    id: 'administration',
    icon: <SettingOutlined />,
    route: routes.administration,
  },
  {
    name: 'Калькулятор',
    id: 'priceCalculator',
    icon: <CalculatorOutlined />,
    route: routes.priceCalculator,
  },
  {
    name: 'Оборачиваемость',
    id: 'turnover',
    icon: <SyncOutlined />,
    route: routes.turnover,
  },
  // {
  //   name: 'Расчет',
  //   id: 'calculation',
  //   icon: <ControlOutlined />,
  //   route: routes.calculation,
  // },
  // {
  //   name: 'Рассылка',
  //   id: 'mailing',
  //   icon: <InboxOutlined />,
  //   route: routes.mailing,
  // },
];

export default buttonsArray;
