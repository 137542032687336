/* eslint-disable max-len */
import React, { Component } from 'react';
import { Col } from 'antd';
import { config } from '../../utils/config';
import CalculationPageDesktop from './Desktop/CalculationPageDesktop';


class CalculationModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
    };
  }

  render() {
    const {
      isMobile,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <Col span={23}>
        <CalculationPageDesktop />
      </Col>
    );
  }
}


export default CalculationModule;
