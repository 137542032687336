const fbsCourierTarifs = [
  {
    minAmount: 1,
    maxAmount: 10,
    value: 750,
    fixedCoefficient: 0,
  },
  {
    minAmount: 11,
    maxAmount: 50,
    value: 350,
    fixedCoefficient: 25,
  },
  {
    minAmount: 51,
    maxAmount: 200,
    value: 150,
    fixedCoefficient: 25,
  },
  {
    minAmount: 201,
    maxAmount: 9999999999,
    value: 0,
    fixedCoefficient: 25,
  },
];

export default fbsCourierTarifs;

