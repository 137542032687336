/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Avatar, Col, Row, Card, Input, Popover, Button,
} from 'antd';
import ozonLogo from '../../../assets/icons/ozon.jpeg';
import wbLogo from '../../../assets/icons/wb.jpeg';


function Calculator({
  product, isInitLoading, onChangePrice, onChangeOtherCosts, onSaveCalculator, loading,
}) {
  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      width: 100,
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      width: 300,
    },
    {
      title: 'Себестоимость',
      dataIndex: 'netCost',
      key: 'netCost',
      width: 200,
      render: (text) => `${text.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`,
    },
    {
      title: '',
      dataIndex: 'mpLogos',
      key: 'mpLogos',
      width: 50,
      render: () => (
        <Col span={24}>
          <Row wrap={false}>
            <Col><img src={ozonLogo} alt="логотип" style={{ width: '30px', height: '30px', marginRight: '10px' }} /></Col>
          </Row>
          <Row style={{ marginTop: '10px' }} wrap={false}>
            <Col><img src={wbLogo} alt="логотип" style={{ width: '30px', height: '30px', marginRight: '10px' }} /></Col>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Текущая цена',
      dataIndex: 'price',
      key: 'price',
      width: 150,
      render: (_, object) => (
        <Col span={24}>
          <Row wrap={false}>
            <Col style={{ lineHeight: '30px' }}>{`${object.priceOzon.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
          </Row>
          <Row style={{ marginTop: '10px' }} wrap={false}>
            <Col style={{ lineHeight: '30px' }}>{`${object.priceWB.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Расчетная цена',
      dataIndex: 'priceCalculated',
      key: 'priceCalculated',
      width: 200,
      render: (_, object) => (
        <Col span={24}>
          <Row gutter={8} style={{ width: '100%', alignItems: 'center' }}>
            <Input
              disabled={!object.productIdOzon || loading}
              style={{ width: '100%' }}
              value={object.priceCalculatedOzon}
              onChange={(e) => onChangePrice(e.target.value, object, 'Ozon')}
            />
          </Row>
          <Row gutter={8} style={{ width: '100%', alignItems: 'center', marginTop: '10px' }}>
            <Input
              disabled={!object.productIdWB || loading}
              style={{ width: '100%' }}
              value={object.priceCalculatedWB}
              onChange={(e) => onChangePrice(e.target.value, object, 'WB')}
            />
          </Row>
        </Col>
      ),
    },
    {
      title: 'Другие расходы',
      dataIndex: 'otherCosts',
      key: 'otherCosts',
      width: 200,
      render: (_, object) => (
        <Col span={24}>
          <Row gutter={8} style={{ width: '100%', alignItems: 'center' }}>
            <Col span={20}>
              <Input
                disabled={!object.productIdOzon || loading}
                style={{ width: '100%' }}
                value={object.otherCostsOzon}
                onChange={(e) => onChangeOtherCosts(e.target.value, object, 'Ozon')}
              />
            </Col>
          </Row>
          <Row gutter={8} style={{ width: '100%', alignItems: 'center', marginTop: '10px' }}>
            <Col span={20}>
              <Input
                disabled={!object.productIdWB || loading}
                style={{ width: '100%' }}
                value={object.otherCostsWB}
                onChange={(e) => onChangeOtherCosts(e.target.value, object, 'WB')}
              />
            </Col>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Сборы',
      dataIndex: 'commissionsTotal',
      key: 'commissionsTotal',
      width: 120,
      render: (_, object) => (
        <Col span={24}>
          <Row wrap={false}>
            <Popover
              content={(
                <Col span={24} style={{ width: '13vw' }}>
                  <Row justify="space-between">
                    <Col>Комиссия:</Col>
                    <Col>{`${object.commissionsValueOzon.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Логистика:</Col>
                    <Col>{`${object.commissionsLogisticsOzon.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Последняя миля:</Col>
                    <Col>{`${object.commissionsLastMileOzon.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                </Col>
    )}
              title="Сборы на МП"
            >
              <Col style={{ lineHeight: '30px', color: '#1890ff' }}>
                {`${object.commissionsTotalOzon.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}
              </Col>
            </Popover>
          </Row>
          <Row style={{ marginTop: '10px' }} wrap={false}>
            <Popover
              content={(
                <Col span={24} style={{ width: '13vw' }}>
                  <Row justify="space-between">
                    <Col>Комиссия:</Col>
                    <Col>{`${object.commissionsValueWB.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Логистика:</Col>
                    <Col>{`${object.commissionsLogisticsWB.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Хранение:</Col>
                    <Col>{`${object.commissionsStorageWB.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                </Col>
    )}
              title="Сборы на МП"
            >
              <Col style={{ lineHeight: '30px', color: '#1890ff' }}>{`${object.commissionsTotalWB.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
            </Popover>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Наценка',
      dataIndex: 'margin',
      key: 'margin',
      width: 120,
      render: (_, object) => (
        <Col span={24}>
          <Row wrap={false}>
            <Col style={{ lineHeight: '30px' }}>{`${object.marginOzon}%`}</Col>
          </Row>
          <Row style={{ marginTop: '10px' }} wrap={false}>
            <Col style={{ lineHeight: '30px' }}>{`${object.marginWB}%`}</Col>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Прибыль',
      dataIndex: 'profit',
      key: 'profit',
      width: 120,
      render: (_, object) => (
        <Col span={24}>
          <Row wrap={false}>
            <Col style={{ lineHeight: '30px' }}>{`${object.profitOzon.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
          </Row>
          <Row style={{ marginTop: '10px' }} wrap={false}>
            <Col style={{ lineHeight: '30px' }}>{`${object.profitWB.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
          </Row>
        </Col>
      ),
    },
  ];

  return (
    <Card style={{
      borderRadius: '5px', width: '100%', margin: '20px 0px',
    }}
    >
      <div style={{ fontSize: '24px', color: '#212121', marginLeft: '16px' }}>Калькулятор</div>
      <Table columns={columns} dataSource={product ? [product] : []} rowKey="id" loading={isInitLoading} pagination={false} />
      <Row justify="end"><Button type="primary" style={{ marginTop: '20px' }} disabled={loading} onClick={onSaveCalculator}>Применить</Button></Row>
    </Card>
  );
}

Calculator.propTypes = {
  product: PropTypes.shape(),
  isInitLoading: PropTypes.bool.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  onChangeOtherCosts: PropTypes.func.isRequired,
  onSaveCalculator: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

Calculator.defaultProps = {
  product: null,
};

export default Calculator;

