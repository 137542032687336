import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card, Statistic,
} from 'antd';


function Widgets({
  widgetData, isLoading,
}) {
  return (
    <Row gutter={10}>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic title="Остатков Airtable, руб" value={widgetData.airtableLeft} valueStyle={{ fontSize: '16px' }} loading={isLoading} />
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic title="Остатков Ozon, руб" value={widgetData.ozonLeft} valueStyle={{ fontSize: '16px' }} loading={isLoading} />
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic title="Остатков WB, руб" value={widgetData.wbLeft} valueStyle={{ fontSize: '16px' }} loading={isLoading} />
        </Card>
      </Col>
    </Row>
  );
}

Widgets.propTypes = {
  widgetData: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default Widgets;
