/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Col, notification } from 'antd';
import PriceCalculatorPageDesktop from './Desktop/PriceCalculatorPageDesktop';
import {
  getAllProductsCatalogueFull, getAllShopsCatalogue, editProductOzon, editProductWB, getAllProductsOzon, getAllProductsWB,
  getAllShopsOzon, getAllShopsWB,
} from '../../utils/http';
import formulaWB from './utils/formulaWb';
import formulaOzon from './utils/formulaOzon';
import { routeGenerator } from '../../utils/routes';
import createXLSDocument from './utils/createXLSDocument';


function PriceCalculatorModule({ history }) {
  const [isLoading, setIsLoading] = useState(true);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const [shops, setShops] = useState([]);
  const [selectedShops, setSelectedShops] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [productsWB, setProductsWB] = useState([]);
  const [productsOzon, setProductsOzon] = useState([]);
  const [updatedObjectsOzon, setUpdatedObjectsOzon] = useState([]);
  const [updatedObjectsWB, setUpdatedObjectsWB] = useState([]);
  const [shopsMarketplace, setShopsMarketplace] = useState([]);
  const [selectedShopMarketplace, setSelectedShopMarketplace] = useState([]);


  const parseData = (productsAirtable, innerProductsOzon, innerProductsWB, innerSearchValue, innerSelectedShops, innerSelectedShopMarketplace) => {
    const parsedProducts = [];
    productsAirtable
      .filter((p) => innerSelectedShops.length === 0 || innerSelectedShops.includes(p.shopId))
      .filter((element) => element.name.toLowerCase().indexOf(innerSearchValue.toLowerCase()) >= 0
            || element.inventoryNumber.toLowerCase().indexOf(innerSearchValue.toLowerCase()) >= 0)
      .forEach((product) => {
        const linkedProductsWb = innerProductsWB.filter((p) => p.productCatalogueId === product.id);
        const linkedProductsOzon = innerProductsOzon.filter((p) => p.productCatalogueId === product.id);

        parsedProducts.push({
          ...product,
          linkedProducts: [...linkedProductsOzon, ...linkedProductsWb],
        });
      });
    return parsedProducts.filter((p) => innerSelectedShopMarketplace.length === 0 || innerSelectedShopMarketplace.some((r) => p.linkedProducts.map((lp) => lp.shopName).includes(r)));
  };

  useEffect(() => {
    Promise.all([getAllProductsCatalogueFull(), getAllShopsCatalogue(), getAllProductsOzon(), getAllProductsWB(),
      getAllShopsOzon(), getAllShopsWB()])
      .then((result) => {
        const parsedData = parseData(result[0], result[2], result[3], searchValue, selectedShops, selectedShopMarketplace);

        setIsLoading(false);
        setOriginalProducts(result[0]);
        setDatasource(parsedData);
        setShops(result[1]);
        setProductsOzon(result[2]);
        setProductsWB(result[3]);
        setShopsMarketplace([...result[4], ...result[5]].map((s) => s.name));
      });
  }, []);

  const onRedirectProduct = (productId) => {
    history.push(routeGenerator.product(productId));
  };

  const onChangeBrand = (selectedShopArray) => {
    const parsedData = parseData(originalProducts, productsOzon, productsWB, searchValue, selectedShopArray, selectedShopMarketplace);
    setDatasource(parsedData);
    setSelectedShops(selectedShopArray);
  };

  const onChangeShop = (selectedShopMarketplaceArray) => {
    const parsedData = parseData(originalProducts, productsOzon, productsWB, searchValue, selectedShops, selectedShopMarketplaceArray);
    setDatasource(parsedData);
    setSelectedShopMarketplace(selectedShopMarketplaceArray);
  };

  const onChageSearch = (e) => {
    const { value } = e.target;
    const parsedData = parseData(originalProducts, productsOzon, productsWB, value, selectedShops, selectedShopMarketplace);
    setDatasource(parsedData);
    setSearchValue(value);
  };

  const onRecalculateObject = (price, otherCosts, airtableObject, shop, shopObject) => {
    let updatedObject = {
      id: shopObject.id,
    };
    if (shop === 'WB') {
      updatedObject = {
        ...updatedObject,
        ...formulaWB(parseFloat(price || 0), parseFloat(otherCosts || 0), shopObject.commissionsPercent, airtableObject),
      };
      setUpdatedObjectsWB(
        updatedObjectsWB.map((o) => o.id).includes(shopObject.id)
          ? updatedObjectsWB.map((u) => (u.id === updatedObject.id ? updatedObject : u))
          : [...updatedObjectsWB, updatedObject],
      );
    }

    if (shop === 'Ozon') {
      updatedObject = {
        ...updatedObject,
        ...formulaOzon(parseFloat(price || 0), parseFloat(otherCosts || 0), shopObject.commissionsPercent, airtableObject),
      };
      setUpdatedObjectsOzon(
        updatedObjectsOzon.map((o) => o.id).includes(shopObject.id)
          ? updatedObjectsOzon.map((u) => (u.id === updatedObject.id ? updatedObject : u))
          : [...updatedObjectsOzon, updatedObject],
      );
    }
    // return updatedObject;
    setDatasource(datasource.map((d) => ({
      ...d,
      linkedProducts: d.linkedProducts.map((l) => l.id).includes(shopObject.id)
        ? d.linkedProducts.map((l) => (l.id === shopObject.id ? { ...shopObject, ...updatedObject } : l))
        : d.linkedProducts,
    })));
  };

  const onSaveProductCalculations = () => {
    setIsLoading(true);
    Promise.all([editProductOzon(updatedObjectsOzon), editProductWB(updatedObjectsWB)])
      .then(() => {
        setIsLoading(false);
        setUpdatedObjectsOzon([]);
        setUpdatedObjectsWB([]);
        notification.success({ message: 'Успех', description: 'Изменения сохранены!' });
      });
  };

  // const updateAllPrices = () => {
  //   const updatedObjectsOzonNew = [];
  //   const updatedObjectsWBNew = [];

  //   datasource.forEach((product) => {
  //     product.linkedProducts.forEach((linkedProduct) => {
  //       const updatedObject = onRecalculateObject(linkedProduct.price, linkedProduct.otherCosts, product, linkedProduct.shop, linkedProduct);
  //       if (linkedProduct.shop === 'WB') {
  //         updatedObjectsWBNew.push(updatedObject);
  //       }
  //       if (linkedProduct.shop === 'Ozon') {
  //         updatedObjectsOzonNew.push(updatedObject);
  //       }
  //     });
  //   });
  //   setUpdatedObjectsOzon(updatedObjectsOzonNew);
  //   setUpdatedObjectsWB(updatedObjectsWBNew);
  // };

  const onExportXLS = async () => {
    // updateAllPrices();
    createXLSDocument(datasource);
  };

  return (
    <Col span={23}>
      <PriceCalculatorPageDesktop
        isLoading={isLoading}
        datasource={datasource}
        selectedShops={selectedShops}
        shops={shops}
        onChageSearch={onChageSearch}
        onChangeShop={onChangeShop}
        onRecalculateObject={onRecalculateObject}
        onSaveProductCalculations={onSaveProductCalculations}
        onRedirectProduct={onRedirectProduct}
        onExportXLS={onExportXLS}
        shopsMarketplace={shopsMarketplace}
        selectedShopMarketplace={selectedShopMarketplace}
        onChangeBrand={onChangeBrand}
      />
    </Col>
  );
}

PriceCalculatorModule.propTypes = {
  history: PropTypes.shape().isRequired,
};


export default withRouter(PriceCalculatorModule);
