import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card,
} from 'antd';
import './ProductCataloguePageDesktop.css';
import ProductTable from '../Components/Table';
import Filters from '../Components/Filters';


function ProductCataloguePageDesktop({
  isInitLoading, datasource, onChageSearch, shops, selectedShopArray, onChangeShop, onRedirectProduct, shopsMarketplace,
  selectedShopMarketplace, onChangeShopMarketplace,
}) {
  return (
    <Col className="productCataloguePageDesktop-wrapper">
      <Row className="productCataloguePageDesktop-title">Каталог продуктов</Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Filters
            onChageSearch={onChageSearch}
            isInitLoading={isInitLoading}
            shops={shops}
            selectedShopArray={selectedShopArray}
            onChangeShop={onChangeShop}
            shopsMarketplace={shopsMarketplace}
            selectedShopMarketplace={selectedShopMarketplace}
            onChangeShopMarketplace={onChangeShopMarketplace}
          />
        </Card>
      </Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <ProductTable tableDatasource={datasource} isInitLoading={isInitLoading} onRedirectProduct={onRedirectProduct} />
        </Card>
      </Row>
    </Col>
  );
}

ProductCataloguePageDesktop.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  shops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShopArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
  onRedirectProduct: PropTypes.func.isRequired,
  selectedShopMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  shopsMarketplace: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeShopMarketplace: PropTypes.func.isRequired,
};

export default ProductCataloguePageDesktop;
