import React from 'react';
import PropTypes from 'prop-types';
import './AdministrationPageDesktop.css';
import Spinner from '../../../components/Spinner/Spinner';
import AdministrationHeader from './AdministrationHeader/AdministrationHeader';
import AdministrationActions from './AdministrationActions/AdministrationActions';
import AdministrationTable from './AdministrationTable/AdministrationTable';


function AdministrationPageDesktop({
  isLoading, view, onTabClick, headerDate, datasource, tableRef, productsCatalogue, onSelectAirtable, onChageSearch,
  shopsMarketplace, selectedShopMarketplace, onChangeShop,
}) {
  return (
    <div className="administrationPageDesktop-wrapper">
      <AdministrationHeader
        headerData={headerDate}
        isLoading={isLoading}
      />

      <AdministrationActions
        view={view}
        onTabClick={onTabClick}
        isLoading={isLoading}
      />

      <AdministrationTable
        datasource={datasource}
        tableRef={tableRef}
        isLoading={isLoading}
        productsCatalogue={productsCatalogue}
        onSelectAirtable={onSelectAirtable}
        onChageSearch={onChageSearch}
        onChangeShop={onChangeShop}
        selectedShopMarketplace={selectedShopMarketplace}
        shopsMarketplace={shopsMarketplace}
      />

      {isLoading && <Spinner />}
    </div>
  );
}

AdministrationPageDesktop.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onTabClick: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  headerDate: PropTypes.shape().isRequired,
  datasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableRef: PropTypes.shape().isRequired,
  productsCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectAirtable: PropTypes.func.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  shopsMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedShopMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
};

export default AdministrationPageDesktop;
