/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import getColumnsDesktop from '../utils/getColumnsDesktop';


function ProductTable({
  datasource, isLoading, onRecalculateObject, onRedirectProduct,
}) {
  return (
    <Table
      dataSource={datasource}
      columns={getColumnsDesktop(onRecalculateObject, onRedirectProduct)}
      style={{ backgroundColor: '#fff', width: '100%', height: '60vh' }}
      loading={isLoading}
      scroll={{ y: '50vh' }}
      rowKey="id"
    />
  );
}

ProductTable.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onRecalculateObject: PropTypes.func.isRequired,
  onRedirectProduct: PropTypes.func.isRequired,
};

export default ProductTable;

