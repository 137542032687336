import React from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';


function Spinner({ style }) {
  return (
    <div style={{
      position: 'absolute',
      width: '95vw',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      top: 0,
      left: 0,
      ...style,
    }}
    >
      <Spin
        style={{ fontSize: '30px' }}
        indicator={(<LoadingOutlined style={{ fontSize: '100px', margin: '-120px 0px 0px -60px' }} spin />)}
        tip="Загрузка..."
      >
        <div />
      </Spin>
    </div>
  );
}


Spinner.propTypes = {
  style: PropTypes.shape(),
};

Spinner.defaultProps = {
  style: {},
};

export default Spinner;
