/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './AdministrationActions.css';


const tabs = [{ value: 'ozon', label: 'Товары на Ozon' }, { value: 'wb', label: 'Товары на WB' }];
function AdministrationActions({
  view, onTabClick,
}) {
  return (
    <div className="administrationActions-row-actions">
      <div className="administrationActions-row-actions-tabs">
        {tabs.map((t) => (
          <div
            key={t.value}
            className="administrationActions-row-actions-tab"
            style={{
              color: view === t.value ? '#322c92' : '#212121',
              borderBottom: view === t.value ? '1px solid #322c92' : '',
            }}
            onClick={() => onTabClick(t.value)}
          >
            {t.label}
          </div>
        ))}
      </div>
      {/* <Button type="primary" onClick={onMatchOfferIdClick} disabled={isLoading}>По артикулу</Button> */}
    </div>
  );
}

AdministrationActions.propTypes = {
  view: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
};

export default AdministrationActions;
