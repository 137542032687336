import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Input, Select, Row,
} from 'antd';


const { Search } = Input;
const { Option } = Select;

function Filters({
  onChageSearch, isInitLoading, selectedShopArray, onChangeShop, shops, shopsMarketplace,
  selectedShopMarketplace, onChangeShopMarketplace,
}) {
  return (
    <Col span={24}>
      <Row style={{ margin: '20px 0px' }}>
        <Select
          style={{ width: '20vw', marginRight: '10px' }}
          disabled={isInitLoading}
          value={selectedShopArray}
          showSearch
          allowClear
          mode="multiple"
          maxTagCount="responsive"
          onChange={onChangeShop}
          placeholder="Бренд"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {shops.map((u) => <Option key={u.id} value={u.id}>{u.name}</Option>)}
        </Select>
        <Select
          style={{ width: '20vw' }}
          disabled={isInitLoading}
          value={selectedShopMarketplace}
          showSearch
          allowClear
          mode="multiple"
          maxTagCount="responsive"
          onChange={onChangeShopMarketplace}
          placeholder="Магазин"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {shopsMarketplace.map((u) => <Option key={u.name} value={u.name}>{u.name}</Option>)}
        </Select>
      </Row>
      <Search
        placeholder="название или артикул"
        onChange={onChageSearch}
        disabled={isInitLoading}
      />
    </Col>
  );
}

Filters.propTypes = {
  onChageSearch: PropTypes.func.isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  shops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShopArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
  selectedShopMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  shopsMarketplace: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeShopMarketplace: PropTypes.func.isRequired,
};

export default Filters;

