import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card, Modal, Button, Form, Select, Input,
} from 'antd';
import Table from '../Components/Table';
import Filters from '../Components/Filters';
import './MailingPageDesktop.css';


const { Option } = Select;
const { TextArea } = Input;
function MailingPageDesktop({
  isInitLoading, datasource, loading, onChageSearch, onShowSelectedMailing, selectedMailing, onSendMailing, formRef, apiKeysCatalogue,
  onFileUpload,
}) {
  return (
    <Col className="mailingPageDesktop-wrapper">
      <Row className="mailingPageDesktop-title">Рассылки</Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Filters
            onChageSearch={onChageSearch}
            isInitLoading={isInitLoading}
            onAdd={onShowSelectedMailing}
          />
        </Card>
      </Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Table
            datasource={datasource}
            loading={loading || isInitLoading}
            onShow={onShowSelectedMailing}
          />
        </Card>
      </Row>
      {selectedMailing
      && (
      <Modal
        title="Рассылка"
        onCancel={() => onShowSelectedMailing(null)}
        visible
        width="80vw"
        bodyStyle={{ padding: '20px' }}
        maskClosable
        footer={[
          <Button type="primary" onClick={onSendMailing}>Запустить рассылку</Button>,
        ]}
      >
        <Form ref={formRef} layout="vertical" style={{ width: '100%' }}>
          <Col span={12}>
            <Form.Item
              name="name"
              initialValue={selectedMailing.name}
              label="Название рассылки:"
              rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
            >
              <Input style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="apiKey"
              label="API-ключ:"
              initialValue={selectedMailing.apiKey}
              style={{ marginTop: '20px' }}
              rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
            >
              <Select
                style={{ width: '100%' }}
              >
                {apiKeysCatalogue.map((u) => <Option key={u.id} value={u.id}>{u.name}</Option>)}
              </Select>
            </Form.Item>
            <Form.Item
              name="text"
              initialValue={selectedMailing.text}
              label="Текст рассылки:"
              rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
              style={{ marginTop: '20px' }}
            >
              <TextArea style={{ width: '100%' }} />
            </Form.Item>
            <input
              type="file"
              name="file"
              accept=".csv"
              onChange={onFileUpload}
            />
          </Col>
        </Form>
      </Modal>
      )}
    </Col>
  );
}

MailingPageDesktop.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  onShowSelectedMailing: PropTypes.func.isRequired,
  selectedMailing: PropTypes.shape(),
  onSendMailing: PropTypes.func.isRequired,
  formRef: PropTypes.shape().isRequired,
  apiKeysCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onFileUpload: PropTypes.func.isRequired,
};

MailingPageDesktop.defaultProps = {
  selectedMailing: null,
};

export default MailingPageDesktop;
