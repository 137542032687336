/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import {
  Col, Row, Avatar,
} from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import './SideBar.css';
import getUserLogo from '../../utils/localeStorage/getUserLogo';
import SideBarButton from './components/SideBarButton/SideBarButton';
import buttonsArray from './utils/buttonsArray';
import { routes } from '../../utils/routes';
import logout from '../../utils/logout';
import logo from '../../assets/icons/harvLogo.png';


class SideBar extends Component {
  handleButtonClick = (route) => {
    const { history } = this.props;
    history.push(route);
  }

  getIsActive() {
    return {
      background: '#edf1f7',
      color: '#1890ff',
      borderLeft: '7px solid #1890ff',
      borderRight: '7px solid #edf1f7',
    };
  }

  render() {
    const { history } = this.props;
    const pathname = get(history, 'location.pathname', '');
    const isVisible = routes.login !== pathname;
    if (!isVisible) {
      return null;
    }
    return (
      <Col span={1}>
        <Row style={{ height: '100vh', backgroundColor: '#fdfcfc' }}>
          <Col span={24} style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
            <Row>
              <Col span={24}>
                <img src={logo} alt="логотип" style={{ width: '100%', padding: '20px 10px 50px' }} />
                {buttonsArray
                  .map((button) => (
                    <SideBarButton
                      key={button.id}
                      icon={button.icon}
                      onClick={this.handleButtonClick}
                      route={button.route}
                      history={history}
                    />
                  ))}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Row
                  align="middle"
                  justify="center"
                  gutter={8}
                  className="sideBarButton-wrapper"
                  style={routes.profile === history.location.pathname && this.getIsActive(routes.profile === history.location.pathname)}
                >
                  <Col className="sideBarButton-icon">
                    {getUserLogo()
                      ? <Avatar src={getUserLogo()} size={33} />
                      : <Avatar style={{ backgroundColor: '#1890ff' }} icon={<UserOutlined />} size={33} />}
                  </Col>
                </Row>
                <Row
                  align="middle"
                  justify="center"
                  gutter={8}
                  className="sideBarButton-wrapper"
                  onClick={() => logout(history)}
                >
                  <Col className="sideBarButton-icon"><LogoutOutlined /></Col>
                </Row>

              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    );
  }
}

SideBar.propTypes = {
  history: PropTypes.shape(),
};

SideBar.defaultProps = {
  history: {},
};

export default withRouter(SideBar);
