/* eslint-disable prefer-spread */
/* eslint-disable no-return-assign */
import React from 'react';
import {
  Chart,
  Tooltip,
  Line,
  Interval,
  Legend,
  Axis,
  View,
} from 'bizcharts';
import { Card } from 'antd';
import PropTypes from 'prop-types';
import formatSumWithSymbol from '../../../utils/formatSumWithSymbol';


function CustomChart({ chartDatasource }) {
  const saleMax = Math.max.apply(Math, chartDatasource.resultBars.map((o) => o.count));
  const countMax = Math.max.apply(
    Math,
    [...chartDatasource.resultLineOrder.map((o) => o.orderCount),
      ...chartDatasource.resultLineSale.map((o) => o.saleCount)],
  );
  const countSumMax = countMax * 1000;
  const tickSum = Math.max(saleMax, countSumMax);
  const tickCount = Number((tickSum / 1000).toFixed());
  const scale = {
    period: {
      sync: true,
    },
    count: {
      min: 0,
      max: 1000 * tickCount,
    },
    orderCount: {
      min: 0,
      alias: 'Заказов, шт',
      max: 1 * tickCount,
    },
    saleCount: {
      min: 0,
      alias: 'Продаж, шт',
      max: 1 * tickCount,
    },
  };
  return (
    <Card style={{ borderRadius: '5px', width: '100%' }}>
      <div style={{ fontSize: '24px', color: '#212121', marginLeft: '16px' }}>Заказы</div>
      <Chart height={400} padding={[20, 80, 50, 80]} data={chartDatasource.resultBars} autoFit scale={scale}>
        <Tooltip shared />
        <Interval
          adjust={[
            {
              type: 'dodge',
              marginRatio: 0,
            },
          ]}
          color="name"
          position="period*count"
        />
        <Axis
          name="count"
          label={{
            formatter(text) {
              return formatSumWithSymbol(text);
            },
          }}
        />
        <View data={chartDatasource.resultLineOrder} padding={0} scale={scale}>
          <Axis
            name="orderCount"
            position="right"
          />
          <Line
            position="period*orderCount"
            color="#eb2f96"
            shape="smooth"
          />
        </View>
        <View data={chartDatasource.resultLineSale} padding={0} scale={scale}>
          <Axis name="saleCount" visible={false} />
          <Line
            position="period*saleCount"
            color="#fadb14"
            shape="smooth"
          />
        </View>
        <Legend
          custom
          items={[
            {
              name: 'Заказов, руб',
              value: 'Заказов, руб',
              marker: {
                symbol: 'square',
                style: { fill: '#6495f9', lineWidth: 2 },
              },
            },
            {
              name: 'Продаж, руб',
              value: 'Продаж, руб',
              marker: {
                symbol: 'square',
                style: { fill: '#65daab', lineWidth: 2 },
              },
            },
            {
              name: 'Заказов, шт',
              value: 'orderCount',
              marker: {
                symbol: 'hyphen',
                style: { stroke: '#eb2f96', lineWidth: 2 },
              },
            },
            {
              name: 'Продаж, шт',
              value: 'saleCount',
              marker: {
                symbol: 'hyphen',
                style: { stroke: '#fadb14', lineWidth: 2 },
              },
            }]}
        />
      </Chart>
    </Card>
  );
}

CustomChart.propTypes = {
  chartDatasource: PropTypes.shape().isRequired,
};


export default CustomChart;
