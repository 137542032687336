const parseData = (innerProduct, productsWB, productsOzon, ordersOzon, ordersWB, ordersOzonMonth, ordersWBMonth, periodFrom, periodTo,
  periodFromMonth) => {
  const linkedProductsWb = productsWB
    .filter((p) => p.productCatalogueId === innerProduct.id)
    .map((p) => {
      const updatedProduct = {
        ...p,
        shop: 'WB',
        orders: ordersWB
          .filter((order) => order.productId === p.id)
          .filter((o) => o.createdAt && o.createdAt.isBetween(periodFrom, periodTo, undefined, '[]'))
          .reduce((accum, element) => accum + Number(element.quantity), 0),
      };
      return updatedProduct;
    });

  const linkedProductsOzon = productsOzon
    .filter((p) => p.productCatalogueId === innerProduct.id)
    .map((p) => {
      const updatedProduct = {
        ...p,
        shop: 'Ozon',
        orders: ordersOzon
          .filter((order) => order.productId === p.id)
          .filter((o) => o.createdAt && o.createdAt.isBetween(periodFrom, periodTo, undefined, '[]'))
          .reduce((accum, element) => accum + Number(element.quantity), 0),
      };
      return updatedProduct;
    });

  const linkedProductsWbMonth = productsWB
    .filter((p) => p.productCatalogueId === innerProduct.id)
    .map((p) => {
      const updatedProduct = {
        ...p,
        shop: 'WB',
        orders: ordersWBMonth
          .filter((order) => order.productId === p.id)
          .filter((o) => o.createdAt && o.createdAt.isBetween(periodFromMonth, periodTo, undefined, '[]'))
          .reduce((accum, element) => accum + Number(element.quantity), 0),
      };
      return updatedProduct;
    });

  const linkedProductsOzonMonth = productsOzon
    .filter((p) => p.productCatalogueId === innerProduct.id)
    .map((p) => {
      const updatedProduct = {
        ...p,
        shop: 'Ozon',
        orders: ordersOzonMonth
          .filter((order) => order.productId === p.id)
          .filter((o) => o.createdAt && o.createdAt.isBetween(periodFromMonth, periodTo, undefined, '[]'))
          .reduce((accum, element) => accum + Number(element.quantity), 0),
      };
      return updatedProduct;
    });

  return ({
    ...innerProduct,
    linkedProductsPeriod: [...linkedProductsOzon, ...linkedProductsWb],
    linkedProductsMonth: [...linkedProductsOzonMonth, ...linkedProductsWbMonth],
  });
};

export default parseData;
