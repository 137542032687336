const logisticsCoefficientsFBS = [
  {
    minWeight: 0.1,
    maxWeight: 0.1,
    percent: 0.05,
    minValue: 38,
    maxValue: 50,
  },
  {
    minWeight: 0.2,
    maxWeight: 0.2,
    percent: 0.05,
    minValue: 39,
    maxValue: 50,
  },
  {
    minWeight: 0.3,
    maxWeight: 0.3,
    percent: 0.05,
    minValue: 40,
    maxValue: 60,
  },
  {
    minWeight: 0.4,
    maxWeight: 0.4,
    percent: 0.05,
    minValue: 41,
    maxValue: 60,
  },
  {
    minWeight: 0.5,
    maxWeight: 0.5,
    percent: 0.05,
    minValue: 41,
    maxValue: 65,
  },
  {
    minWeight: 0.6,
    maxWeight: 0.6,
    percent: 0.05,
    minValue: 43,
    maxValue: 70,
  },
  {
    minWeight: 0.7,
    maxWeight: 0.7,
    percent: 0.05,
    minValue: 43,
    maxValue: 70,
  },
  {
    minWeight: 0.8,
    maxWeight: 0.8,
    percent: 0.05,
    minValue: 45,
    maxValue: 75,
  },
  {
    minWeight: 0.9,
    maxWeight: 0.9,
    percent: 0.05,
    minValue: 47,
    maxValue: 75,
  },
  {
    minWeight: 1,
    maxWeight: 1,
    percent: 0.06,
    minValue: 49,
    maxValue: 85,
  },
  {
    minWeight: 1.1,
    maxWeight: 1.1,
    percent: 0.06,
    minValue: 53,
    maxValue: 90,
  },
  {
    minWeight: 1.2,
    maxWeight: 1.2,
    percent: 0.06,
    minValue: 55,
    maxValue: 95,
  },
  {
    minWeight: 1.3,
    maxWeight: 1.3,
    percent: 0.06,
    minValue: 59,
    maxValue: 100,
  },
  {
    minWeight: 1.4,
    maxWeight: 1.4,
    percent: 0.06,
    minValue: 60,
    maxValue: 100,
  },
  {
    minWeight: 1.5,
    maxWeight: 1.5,
    percent: 0.06,
    minValue: 61,
    maxValue: 115,
  },
  {
    minWeight: 1.6,
    maxWeight: 1.6,
    percent: 0.06,
    minValue: 63,
    maxValue: 115,
  },
  {
    minWeight: 1.7,
    maxWeight: 1.7,
    percent: 0.06,
    minValue: 65,
    maxValue: 120,
  },
  {
    minWeight: 1.8,
    maxWeight: 1.8,
    percent: 0.06,
    minValue: 67,
    maxValue: 125,
  },
  {
    minWeight: 1.9,
    maxWeight: 1.9,
    percent: 0.06,
    minValue: 67,
    maxValue: 130,
  },
  {
    minWeight: 2.0,
    maxWeight: 2.9,
    percent: 0.06,
    minValue: 75,
    maxValue: 140,
  },
  {
    minWeight: 3.0,
    maxWeight: 3.9,
    percent: 0.06,
    minValue: 95,
    maxValue: 170,
  },
  {
    minWeight: 4.0,
    maxWeight: 4.9,
    percent: 0.06,
    minValue: 115,
    maxValue: 210,
  },
  {
    minWeight: 5.0,
    maxWeight: 5.9,
    percent: 0.06,
    minValue: 130,
    maxValue: 250,
  },
  {
    minWeight: 6.0,
    maxWeight: 6.9,
    percent: 0.06,
    minValue: 150,
    maxValue: 300,
  },
  {
    minWeight: 7.0,
    maxWeight: 7.9,
    percent: 0.06,
    minValue: 175,
    maxValue: 330,
  },
  {
    minWeight: 8.0,
    maxWeight: 8.9,
    percent: 0.06,
    minValue: 200,
    maxValue: 350,
  },
  {
    minWeight: 9.0,
    maxWeight: 9.9,
    percent: 0.06,
    minValue: 215,
    maxValue: 375,
  },
  {
    minWeight: 10.0,
    maxWeight: 10.9,
    percent: 0.07,
    minValue: 250,
    maxValue: 390,
  },
  {
    minWeight: 11.0,
    maxWeight: 11.9,
    percent: 0.07,
    minValue: 275,
    maxValue: 430,
  },
  {
    minWeight: 12.0,
    maxWeight: 12.9,
    percent: 0.06,
    minValue: 300,
    maxValue: 470,
  },
  {
    minWeight: 13.0,
    maxWeight: 13.9,
    percent: 0.07,
    minValue: 330,
    maxValue: 500,
  },
  {
    minWeight: 14.0,
    maxWeight: 14.9,
    percent: 0.07,
    minValue: 350,
    maxValue: 525,
  },
  {
    minWeight: 15.0,
    maxWeight: 19.9,
    percent: 0.07,
    minValue: 375,
    maxValue: 550,
  },
  {
    minWeight: 20.0,
    maxWeight: 999999.9,
    percent: 0.07,
    minValue: 500,
    maxValue: 650,
  },
];

export default logisticsCoefficientsFBS;
