const prepareHistoryData = (productHistory) => {
  const result = {
    updatedProductHistoryStocks: [],
    updatedProductHistoryPrice: [],
  };
  productHistory.forEach((el) => {
    const period = el.date;
    result.updatedProductHistoryStocks.push(
      {
        period,
        name: `Остатки, ${el.shopName}`,
        count: el.stocksPresent,
      },
    );
    result.updatedProductHistoryPrice.push(
      {
        period,
        name: el.shop === 'Airtable' ? 'Себестоимость' : `Цена, ${el.shopName}`,
        count: el.price,
      },
    );
  });

  return result;
};

export default prepareHistoryData;
