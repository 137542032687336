const fbsWarehouse = [
  {
    name: 'У меня FBO или RealFBS',
    value: 0,
  },
  {
    name: 'ППЗ (Москва, УТК Садовод)',
    value: 80,
  },
  {
    name: 'ППЗ, ПВЗ (другие адреса)',
    value: 25,
  },
  {
    name: 'СЦ',
    value: 10,
  },
  {
    name: 'ТСЦ',
    value: 0,
  },
];

export default fbsWarehouse;
