/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Select, Input } from 'antd';
import { orderBy } from 'lodash';
import './AdministrationTable.css';
import OrderButton from '../../../../components/OrderButton/OrderButton';


const { Search } = Input;
const { Option } = Select;
const directionOrders = ['', 'asc', 'desc'];
function AdministrationTable({
  datasource, tableRef, isLoading, productsCatalogue, onSelectAirtable, onChageSearch, shopsMarketplace, selectedShopMarketplace, onChangeShop,
}) {
  const [order, setIsOrder] = useState({ });

  const onOrderClick = (key) => {
    const currentDirectionIndex = order[key] ? directionOrders.indexOf(order[key]) : 0;
    if (currentDirectionIndex === 2) {
      setIsOrder({ [key]: '' });
    } else {
      setIsOrder({ [key]: directionOrders[currentDirectionIndex + 1] });
    }
  };

  let orderResults = datasource;
  const orderKeys = Object.keys(order);
  if (orderKeys.length > 0) {
    orderResults = orderBy(datasource, orderKeys[0], [order[orderKeys[0]]]);
  }

  return (
    <div className="administrationTable-wrapper">
      <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
        <Select
          style={{ width: '20vw' }}
          disabled={isLoading}
          value={selectedShopMarketplace}
          showSearch
          allowClear
          mode="multiple"
          maxTagCount="responsive"
          onChange={onChangeShop}
          placeholder="Магазин"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {shopsMarketplace.map((u) => <Option key={u} value={u}>{u}</Option>)}
        </Select>
        <Search
          style={{ marginLeft: '10px' }}
          placeholder="название или артикул"
          onChange={onChageSearch}
          disabled={isLoading}
        />
      </div>
      <div className="administrationTable-header-wrapper">
        <div className="administrationTable-header-cell-wrapper" style={{ width: '10%' }}>ID</div>
        <div className="administrationTable-header-cell-wrapper" style={{ width: '10%' }}>Артикул</div>
        <div className="administrationTable-header-cell-wrapper" style={{ width: '15%' }}>Магазин</div>
        <div className="administrationTable-header-cell-wrapper" style={{ width: '40%' }}>Название</div>
        <div className="administrationTable-header-cell-wrapper" style={{ width: '25%', cursor: 'pointer' }} onClick={() => onOrderClick('productCatalogueId')}>
          <span>Airtable</span>
          <OrderButton orderDirection={order.productCatalogueId} />
        </div>
      </div>
      <div className="administrationTable-table-wrapper">
        <Form ref={tableRef}>
          {orderResults.map((row, index) => (
            <div className="administrationTable-table-row-wrapper" key={row.id} style={{ borderBottom: index + 1 === orderResults.length ? '' : '1px solid #dcdee1' }}>
              <div className="administrationTable-table-row-cell-wrapper" style={{ width: '10%' }}>{row.id || '-'}</div>
              <div className="administrationTable-table-row-cell-wrapper" style={{ width: '10%' }}>{row.supplierArticle || '-'}</div>
              <div className="administrationTable-table-row-cell-wrapper" style={{ width: '15%' }}>{row.shopName || '-'}</div>
              <div className="administrationTable-table-row-cell-wrapper" style={{ width: '40%' }}>{row.name || '-'}</div>
              <div className="administrationTable-table-row-cell-wrapper" style={{ width: '25%' }}>
                {/* <Form.Item name={`productCatalogueId_${row.id}`} initialValue={row.productCatalogueId}> */}
                <Select
                  disabled={isLoading}
                  options={productsCatalogue}
                  allowClear
                  showSearch
                  filterOption={(input, option) => option.label && option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  onChange={(e) => onSelectAirtable(e, row.id)}
                  defaultValue={row.productCatalogueId}
                />
                {/* </Form.Item> */}
              </div>
            </div>
          ))}
        </Form>
      </div>
    </div>
  );
}

AdministrationTable.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  tableRef: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
  productsCatalogue: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelectAirtable: PropTypes.func.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  shopsMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedShopMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
};

export default AdministrationTable;
