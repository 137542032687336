import { createBrowserHistory as createHistory } from 'history';
import { get } from 'lodash';
import moment from 'moment';
import api from './directualAPI/api';


const pageSize700 = 700;
const pageSize1500 = 1500;
const pageSize30 = 30;

function handleError(error) {
  if (get(error, 'response.status', null) === 403) {
    return createHistory({ forceRefresh: true }).push('/login');
  }
  throw new Error(error);
}


function parseUser(user) {
  return {
    id: get(user, 'id', null),
    fullName: `${get(user, 'lastName', '')} ${get(user, 'firstName', '')}`,
    logoURL: get(user, 'logo_id.urlLink', null),
    isVerified: get(user, 'isVerified', false),
    role: get(user, 'role', ''),
    firstName: get(user, 'firstName', ''),
    lastName: get(user, 'lastName', ''),
  };
}

function parseProductOzon(object) {
  return {
    id: get(object, 'id', null),
    offerId: get(object, 'offerId', null),
    name: get(object, 'name', null),
    primaryImage: get(object, 'primaryImage', null),
    sku: get(object, 'sku', null),
    productCatalogueId: get(object, 'productCatalogue_id', null),
    supplierArticle: get(object, 'offerId', null),
    shopId: get(object, 'shop_id.id', null),
    shopName: get(object, 'shop_id.name', null),
    stocksPresent: Number(get(object, 'stocksPresent', 0)),
    price: parseFloat(get(object, 'price', 0)),
    shop: 'Ozon',
    commissionsPercent: get(object, 'commissionsPercent', 0),
    commissionsLogistics: get(object, 'commissionsLogistics', 0),
    commissionsStorage: get(object, 'commissionsStorage', 0),
    commissionsTotal: get(object, 'commissionsTotal', 0),
    margin: get(object, 'margin', 0),
    profit: get(object, 'profit', 0),
    priceCalculated: get(object, 'priceCalculated', 0),
    otherCosts: get(object, 'otherCosts', 0),
    commissionsValue: get(object, 'commissionsValue', 0),
    bankComission: get(object, 'bankComission', 0),
    marginToSelfCostFBO: get(object, 'marginToSelfCostFBO', 0),
    commissionsLogisticsBack: get(object, 'commissionsLogisticsBack', 0),
    commissionsMarketing: get(object, 'commissionsMarketing', 0),
  };
}

function parseShopCatalogue(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
  };
}

function parseShop(object, shop) {
  return {
    id: get(object, 'id', null),
    name: `${get(object, 'name', null)}`,
    apiKey: get(object, 'appId', null),
    shop,
  };
}

function parseOrderOzon(object) {
  return {
    id: get(object, 'id', null),
    productCatalogueId: get(object, 'product_id.productCatalogue_id.id', null),
    shopId: get(object, 'product_id.productCatalogue_id.shop_id', null),
    quantity: Number(get(object, 'quantity', 0)),
    clientOrderSum: parseFloat(get(object, 'clientOrderSum', 0)),
    createdAt: get(object, 'createdAt', null) ? moment(moment(get(object, 'createdAt', null)).utc(false).startOf('day').format('YYYY-MM-DD')) : null,
    shop: 'Ozon',
    productId: get(object, 'product_id.id', null),
  };
}

function parseTransactionOzon(object) {
  return {
    id: get(object, 'id', null),
    productCatalogueId: get(object, 'product_id.productCatalogue_id.id', null),
    shopId: get(object, 'product_id.productCatalogue_id.shop_id', null),
    quantity: Number(get(object, 'quantity', 0)),
    amount: parseFloat(get(object, 'amount', 0)),
    transactionDate: get(object, 'transactionDate', null) ? moment(get(object, 'transactionDate', null)).startOf('day') : null,
    shop: 'Ozon',
  };
}

function parseProductWB(object) {
  return {
    id: get(object, 'id', null),
    supplierArticle: get(object, 'supplierArticle', null),
    productCatalogueId: get(object, 'productCatalogue_id', null),
    name: get(object, 'name', null),
    shopId: get(object, 'shopWB_id.id', null),
    shopName: get(object, 'shopWB_id.name', null),
    stocks: Number(get(object, 'quantity', 0)),
    price: parseFloat(get(object, 'priceWithDiscount', 0)),
    shop: 'WB',
    commissionsPercent: get(object, 'WBCommissions_id.commissionPercent', 0),
    commissionsLogistics: get(object, 'commissionsLogistics', 0),
    commissionsStorage: get(object, 'commissionsStorage', 0),
    commissionsTotal: get(object, 'commissionsTotal', 0),
    margin: get(object, 'margin', 0),
    profit: get(object, 'profit', 0),
    priceCalculated: get(object, 'priceCalculated', 0),
    otherCosts: get(object, 'otherCosts', 0),
    commissionsValue: get(object, 'commissionsValue', 0),
    bankComission: get(object, 'bankComission', 0),
    marginToSelfCostFBO: get(object, 'marginToSelfCostFBO', 0),
    commissionsLogisticsBack: get(object, 'commissionsLogisticsBack', 0),
    commissionsMarketing: get(object, 'commissionsMarketing', 0),
  };
}

function parseProductCatalogue(object) {
  return {
    id: get(object, 'id', null),
    productOzonId: get(object, 'productOzon_id', null),
    productWBId: get(object, 'productWB_id', null),
    inventoryNumber: get(object, 'inventoryNumber', null),
    name: get(object, 'name', ''),
    imageURL: get(object, 'imageURL', null),
    stocksWarehouse: get(object, 'stocksWarehouse', 0),
    netCost: get(object, 'netCost', 0),
    linkedProductsIds: get(object, 'linkedProducts_ids', []),
  };
}

function parseProductCatalogueFull(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', ''),
    imageURL: get(object, 'imageURL', null),
    inventoryNumber: get(object, 'inventoryNumber', ''),
    stocksWarehouse: get(object, 'stocksWarehouse', 0),
    netCost: get(object, 'netCost', 0),
    priceWB: get(object, 'productWB_id.priceWithDiscount', 0),
    stocksWB: get(object, 'productWB_id.quantity', 0),
    priceOzon: get(object, 'productOzon_id.price', 0),
    stocksOzon: get(object, 'productOzon_id.stocksPresent', 0),
    shopId: get(object, 'shop_id.id', ''),
    shopValue: get(object, 'shop_id.name', ''),
    commissionsPercentOzon: get(object, 'productOzon_id.commissionsPercent', 0),
    commissionsPercentWB: parseFloat(get(object, 'productWB_id.WBCommissions_id.commissionPercent', 0)) / 31,
    commissionsLogisticsOzon: get(object, 'productOzon_id.commissionsLogistics', 0),
    commissionsLogisticsWB: get(object, 'productWB_id.commissionsLogistics', 0),
    commissionsStorageOzon: get(object, 'productOzon_id.commissionsStorage', 0),
    commissionsStorageWB: get(object, 'productWB_id.commissionsStorage', 0),
    commissionsTotalOzon: get(object, 'productOzon_id.commissionsTotal', 0),
    commissionsTotalWB: get(object, 'productWB_id.commissionsTotal', 0),
    priceMinimumOzon: get(object, 'productOzon_id.priceMinimum', 0),
    priceMinimumWB: get(object, 'productWB_id.priceMinimum', 0),
    marginOzon: get(object, 'productOzon_id.margin', 0),
    marginWB: get(object, 'productWB_id.margin', 0),
    profitOzon: get(object, 'productOzon_id.profit', 0),
    profitWB: get(object, 'productWB_id.profit', 0),
    productIdOzon: get(object, 'productOzon_id.id', null),
    productIdWB: get(object, 'productWB_id.id', null),
    priceCalculatedOzon: get(object, 'productOzon_id.priceCalculated', 0),
    priceCalculatedWB: get(object, 'productWB_id.priceCalculated', 0),
    otherCostsOzon: get(object, 'productOzon_id.otherCosts', 0),
    otherCostsWB: get(object, 'productWB_id.otherCosts', 0),
    commissionsValueOzon: get(object, 'productOzon_id.commissionsValue', 0),
    commissionsValueWB: get(object, 'productWB_id.commissionsValue', 0),
    commissionsLastMileOzon: get(object, 'productOzon_id.commissionsLastMile', 0),
    commissionsLastMileWB: get(object, 'productWB_id.commissionsLastMile', 0),
    volumeWeightOzon: get(object, 'productOzon_id.volumeWeight', 0),
    commissionLogisticMonoMixWB: get(object, 'productWB_id.WBCommissions_id.commissionLogisticMonoMix', 0),
    commissionStorageMonoMixWB: get(object, 'productWB_id.WBCommissions_id.commissionStorageMonoMix', 0),
    isActualCalculationsWB: get(object, 'productWB_id.WBCommissions_id.isActualCalculations', 'Нет'),
    heightOzon: parseFloat((parseFloat(get(object, 'productOzon_id.height', 0)) / 10).toFixed(2)),
    depthOzon: parseFloat((parseFloat(get(object, 'productOzon_id.depth', 0)) / 10).toFixed(2)),
    widthOzon: parseFloat((parseFloat(get(object, 'productOzon_id.width', 0)) / 10).toFixed(2)),
    weightOzon: parseFloat((parseFloat(get(object, 'productOzon_id.weight', 0)) / 31).toFixed(2)),
    linkedProducts: get(object, 'linkedProducts_ids', []).map((p) => parseProductCatalogue(p)),
    categoryNameOzon: get(object, 'productOzon_id.categoryName', ''),
    bankComissionOzon: parseFloat(get(object, 'productOzon_id.bankComission', 0)),
    costAfterMPFBOOzon: parseFloat(get(object, 'productOzon_id.costAfterMPFBO', 0)),
    marginToSelfCostFBOOzon: parseFloat(get(object, 'productOzon_id.marginToSelfCostFBO', 0)),

    width: parseFloat((parseFloat(get(object, 'width', 0)) / 10).toFixed(2)),
    length: parseFloat((parseFloat(get(object, 'length', 0)) / 10).toFixed(2)),
    height: parseFloat((parseFloat(get(object, 'height', 0)) / 10).toFixed(2)),
    weight: parseFloat((parseFloat(get(object, 'weight', 0)) / 31).toFixed(2)),
    selfCost: get(object, 'netCost', 0),
  };
}

function parseOrderWB(object) {
  return {
    id: get(object, 'id', null),
    productCatalogueId: get(object, 'nmId_id.productCatalogue_id.id', null),
    shopId: get(object, 'nmId_id.productCatalogue_id.shop_id', null),
    quantity: Number(get(object, 'quantity', 0)),
    clientOrderSum: parseFloat(get(object, 'priceWithDiscount', 0)),
    createdAt: get(object, 'createDate', null) ? moment(moment(get(object, 'createDate', null)).utc(false).startOf('day').format('YYYY-MM-DD')) : null,
    shop: 'Wildberries',
    productId: get(object, 'nmId_id.id', null),
  };
}

function parseTransactionWB(object) {
  return {
    id: get(object, 'id', null),
    productCatalogueId: get(object, 'nmId_id.productCatalogue_id.id', null),
    shopId: get(object, 'nmId_id.productCatalogue_id.shop_id', null),
    quantity: Number(get(object, 'quantity', 0)),
    amount: parseFloat(get(object, 'forPay', 0)),
    profit: parseFloat(get(object, 'profit', 0)),
    transactionDate: get(object, 'transactionDate', null) ? moment(get(object, 'transactionDate', null)).startOf('day') : null,
    shop: 'Wildberries',
  };
}

function parseMailingListOzon(object) {
  return {
    id: get(object, 'id', null),
    name: get(object, 'name', null),
    date: get(object, 'date', null) ? moment(get(object, 'date', null)).startOf('day') : null,
    text: get(object, 'text', null),
    apiKey: get(object, 'shopOzon_id', null),
    postings: get(object, 'orderOzon_ids', []),
    totalNumber: Number(get(object, 'totalNumber', 0)),
    successNumber: Number(get(object, 'successNumber', 0)),
  };
}

//------------------------------------------------------------------------------------------------------------------------------

export function getUser(userID, sessionID) {
  return api
    .structure('WebUser')
    .getData('getUser', {
      userID,
      sessionID,
    })
    .then((resp) => parseUser(get(resp, 'payload[0]', {})))
    .catch(handleError);
}

export function getAllProductsOzon(shopId) {
  return api
    .structure('productOzon')
    .getData('getAllProductsOzon', { pageSize: pageSize700, shopId })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductOzon(p)))
    .catch(handleError);
}

export function getAllOrdersOzon(values) {
  return api
    .structure('orderOzon')
    .getData('getAllOrdersOzon', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseOrderOzon(p)))
    .catch(handleError);
}

export function getAllProductsWB(shopId) {
  return api
    .structure('productWB')
    .getData('getAllProductsWB', { pageSize: pageSize700, shopId })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductWB(p)))
    .catch(handleError);
}

export function getAllProductsCatalogue(shopId) {
  return api
    .structure('productCatalogue')
    .getData('getAllProductsCatalogue', { pageSize: pageSize1500, shopId })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductCatalogue(p)))
    .catch(handleError);
}

export function editProductCatalogue(values) {
  return api
    .structure('productCatalogue')
    .sendData('editProductCatalogue', values)
    .catch(handleError);
}

export function editProductOzon(values) {
  return api
    .structure('productOzon')
    .sendData('editProductOzon', values)
    .catch(handleError);
}

export function editProductWB(values) {
  return api
    .structure('productWB')
    .sendData('editProductWB', values)
    .catch(handleError);
}

export function getAllProductsCatalogueFull(productId) {
  return api
    .structure('productCatalogue')
    .getData('getAllProductsCatalogueFull', { pageSize: pageSize1500, productId })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductCatalogueFull(p)))
    .catch(handleError);
}

export function getAllOrdersWB(values) {
  return api
    .structure('orderWB')
    .getData('getAllOrdersWB', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseOrderWB(p)))
    .catch(handleError);
}

export function getAllShopsCatalogue() {
  return api
    .structure('shopCatalogue')
    .getData('getAllShopsCatalogue', { pageSize: pageSize30 })
    .then((resp) => get(resp, 'payload', []).map((p) => parseShopCatalogue(p)))
    .catch(handleError);
}

export function getAllTransactionsOzon(values) {
  return api
    .structure('orderOzon')
    .getData('getAllTransactionsOzon', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseTransactionOzon(p)))
    .catch(handleError);
}

export function getAllTransactionsWB(values) {
  return api
    .structure('orderWB')
    .getData('getAllTransactionsWB', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseTransactionWB(p)))
    .catch(handleError);
}

export function getAllShopsOzon() {
  return api
    .structure('shopOzon')
    .getData('getAllShopsOzon', { pageSize: pageSize30 })
    .then((resp) => get(resp, 'payload', []).map((p) => parseShop(p, 'Ozon')))
    .catch(handleError);
}

export function getAllShopsWB() {
  return api
    .structure('shopWB')
    .getData('getAllShopsWB', { pageSize: pageSize30 })
    .then((resp) => get(resp, 'payload', []).map((p) => parseShop(p, 'WB')))
    .catch(handleError);
}

export function getAllMailingListsOzon() {
  return api
    .structure('mailingListsOzon')
    .getData('getAllMailingListsOzon', { pageSize: pageSize30 })
    .then((resp) => get(resp, 'payload', []).map((p) => parseMailingListOzon(p)))
    .catch(handleError);
}

export function editMailingListOzon(values) {
  return api
    .structure('mailingListsOzon')
    .sendData('editMailingListOzon', values)
    .catch(handleError);
}


function parseOrderOzonReport(object) {
  const productJSON = JSON.parse(get(object, 'product_id__productCatalogue_id', '{}'));
  return {
    id: get(object, 'id', null),
    productCatalogueId: get(productJSON, 'id'),
    shopId: get(productJSON, 'shop_id.id'),
    quantity: Number(get(object, 'quantity___sum', 0)),
    clientOrderSum: parseFloat(get(object, 'clientOrderSum___sum', 0)),
    createdAt: moment(moment(get(object, 'createdAtDayString', null), 'YYYY-MM-DDTHH:mm:ss.sssZ').utc(false).startOf('day').format('YYYY-MM-DD')),
    shop: 'Ozon',
  };
}


export function getAllOrdersOzonReport(values) {
  return api
    .structure('report_697434276_for_struct_orderOzon')
    .getData('getAllOrdersOzonReport', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseOrderOzonReport(p)))
    .catch(handleError);
}

function parseTransactionOzonReport(object) {
  const productJSON = JSON.parse(get(object, 'product_id__productCatalogue_id', '{}'));
  return {
    id: get(object, 'id', null),
    productCatalogueId: get(productJSON, 'id'),
    shopId: get(productJSON, 'shop_id.id'),
    quantity: Number(get(object, 'quantity___sum', 0)),
    amount: parseFloat(get(object, 'amount___sum', 0)),
    profit: parseFloat(get(object, 'profit___sum', 0)),
    transactionDate: get(object, 'transactionDate', null) ? moment(get(object, 'transactionDate', null)).startOf('day') : null,
    shop: 'Ozon',
  };
}

export function getAllTransactionsOzonReport(values) {
  return api
    .structure('report_1140458442_for_struct_orderOzon')
    .getData('getAllTransactionsOzonReport', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseTransactionOzonReport(p)))
    .catch(handleError);
}

function parseProductOzonHistory(object) {
  return {
    stocksPresent: Number(get(object, 'stocksPresent', 0)),
    price: parseFloat(get(object, 'price', 0)),
    date: get(object, 'date', null) ? moment(get(object, 'date', null)).startOf('day').format('DD.MM.YY') : null,
    shop: 'Ozon',
    productCatalogueId: get(object, 'productOzon_id.productCatalogue_id', null),
    shopName: get(object, 'productOzon_id.shop_id.name', null),
  };
}

export function getProductOzonHistory(values) {
  return api
    .structure('productOzonHistory')
    .getData('getProductOzonHistory', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductOzonHistory(p)))
    .catch(handleError);
}

function parseProductWBHistory(object) {
  return {
    stocksPresent: Number(get(object, 'quantity', 0)),
    // price: parseFloat(get(object, 'price', 0)),
    price: parseFloat(get(object, 'priceWithDiscount', 0)),
    date: get(object, 'date', null) ? moment(get(object, 'date', null)).startOf('day').format('DD.MM.YY') : null,
    productCatalogueId: get(object, 'nmId.productCatalogue_id', null),
    shop: 'WB',
    shopName: get(object, 'nmId.shopWB_id.name', null),
  };
}

export function getProductWBHistory(values) {
  return api
    .structure('productWBStocksAndPricesHistory')
    .getData('getProductWBHistory', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductWBHistory(p)))
    .catch(handleError);
}

function parseProductCatalogueHistory(object) {
  return {
    stocksPresent: Number(get(object, 'stocksPresent', 0)),
    price: parseFloat(get(object, 'cost', 0)),
    date: get(object, 'date', null) ? moment(get(object, 'date', null)).startOf('day').format('DD.MM.YY') : null,
    shop: 'Airtable',
    product_id: get(object, 'product_id'),
    dateAsDate: get(object, 'date', null) ? moment(get(object, 'date', null)).startOf('day') : null,
    productCatalogueId: get(object, 'product_id', null),
    shopName: 'Airtable',
  };
}

export function getProductCatalogueHistory(values) {
  return api
    .structure('productCatalogueHistory')
    .getData('getProductCatalogueHistory', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductCatalogueHistory(p)))
    .catch(handleError);
}

function parseProductComment(object) {
  return {
    id: get(object, 'id'),
    date: get(object, 'date', null) ? moment(get(object, 'date', null)).format('DD.MM.YYYY') : null,
    time: get(object, 'time'),
    comment: get(object, 'comment'),
    author: `${get(object, 'author_id.lastName')} ${get(object, 'author_id.firstName')} ${get(object, 'author_id.middleName') || ''}`,
  };
}

export function editProductComment(values) {
  return api
    .structure('productComment')
    .sendData('editProductComment', values)
    .then((resp) => parseProductComment(get(resp, 'result[0]', {})))
    .catch(handleError);
}

export function getProductComment(values) {
  return api
    .structure('productComment')
    .getData('getProductComment', { pageSize: pageSize30, ...values })
    .then((resp) => get(resp, 'payload', []).map((p) => parseProductComment(p)))
    .catch(handleError);
}
