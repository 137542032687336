export const routes = {
  home: '/',
  login: '/login',
  dashboard: '/dashboard',
  productCatalogue: '/productCatalogue',
  administration: '/administration',
  profile: '/profile',
  help: '/help',
  priceCalculator: '/priceCalculator',
  product: '/product/:id',
  calculation: '/calculation',
  mailing: '/mailing',
  turnover: '/turnover',
};


export const routeGenerator = {
  product: (id) => `${routes.product.replace(':id', id)}`,
};
