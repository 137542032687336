import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row,
} from 'antd';
import Filters from '../Components/Filters';
import Statistics from '../Components/Statistics';
import Chart from '../Components/Chart';
import HistoryStocksChart from '../Components/HistoryStocksChart';
import HistoryPriceChart from '../Components/HistoryPriceChart';
import TableAbout from '../Components/TableAbout';
import Calculator from '../Components/Calculator';
import Stocks from '../Components/Stocks';
import Linked from '../Components/Linked';
import Comments from '../Components/Comments';
import './ProductPageDesktop.css';


function ProductPageDesktop({
  product, chartDatasource, orderQuantity, orderSum, saleQuantity, saleSum, periodFrom, periodTo, onChangePeriod, onAddNewLinkModal,
  selectedMarketplaces, onChangeMarketplace, loading, onChangePrice, onChangeOtherCosts, onSaveCalculator, allProductsFiltered,
  onSearchProductForLink, isShowAddLinkModal, onLinkCheckBoxChange, onSaveAddNewLink, productHistoryStocks, productHistoryPrice,
  shopsMarketplace, selectedShopMarketplace, onChangeShopMarketplace, isLoading, widgetData, productComments, editProductComment,
}) {
  return (
    <Col className="productPageDesktop-wrapper">
      <Row className="productPageDesktop-title">Карточка товара</Row>
      <Filters
        isInitLoading={isLoading}
        periodFrom={periodFrom}
        periodTo={periodTo}
        onChangePeriod={onChangePeriod}
        selectedMarketplaces={selectedMarketplaces}
        onChangeMarketplace={onChangeMarketplace}
        shopsMarketplace={shopsMarketplace}
        selectedShopMarketplace={selectedShopMarketplace}
        onChangeShopMarketplace={onChangeShopMarketplace}
      />
      <TableAbout
        isLoading={isLoading}
        product={product}
      />
      <Statistics
        isLoading={isLoading}
        orderQuantity={widgetData.orderQuantity || {}}
        orderSum={widgetData.orderSum || {}}
        saleQuantity={widgetData.saleQuantity || {}}
        saleSum={widgetData.saleSum || {}}
      />
      <Chart
        chartDatasource={chartDatasource}
      />

      <Linked
        isLoading={isLoading}
        product={product}
        onAddNewLinkModal={onAddNewLinkModal}
        allProductsFiltered={allProductsFiltered}
        onSearchProductForLink={onSearchProductForLink}
        isShowAddLinkModal={isShowAddLinkModal}
        onLinkCheckBoxChange={onLinkCheckBoxChange}
        onSaveAddNewLink={onSaveAddNewLink}
      />
      <HistoryStocksChart productHistoryStocks={productHistoryStocks} />
      <HistoryPriceChart productHistoryPrice={productHistoryPrice} />
      <Comments productComments={productComments} isLoading={isLoading} editProductComment={editProductComment} />

      {/*
      <Calculator
        isInitLoading={isInitLoading}
        product={product}
        onChangePrice={onChangePrice}
        onChangeOtherCosts={onChangeOtherCosts}
        onSaveCalculator={onSaveCalculator}
        loading={loading}
      /> */}
    </Col>
  );
}

ProductPageDesktop.propTypes = {
  chartDatasource: PropTypes.shape().isRequired,
  orderQuantity: PropTypes.shape().isRequired,
  orderSum: PropTypes.shape().isRequired,
  saleQuantity: PropTypes.shape().isRequired,
  saleSum: PropTypes.shape().isRequired,
  periodTo: PropTypes.shape().isRequired,
  periodFrom: PropTypes.shape().isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedMarketplaces: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeMarketplace: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  onChangeOtherCosts: PropTypes.func.isRequired,
  product: PropTypes.shape().isRequired,
  onSaveCalculator: PropTypes.func.isRequired,
  onAddNewLinkModal: PropTypes.func.isRequired,
  allProductsFiltered: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSearchProductForLink: PropTypes.func.isRequired,
  isShowAddLinkModal: PropTypes.bool.isRequired,
  onLinkCheckBoxChange: PropTypes.func.isRequired,
  onSaveAddNewLink: PropTypes.func.isRequired,
  productHistoryStocks: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  productHistoryPrice: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShopMarketplace: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  shopsMarketplace: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChangeShopMarketplace: PropTypes.func.isRequired,
  widgetData: PropTypes.shape().isRequired,
  productComments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  editProductComment: PropTypes.func.isRequired,
};

export default ProductPageDesktop;
