/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Col } from 'antd';
import { groupBy } from 'lodash';
import moment from 'moment';
import TurnoverDesktop from './Desktop/TurnoverDesktop';
import {
  getProductCatalogueHistory, getProductOzonHistory, getProductWBHistory, getAllShopsCatalogue, getAllProductsCatalogueFull,
} from '../../utils/http';


function TurnoverModule() {
  const [isLoading, setIsLoading] = useState(true);
  const [widgetData, setWidgetData] = useState({});
  const [periodFrom, setPeriodFrom] = useState(moment(moment().subtract(7, 'day').startOf('day').format('YYYY-MM-DD')));
  const [periodTo, setPeriodTo] = useState(moment(moment().startOf('day').format('YYYY-MM-DD')));
  const [periodFromNow] = useState(moment(moment().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')));
  const [shops, setShops] = useState([]);
  const [selectedMarketplaces, setSelectedMarketplaces] = useState(['Ozon', 'WB']);
  const [selectedShopArray, setSelectedShopArray] = useState([]);
  const [historyChartData, setHistoryChartData] = useState([]);
  const [products, setProducts] = useState([]);
  const [todayLeftovers, setTodayLeftovers] = useState([]);
  const [turnoverTableDatasource, setTurnoverTableDatasource] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [searchValue, setSearchValue] = useState('');


  const groupHistoryData = (data) => {
    const result = [];
    const groupedByShop = groupBy(data, 'shop');
    const shopKeys = Object.keys(groupedByShop);
    shopKeys.forEach((shopKey) => {
      const groupedByDate = groupBy(groupedByShop[shopKey], 'date');
      const dateKeys = Object.keys(groupedByDate);
      dateKeys.forEach((dateKey) => {
        const pack = groupedByDate[dateKey];
        const value = pack.reduce((acc, obj) => acc + obj.stocksPresent * obj.price, 0);
        result.push({
          value,
          shopKey,
          date: dateKey,
        });
      });
    });
    return result;
  };

  const prepareChartData = (array) => {
    const result = [];
    array.forEach((el) => {
      const period = el.date;
      result.push(
        {
          period,
          name: `Остатки, ${el.shopKey}`,
          count: Number(el.value.toFixed(0)),
          shop: el.shopKey,
        },
      );
    });
    return result;
  };

  const prepareTurnoverTableDatasource = (productsArray, leftoversArray) => {
    const result = [];
    const groupedByShop = groupBy(leftoversArray, 'shop');

    productsArray.forEach((p) => {
      const object = {
        id: p.id,
        inventoryNumber: p.inventoryNumber,
        imageURL: p.imageURL,
        name: p.name,
      };
      ['Airtable', 'Ozon', 'WB'].forEach((shop) => {
        const inShopStock = groupedByShop[shop] || [];
        const productInShopStock = inShopStock.filter((f) => f.productCatalogueId === p.id);
        object[`cost${shop}`] = productInShopStock.reduce((acc, obj) => acc + obj.stocksPresent * obj.price, 0).toFixed(0);
        object[`amount${shop}`] = productInShopStock.reduce((acc, obj) => acc + obj.stocksPresent, 0).toFixed(0);
      });
      result.push(object);
    });
    return result;
  };

  const prepareWidgetData = (array) => {
    const groupedByShop = groupBy(array, 'shop');
    const result = {
      airtableLeft: (groupedByShop.Airtable || []).reduce((acc, obj) => acc + obj.stocksPresent * obj.price, 0).toFixed(0),
      ozonLeft: (groupedByShop.Ozon || []).reduce((acc, obj) => acc + obj.stocksPresent * obj.price, 0).toFixed(0),
      wbLeft: (groupedByShop.WB || []).reduce((acc, obj) => acc + obj.stocksPresent * obj.price, 0).toFixed(0),
    };
    return result;
  };

  const getSelectedProduct = (newSarchValue, newSelectedShopArray, newProducts) => {
    let selectedProducts = [];
    if (newSarchValue === '') {
      selectedProducts = newSelectedShopArray.length === 0
        ? newProducts
        : newProducts.filter((p) => newSelectedShopArray.includes(p.shopId));
    } else {
      selectedProducts = newSelectedShopArray.length === 0
        ? newProducts
          .filter((element) => element.name.toLowerCase().indexOf(newSarchValue.toLowerCase()) >= 0
          || element.inventoryNumber.toLowerCase().indexOf(newSarchValue.toLowerCase()) >= 0)
        : newProducts
          .filter((element) => element.name.toLowerCase().indexOf(newSarchValue.toLowerCase()) >= 0
            || element.inventoryNumber.toLowerCase().indexOf(newSarchValue.toLowerCase()) >= 0)
          .filter((p) => newSelectedShopArray.includes(p.shopId));
    }
    return selectedProducts;
  };

  const filterData = (newSelectedMarketplaces, originalArray, selectedProducts) => {
    const filteredArray = originalArray
      .filter((o) => selectedProducts.includes(o.productCatalogueId))
      .filter(((o) => [...newSelectedMarketplaces, 'Airtable'].includes(o.shop)));
    return filteredArray;
  };

  const preapreData = (newSarchValue, newSelectedShopArray, newSelectedMarketplaces, newProducts, newTodayLeftovers, newAllHistory) => {
    const newSelectedProducts = getSelectedProduct(newSarchValue, newSelectedShopArray, newProducts);

    const filteredNewTodayLeftovers = filterData(newSelectedMarketplaces, newTodayLeftovers, newSelectedProducts.map((p) => p.id));
    const filteredNewAllHistory = filterData(newSelectedMarketplaces, newAllHistory, newSelectedProducts.map((p) => p.id));

    const groupedHistoryData = groupHistoryData(filteredNewAllHistory);

    const newWidgetData = prepareWidgetData(filteredNewTodayLeftovers);
    const newTurnoverTableDatasource = prepareTurnoverTableDatasource(newSelectedProducts, filteredNewTodayLeftovers);
    const newChartData = prepareChartData(groupedHistoryData);
    return {
      newWidgetData,
      newTurnoverTableDatasource,
      newChartData,
    };
  };

  useEffect(() => {
    Promise.all([
      getProductCatalogueHistory({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getProductOzonHistory({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getProductWBHistory({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllShopsCatalogue(),
      getAllProductsCatalogueFull(),
      getProductCatalogueHistory({ periodFrom: periodFromNow.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getProductOzonHistory({ periodFrom: periodFromNow.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getProductWBHistory({ periodFrom: periodFromNow.format('YYYY-MM-DD'), periodTo: periodFromNow.format('YYYY-MM-DD') }),
    ])
      .then((res) => {
        const newTodayLeftovers = [...res[5], ...res[6], ...res[7]];
        const readyData = preapreData('', [], ['Ozon', 'WB'], res[4], newTodayLeftovers, [...res[0], ...res[1], ...res[2]]);
        setHistoryChartData(readyData.newChartData);
        setWidgetData(readyData.newWidgetData);
        setTurnoverTableDatasource(readyData.newTurnoverTableDatasource);
        setTodayLeftovers(newTodayLeftovers);
        setShops(res[3]);
        setProducts(res[4]);
        setIsLoading(false);
        setOriginalData([...res[0], ...res[1], ...res[2]]);
      });
  }, []);

  const onChageSearch = (e) => {
    const { value } = e.target;
    const readyData = preapreData(value, selectedShopArray, selectedMarketplaces, products, todayLeftovers, originalData);
    setHistoryChartData(readyData.newChartData);
    setWidgetData(readyData.newWidgetData);
    setTurnoverTableDatasource(readyData.newTurnoverTableDatasource);
    setSearchValue(value);
  };

  const onChangeShop = (newSelectedShopArray) => {
    const readyData = preapreData(searchValue, newSelectedShopArray, selectedMarketplaces, products, todayLeftovers, originalData);
    setHistoryChartData(readyData.newChartData);
    setWidgetData(readyData.newWidgetData);
    setTurnoverTableDatasource(readyData.newTurnoverTableDatasource);
    setSelectedShopArray(newSelectedShopArray);
  };

  const onChangeMarketplace = (newSelectedMarketplaces) => {
    const readyData = preapreData(searchValue, selectedShopArray, newSelectedMarketplaces, products, todayLeftovers, originalData);
    setHistoryChartData(readyData.newChartData);
    setWidgetData(readyData.newWidgetData);
    setTurnoverTableDatasource(readyData.newTurnoverTableDatasource);
    setSelectedMarketplaces(newSelectedMarketplaces);
  };

  const onChangePeriod = (newPeriodFrom, newPeriodTo) => {
    setIsLoading(true);
    Promise.all([
      getProductCatalogueHistory({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD') }),
      getProductOzonHistory({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD') }),
      getProductWBHistory({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD') }),
    ])
      .then((res) => {
        const readyData = preapreData(searchValue, selectedShopArray, selectedMarketplaces, products, todayLeftovers, [...res[0], ...res[1], ...res[2]]);
        setHistoryChartData(readyData.newChartData);
        setWidgetData(readyData.newWidgetData);
        setTurnoverTableDatasource(readyData.newTurnoverTableDatasource);
        setOriginalData([...res[0], ...res[1], ...res[2]]);
        setIsLoading(false);
        setPeriodFrom(newPeriodFrom);
        setPeriodTo(newPeriodTo);
      });
  };

  return (
    <Col span={23}>
      <TurnoverDesktop
        isLoading={isLoading}
        widgetData={widgetData}
        periodFrom={periodFrom}
        periodTo={periodTo}
        onChangePeriod={onChangePeriod}
        onChageSearch={onChageSearch}
        shops={shops}
        selectedMarketplaces={selectedMarketplaces}
        selectedShopArray={selectedShopArray}
        onChangeShop={onChangeShop}
        onChangeMarketplace={onChangeMarketplace}
        historyChartData={historyChartData}
        turnoverTableDatasource={turnoverTableDatasource}
      />
    </Col>
  );
}

export default TurnoverModule;
