/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Avatar, Col, Row, Card,
} from 'antd';
import ozonLogo from '../../../assets/icons/ozon.jpeg';
import wbLogo from '../../../assets/icons/wb.jpeg';


function Stocks({ product, isLoading }) {
  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Остаток Тверь',
      dataIndex: 'stocksWarehouse',
      key: 'stocksWarehouse',
    },
    {
      title: '',
      dataIndex: 'mpLogos',
      key: 'mpLogos',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col><img src={p.shop === 'Ozon' ? ozonLogo : wbLogo} alt="логотип" style={{ width: '30px', height: '30px' }} /></Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: '',
      dataIndex: 'mpLogos',
      key: 'mpLogos',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.shopName}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Остаток на МП, шт',
      dataIndex: 'stocks',
      key: 'stocks',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.stocksPresent}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Заказы за 30 дней, шт',
      dataIndex: 'orders',
      key: 'orders',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.orders}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    // {
    //   title: 'Продано',
    //   dataIndex: 'sales',
    //   key: 'sales',
    //   render: (_, object) => (
    //     <Col span={24}>
    //       <Row wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.salesOzon}</Col>
    //       </Row>
    //       <Row style={{ marginTop: '10px' }} wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.salesWB}</Col>
    //       </Row>
    //     </Col>
    //   ),
    // },
  ];

  return (
    <Card style={{
      borderRadius: '5px', width: '100%', padding: '0px 0px 20px', margin: '20px 0px',
    }}
    >
      <div style={{ fontSize: '24px', color: '#212121', marginLeft: '16px' }}>Остатки</div>
      <Table columns={columns} dataSource={product ? [product] : []} rowKey="id" loading={isLoading} pagination={false} />
    </Card>
  );
}

Stocks.propTypes = {
  product: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
};

Stocks.defaultProps = {
  product: null,
};

export default Stocks;

