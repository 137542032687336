import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Input, Row, Button,
} from 'antd';


const { Search } = Input;
function Filters({
  onChageSearch, isInitLoading, onAdd,
}) {
  return (
    <Col span={24}>
      <Row justify="space-between">
        <Col span={10}>
          <Search
            placeholder="название"
            onChange={onChageSearch}
            disabled={isInitLoading}
          />
        </Col>
        <Button type="primary" onClick={() => onAdd({})}>Создать рассылку</Button>
      </Row>
    </Col>
  );
}

Filters.propTypes = {
  onChageSearch: PropTypes.func.isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default Filters;

