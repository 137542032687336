/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Avatar, Col, Row, Card,
} from 'antd';
import ozonLogo from '../../../assets/icons/ozon.jpeg';
import wbLogo from '../../../assets/icons/wb.jpeg';


function TableAbout({ product, isLoading }) {
  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Себестоимость, руб',
      dataIndex: 'netCost',
      key: 'netCost',
    },
    {
      title: 'Остаток в Твери, шт',
      dataIndex: 'stocksWarehouse',
      key: 'stocksWarehouse',
    },
    {
      title: '',
      dataIndex: 'mpLogos',
      key: 'mpLogos',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col><img src={p.shop === 'Ozon' ? ozonLogo : wbLogo} alt="логотип" style={{ width: '30px', height: '30px' }} /></Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: '',
      dataIndex: 'mpLogos',
      key: 'mpLogos',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.shopName}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Цена на МП, руб',
      dataIndex: 'price',
      key: 'price',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {`${p.price} руб`}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Остаток на МП, шт',
      dataIndex: 'stocks',
      key: 'stocks',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.stocksPresent}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Заказы за 30 дней, шт',
      dataIndex: 'orders',
      key: 'orders',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsMonth.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.orders}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
  ];

  return (
    <Card style={{ borderRadius: '5px', width: '100%', padding: '0px 0px 20px' }}>
      <div style={{ fontSize: '24px', color: '#212121', marginLeft: '16px' }}>О товаре</div>
      <Table columns={columns} dataSource={[product]} rowKey="id" loading={isLoading} pagination={false} />
    </Card>
  );
}

TableAbout.propTypes = {
  product: PropTypes.shape().isRequired,
  isLoading: PropTypes.bool.isRequired,
};


export default TableAbout;

