/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import {
  Form, Button, Input, Divider,
} from 'antd';
import PropTypes from 'prop-types';
import './LoginPageDesktop.css';


class LoginPageDesktop extends Component {
  render() {
    const {
      loading, onLogin,
    } = this.props;
    return (
      <div className="loginPageDesktop-wrapper">
        <div className="loginPageDesktop-left-wrapper" />
        <div className="loginPageDesktop-column-wrapper">
          <div className="loginPageDesktop-form-wrapper">
            <Form
              initialValues={{ remember: true }}
              onFinish={onLogin}
            >
              <div className="loginPageDesktop-form-title-wrapper">
                <Divider>Авторизация</Divider>
              </div>
              <div className="loginPageDesktop-form-input-label">Логин</div>
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
              >
                <Input disabled={loading} placeholder="введите логин" />
              </Form.Item>
              <div className="loginPageDesktop-form-input-label" style={{ marginTop: '20px' }}>Пароль</div>
              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
              >
                <Input.Password disabled={loading} placeholder="введите пароль" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="loginPageDesktop-form-button" disabled={loading}>
                  Войти
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

LoginPageDesktop.propTypes = {
  loading: PropTypes.bool.isRequired,
  onLogin: PropTypes.func.isRequired,
};

export default LoginPageDesktop;
