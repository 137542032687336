const logisticsCoefficientsFBO = [
  {
    minWeight: 0.1,
    maxWeight: 0.1,
    percent: 0.05,
    minValue: 40,
    maxValue: 100,
  },
  {
    minWeight: 0.2,
    maxWeight: 0.2,
    percent: 0.05,
    minValue: 41,
    maxValue: 105,
  },
  {
    minWeight: 0.3,
    maxWeight: 0.3,
    percent: 0.05,
    minValue: 42,
    maxValue: 115,
  },
  {
    minWeight: 0.4,
    maxWeight: 0.4,
    percent: 0.05,
    minValue: 43,
    maxValue: 120,
  },
  {
    minWeight: 0.5,
    maxWeight: 0.5,
    percent: 0.05,
    minValue: 43,
    maxValue: 125,
  },
  {
    minWeight: 0.6,
    maxWeight: 0.6,
    percent: 0.05,
    minValue: 45,
    maxValue: 130,
  },
  {
    minWeight: 0.7,
    maxWeight: 0.7,
    percent: 0.05,
    minValue: 45,
    maxValue: 135,
  },
  {
    minWeight: 0.8,
    maxWeight: 0.8,
    percent: 0.05,
    minValue: 47,
    maxValue: 140,
  },
  {
    minWeight: 0.9,
    maxWeight: 0.9,
    percent: 0.05,
    minValue: 49,
    maxValue: 145,
  },
  {
    minWeight: 1,
    maxWeight: 1,
    percent: 0.06,
    minValue: 51,
    maxValue: 155,
  },
  {
    minWeight: 1.1,
    maxWeight: 1.1,
    percent: 0.06,
    minValue: 55,
    maxValue: 165,
  },
  {
    minWeight: 1.2,
    maxWeight: 1.2,
    percent: 0.06,
    minValue: 57,
    maxValue: 175,
  },
  {
    minWeight: 1.3,
    maxWeight: 1.3,
    percent: 0.06,
    minValue: 61,
    maxValue: 190,
  },
  {
    minWeight: 1.4,
    maxWeight: 1.4,
    percent: 0.06,
    minValue: 63,
    maxValue: 200,
  },
  {
    minWeight: 1.5,
    maxWeight: 1.5,
    percent: 0.06,
    minValue: 65,
    maxValue: 225,
  },
  {
    minWeight: 1.6,
    maxWeight: 1.6,
    percent: 0.06,
    minValue: 67,
    maxValue: 230,
  },
  {
    minWeight: 1.7,
    maxWeight: 1.7,
    percent: 0.06,
    minValue: 69,
    maxValue: 245,
  },
  {
    minWeight: 1.8,
    maxWeight: 1.8,
    percent: 0.06,
    minValue: 70,
    maxValue: 255,
  },
  {
    minWeight: 1.9,
    maxWeight: 1.9,
    percent: 0.06,
    minValue: 71,
    maxValue: 265,
  },
  {
    minWeight: 2.0,
    maxWeight: 2.9,
    percent: 0.06,
    minValue: 79,
    maxValue: 285,
  },
  {
    minWeight: 3.0,
    maxWeight: 3.9,
    percent: 0.06,
    minValue: 100,
    maxValue: 330,
  },
  {
    minWeight: 4.0,
    maxWeight: 4.9,
    percent: 0.06,
    minValue: 120,
    maxValue: 400,
  },
  {
    minWeight: 5.0,
    maxWeight: 5.9,
    percent: 0.06,
    minValue: 135,
    maxValue: 425,
  },
  {
    minWeight: 6.0,
    maxWeight: 6.9,
    percent: 0.06,
    minValue: 160,
    maxValue: 450,
  },
  {
    minWeight: 7.0,
    maxWeight: 7.9,
    percent: 0.06,
    minValue: 185,
    maxValue: 500,
  },
  {
    minWeight: 8.0,
    maxWeight: 8.9,
    percent: 0.06,
    minValue: 210,
    maxValue: 525,
  },
  {
    minWeight: 9.0,
    maxWeight: 9.9,
    percent: 0.06,
    minValue: 225,
    maxValue: 550,
  },
  {
    minWeight: 10.0,
    maxWeight: 10.9,
    percent: 0.07,
    minValue: 265,
    maxValue: 575,
  },
  {
    minWeight: 11.0,
    maxWeight: 11.9,
    percent: 0.07,
    minValue: 290,
    maxValue: 625,
  },
  {
    minWeight: 12.0,
    maxWeight: 12.9,
    percent: 0.07,
    minValue: 315,
    maxValue: 685,
  },
  {
    minWeight: 13.0,
    maxWeight: 13.9,
    percent: 0.07,
    minValue: 350,
    maxValue: 700,
  },
  {
    minWeight: 14.0,
    maxWeight: 14.9,
    percent: 0.07,
    minValue: 370,
    maxValue: 700,
  },
  {
    minWeight: 15.0,
    maxWeight: 19.9,
    percent: 0.07,
    minValue: 400,
    maxValue: 700,
  },
  {
    minWeight: 20.0,
    maxWeight: 24.9,
    percent: 0.07,
    minValue: 525,
    maxValue: 700,
  },
  {
    minWeight: 25.0,
    maxWeight: 29.9,
    percent: 0.07,
    minValue: 700,
    maxValue: 1750,
  },
  {
    minWeight: 30.0,
    maxWeight: 34.9,
    percent: 0.07,
    minValue: 800,
    maxValue: 1750,
  },
  {
    minWeight: 35.0,
    maxWeight: 999999.9,
    percent: 0.07,
    minValue: 1000,
    maxValue: 1750,
  },
];

export default logisticsCoefficientsFBO;
