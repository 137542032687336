/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Avatar, Row, Card,
} from 'antd';
// import ozonLogo from '../../../../../assets/icons/ozon.jpeg';
// import wbLogo from '../../../../../assets/icons/wb.jpeg';


function TurnoverTable({ dataSource, isLoading }) {
  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
      sorter: (a, b) => a.inventoryNumber.localeCompare(b.inventoryNumber),
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Airtable, руб.',
      dataIndex: 'costAirtable',
      key: 'costAirtable',
    },
    {
      title: 'Airtable, шт.',
      dataIndex: 'amountAirtable',
      key: 'amountAirtable',
    },
    {
      title: 'Ozon, руб.',
      dataIndex: 'costOzon',
      key: 'costOzon',
    },
    {
      title: 'Ozon, шт.',
      dataIndex: 'amountOzon',
      key: 'amountOzon',
    },
    {
      title: 'WB, руб.',
      dataIndex: 'costWB',
      key: 'costWB',
    },
    {
      title: 'WB, шт.',
      dataIndex: 'amountWB',
      key: 'amountWB',
    },
  ];

  return (
    <Row style={{ margin: '20px 0px' }}>
      <Card style={{ borderRadius: '5px', width: '100%' }}>
        <Table columns={columns} dataSource={dataSource} rowKey="id" loading={isLoading} />
      </Card>
    </Row>
  );
}

TurnoverTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TurnoverTable;

