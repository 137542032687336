/* eslint-disable max-len */
import React, { Component } from 'react';
import { orderBy } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Col } from 'antd';
import { config } from '../../utils/config';
import DashboardPageDesktop from './Desktop/DashboardPageDesktop';
import {
  getAllProductsCatalogueFull, getAllOrdersOzonReport, getAllShopsCatalogue, getAllTransactionsOzonReport, getAllOrdersWB, getAllTransactionsWB,
  getAllShopsOzon, getAllShopsWB,
} from '../../utils/http';
import { routeGenerator } from '../../utils/routes';


class DashboardModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitLoading: true,
      products: [],
      shops: [],
      shopsMarketplace: [],
      selectedMarketplaces: ['Ozon', 'Wildberries'],
      selectedShopArray: [],
      searchValue: '',
      orders: [],
      ordersOld: [],
      transactions: [],
      transactionsOld: [],
      isMobile: window.innerWidth <= config.mobileWidth,
      periodFrom: moment(moment().subtract(7, 'day').startOf('day').format('YYYY-MM-DD')),
      periodTo: moment(moment().startOf('day').format('YYYY-MM-DD')),
      widgetData: {
        orderQuantity: {},
        orderSum: {},
        saleQuantity: {},
        saleSum: {},
        profit: {},
      },
      tableDatasource: [],
      chartDatasource: {
        resultBars: [],
        resultLineOrder: [],
        resultLineSale: [],
      },
    };
  }

  componentDidMount() {
    const { periodFrom, periodTo, selectedMarketplaces } = this.state;
    this.setState({ isInitLoading: true });
    Promise.all([
      getAllProductsCatalogueFull(),
      getAllOrdersOzonReport({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllOrdersOzonReport({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
      getAllShopsCatalogue(),
      getAllTransactionsOzonReport({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllTransactionsOzonReport({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
      getAllOrdersWB({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllOrdersWB({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
      getAllTransactionsWB({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllTransactionsWB({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
      getAllShopsOzon(),
      getAllShopsWB(),
    ])
      .then((resp) => {
        const orders = [...resp[1], ...resp[6]];
        const ordersOld = [...resp[2], ...resp[7]];
        const transactions = [...resp[4], ...resp[8]];
        const transactionsOld = [...resp[5], ...resp[9]];
        this.onFilterData('', [], transactions, transactionsOld, orders, ordersOld, resp[0], periodFrom, periodTo, selectedMarketplaces);
        this.setState({
          products: resp[0],
          isInitLoading: false,
          orders,
          ordersOld,
          periodFrom,
          periodTo,
          shops: resp[3],
          transactions,
          transactionsOld,
          shopsMarketplace: [...resp[10], ...resp[11]],
        });
      });
  }


  parseOrdersWidget = (filteredOrders, filteredOrdersOld, filteredTransactions, filteredTransactionsOld) => {
    const orderQuantityNow = filteredOrders.reduce((p, c) => p + Number(c.quantity), 0);
    const orderQuantityBefore = filteredOrdersOld.reduce((p, c) => p + Number(c.quantity), 0);
    const orderSumNow = filteredOrders.reduce((p, c) => p + c.clientOrderSum, 0);
    const orderSumBefore = filteredOrdersOld.reduce((p, c) => p + c.clientOrderSum, 0);
    const saleQuantityNow = filteredTransactions.reduce((p, c) => p + Number(c.quantity), 0);
    const saleQuantitBefore = filteredTransactionsOld.reduce((p, c) => p + Number(c.quantity), 0);
    const saleSumNow = filteredTransactions.reduce((p, c) => p + Number(c.amount), 0);
    const saleSumBefore = filteredTransactionsOld.reduce((p, c) => p + Number(c.amount), 0);
    const profitNow = filteredTransactions.reduce((p, c) => p + Number(c.profit), 0);
    const profitBefore = filteredTransactionsOld.reduce((p, c) => p + Number(c.profit), 0);
    return {
      orderQuantity: {
        now: orderQuantityNow.toFixed(0),
        changeValue: Number(orderQuantityNow - orderQuantityBefore),
        changePercent: (((orderQuantityNow - orderQuantityBefore) / orderQuantityBefore) * 100).toFixed(0),
      },
      orderSum: {
        now: orderSumNow.toFixed(0),
        changeValue: Number(orderSumNow - orderSumBefore),
        changePercent: (((orderSumNow - orderSumBefore) / orderSumBefore) * 100).toFixed(0),
      },
      saleQuantity: {
        now: saleQuantityNow.toFixed(0),
        changeValue: Number(saleQuantityNow - saleQuantitBefore),
        changePercent: (((saleQuantityNow - saleQuantitBefore) / saleQuantitBefore) * 100).toFixed(0),
      },
      saleSum: {
        now: saleSumNow.toFixed(0),
        changeValue: Number(saleSumNow - saleSumBefore),
        changePercent: (((saleSumNow - saleSumBefore) / saleSumBefore) * 100).toFixed(0),
      },
      profit: {
        now: profitNow.toFixed(0),
        changeValue: Number(profitNow - profitBefore),
        changePercent: profitBefore === 0 ? '0' : (((profitNow - profitBefore) / profitBefore) * 100).toFixed(0),
      },
    };
  }

  getTableDatasource = (filteredOrders, filteredTransactions, products) => {
    const orders = filteredOrders.map((o) => ({ ...products.find((p) => p.id === o.productCatalogueId), ...o }));
    const transactions = filteredTransactions.map((o) => ({ ...products.find((p) => p.id === o.productCatalogueId), ...o }));
    const result = [];
    transactions.forEach((t) => {
      const resObj = result.find((r) => r.id === t.productCatalogueId);
      if (resObj) {
        resObj.saleQuantity = Number((resObj.saleQuantity + Number(t.quantity)).toFixed(0));
        resObj.saleSum = parseFloat((resObj.saleSum + Number(t.amount)).toFixed(0));
      } else {
        const newRes = {
          id: t.productCatalogueId,
          name: t.name,
          inventoryNumber: t.inventoryNumber,
          imageURL: t.imageURL,
          orderQuantity: 0,
          orderSum: 0,
          saleQuantity: t.quantity,
          saleSum: t.amount,
        };
        result.push(newRes);
      }
    });
    orders.forEach((o) => {
      const resObj = result.find((r) => r.id === o.productCatalogueId);
      if (resObj) {
        resObj.orderQuantity = Number((resObj.orderQuantity + o.quantity).toFixed(0));
        resObj.orderSum = Number((resObj.orderSum + o.clientOrderSum).toFixed(0));
      } else {
        const newRes = {
          id: o.productCatalogueId,
          name: o.name,
          inventoryNumber: o.inventoryNumber,
          imageURL: o.imageURL,
          orderQuantity: o.quantity,
          orderSum: o.clientOrderSum,
          saleQuantity: 0,
          saleSum: 0,
        };
        result.push(newRes);
      }
    });
    return result;
  }

  getChartDatasource = (filteredOrders, filteredTransactions, periodFrom, periodTo) => {
    const resultBars = [];
    const resultLineOrder = [];
    const resultLineSale = [];
    const startDate = moment(periodFrom);
    while (periodTo.isAfter(startDate)) {
      resultBars.push({
        period: moment(startDate).format('DD.MM.YY'),
        name: 'Продаж, руб',
        count: Number((filteredTransactions.filter((o) => o.transactionDate.isSame(moment(startDate).startOf('day'))).reduce((p, c) => p + c.amount, 0)).toFixed(0)),
      });
      resultBars.push({
        period: moment(startDate).format('DD.MM.YY'),
        name: 'Заказов, руб',
        count: Number(filteredOrders.filter((o) => o.createdAt.isSame(startDate, 'day')).reduce((p, c) => p + c.clientOrderSum, 0).toFixed(0)),
      });
      resultLineOrder.push({
        period: moment(startDate).format('DD.MM.YY'),
        orderCount: Number(filteredOrders.filter((o) => o.createdAt.isSame(startDate, 'day')).reduce((p, c) => p + c.quantity, 0).toFixed(0)),
      });
      resultLineSale.push({
        period: moment(startDate).format('DD.MM.YY'),
        saleCount: Number((filteredTransactions.filter((o) => o.transactionDate.isSame(startDate, 'day')).reduce((p, c) => p + c.quantity, 0)).toFixed(0)),
      });
      startDate.add(1, 'day');
    }
    return {
      resultBars: orderBy(resultBars, 'name', 'asc'),
      resultLineOrder,
      resultLineSale,
    };
  }

  onFilterData = (searchValue, selectedShopArray, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedMarketplaces) => {
    let selectedProducts = [];
    if (searchValue === '') {
      selectedProducts = selectedShopArray.length === 0
        ? products.map((p) => p.id)
        : products.filter((p) => selectedShopArray.includes(p.shopId)).map((p) => p.id);
    } else {
      selectedProducts = selectedShopArray.length === 0
        ? products
          .filter((element) => element.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
          || element.inventoryNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0).map((p) => p.id)
        : products
          .filter((element) => element.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
            || element.inventoryNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
          .filter((p) => selectedShopArray.includes(p.shopId)).map((p) => p.id);
    }
    const filteredOrders = orders.filter((o) => selectedProducts.includes(o.productCatalogueId)).filter(((o) => selectedMarketplaces.includes(o.shop)));
    const filteredOrdersOld = ordersOld.filter((o) => selectedProducts.includes(o.productCatalogueId)).filter(((o) => selectedMarketplaces.includes(o.shop)));
    const filteredTransactions = transactions.filter((o) => selectedProducts.includes(o.productCatalogueId)).filter(((o) => selectedMarketplaces.includes(o.shop)));
    const filteredTransactionsOld = transactionsOld.filter((o) => selectedProducts.includes(o.productCatalogueId)).filter(((o) => selectedMarketplaces.includes(o.shop)));
    const widgetData = this.parseOrdersWidget(filteredOrders, filteredOrdersOld, filteredTransactions, filteredTransactionsOld);
    const tableDatasource = orderBy(this.getTableDatasource(filteredOrders, filteredTransactions, products), 'orderSum', 'desc');
    const chartDatasource = this.getChartDatasource(filteredOrders, filteredTransactions, periodFrom, periodTo);
    this.setState({
      searchValue,
      selectedShopArray,
      widgetData,
      tableDatasource,
      chartDatasource,
      selectedMarketplaces,
    });
  }

  onChageSearch = (e) => {
    const {
      selectedShopArray, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedMarketplaces,
    } = this.state;
    const { value } = e.target;
    this.onFilterData(value, selectedShopArray, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedMarketplaces);
  }

  onChangeShop = (selectedShopArray) => {
    const {
      searchValue, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedMarketplaces,
    } = this.state;
    this.onFilterData(searchValue, selectedShopArray, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedMarketplaces);
  }

  onChangeMarketplace = (selectedMarketplaces) => {
    const {
      searchValue, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedShopArray,
    } = this.state;
    this.onFilterData(searchValue, selectedShopArray, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedMarketplaces);
  }

  onChangePeriod = (periodFrom, periodTo) => {
    const {
      products, searchValue, selectedShopArray, selectedMarketplaces,
    } = this.state;
    this.setState({ isInitLoading: true });
    Promise.all([
      getAllOrdersOzonReport({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllOrdersOzonReport({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
      getAllTransactionsOzonReport({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllTransactionsOzonReport({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
      getAllOrdersWB({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllOrdersWB({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
      getAllTransactionsWB({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD') }),
      getAllTransactionsWB({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD') }),
    ])
      .then((resp) => {
        const orders = [...resp[0], ...resp[4]];
        const ordersOld = [...resp[1], ...resp[5]];
        const transactions = [...resp[2], ...resp[6]];
        const transactionsOld = [...resp[3], ...resp[7]];
        this.onFilterData(searchValue, selectedShopArray, transactions, transactionsOld, orders, ordersOld, products, periodFrom, periodTo, selectedMarketplaces);
        this.setState({
          orders,
          isInitLoading: false,
          periodFrom,
          periodTo,
          ordersOld,
          transactions,
          transactionsOld,
        });
      });
  }

  onRedirectProduct = (productId) => {
    const { history } = this.props;
    history.push(routeGenerator.product(productId));
  }

  render() {
    const {
      isMobile, isInitLoading, periodFrom, periodTo, shops, selectedShopArray, selectedMarketplaces,
      widgetData, tableDatasource, chartDatasource, shopsMarketplace,
    } = this.state;

    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <Col span={23}>
        <DashboardPageDesktop
          tableDatasource={tableDatasource}
          chartDatasource={chartDatasource}
          periodFrom={periodFrom}
          periodTo={periodTo}
          orderQuantity={widgetData.orderQuantity}
          orderSum={widgetData.orderSum}
          saleQuantity={widgetData.saleQuantity}
          saleSum={widgetData.saleSum}
          profit={widgetData.profit}
          onChangePeriod={this.onChangePeriod}
          isInitLoading={isInitLoading}
          onChageSearch={this.onChageSearch}
          shops={shops}
          selectedShopArray={selectedShopArray}
          onChangeShop={this.onChangeShop}
          selectedMarketplaces={selectedMarketplaces}
          onChangeMarketplace={this.onChangeMarketplace}
          onRedirectProduct={this.onRedirectProduct}
        />
      </Col>
    );
  }
}

DashboardModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(DashboardModule);
