/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Avatar, Col, Row, Button,
} from 'antd';
import ozonLogo from '../../../assets/icons/ozon.jpeg';
import wbLogo from '../../../assets/icons/wb.jpeg';


function ProductTable({ tableDatasource, isInitLoading, onRedirectProduct }) {
  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
      render: (text, object) => <Button type="link" style={{ padding: 0 }} onClick={() => onRedirectProduct(object.id)}>{text}</Button>,
      sorter: (a, b) => a.inventoryNumber.localeCompare(b.inventoryNumber),
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Себестоимость, руб',
      dataIndex: 'netCost',
      key: 'netCost',
      sorter: (a, b) => a.netCost - b.netCost,
    },
    {
      title: 'Остаток в Твери, шт',
      dataIndex: 'stocksWarehouse',
      key: 'stocksWarehouse',
      sorter: (a, b) => a.stocksWarehouse - b.stocksWarehouse,
    },
    {
      title: '',
      dataIndex: 'mpLogos',
      key: 'mpLogos',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {object.linkedProductsOzon.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col><img src={ozonLogo} alt="логотип" style={{ width: '30px', height: '30px' }} /></Col>
            </Row>
          ))}
          {object.linkedProductsWb.map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col><img src={wbLogo} alt="логотип" style={{ width: '30px', height: '30px' }} /></Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: '',
      dataIndex: 'mpLogos',
      key: 'mpLogos',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {[...object.linkedProductsOzon, ...object.linkedProductsWb].map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.shopName}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Цена на МП, руб',
      dataIndex: 'price',
      key: 'price',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {[...object.linkedProductsOzon, ...object.linkedProductsWb].map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {`${p.price} руб`}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Остаток на МП, шт',
      dataIndex: 'stocks',
      key: 'stocks',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {[...object.linkedProductsOzon, ...object.linkedProductsWb].map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.stocksPresent}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
    {
      title: 'Заказы за 30 дней, шт',
      dataIndex: 'orders',
      key: 'orders',
      render: (_, object) => (
        <Col span={24} style={{ marginTop: '-10px' }}>
          {[...object.linkedProductsOzon, ...object.linkedProductsWb].map((p) => (
            <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
              <Col style={{
                textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '30px', lineHeight: '30px',
              }}
              >
                {p.orders}
              </Col>
            </Row>
          ))}
        </Col>
      ),
    },
  ];

  return <Table columns={columns} dataSource={tableDatasource} rowKey="id" loading={isInitLoading} />;
}

ProductTable.propTypes = {
  tableDatasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  onRedirectProduct: PropTypes.func.isRequired,
};

export default ProductTable;

