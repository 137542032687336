import React, { useState, useEffect, useRef } from 'react';
import { notification } from 'antd';
import AdministrationPageDesktop from './Desktop/AdministrationPageDesktop';
import {
  getAllProductsOzon, getAllProductsWB, getAllProductsCatalogue, editProductOzon, editProductWB, getAllShopsOzon, getAllShopsWB,
} from '../../utils/http';


function AdministrationModule() {
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState('ozon');
  const [headerDate, setHeaderData] = useState({});
  const [productsCatalogue, setProductsCatalogue] = useState([]);
  const [productsWB, setProductsWB] = useState([]);
  const [productsOzon, setProductsOzon] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const [shopsMarketplace, setShopsMarketplace] = useState([]);
  const [selectedShopMarketplace, setSelectedShopMarketplace] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const tableRef = useRef(null);

  useEffect(() => {
    Promise.all([getAllProductsOzon(), getAllProductsWB(), getAllProductsCatalogue(), getAllShopsOzon(), getAllShopsWB()])
      .then((result) => {
        setIsLoading(false);
        setProductsCatalogue(result[2].map((p) => ({ value: p.id, label: p.inventoryNumber })));
        setProductsWB(result[1]);
        setProductsOzon(result[0]);
        setDatasource(result[0]);
        setShopsMarketplace([...result[3], ...result[4]].map((s) => s.name));
        setHeaderData({
          total: result[2].length,
          ozon: result[0].length,
          wb: result[1].length,
        });
      });
  }, []);

  const onTabClick = (selectedView) => {
    setView(selectedView);
    if (selectedView === 'ozon') {
      setDatasource(productsOzon);
    }
    if (selectedView === 'wb') {
      setDatasource(productsWB);
    }
  };

  const onSelectAirtable = (airtableId, productId) => {
    setIsLoading(true);
    if (view === 'wb') {
      editProductWB({ id: productId, productCatalogue_id: airtableId || '' })
        .then(() => {
          setProductsWB(productsWB.map((p) => (p.id === productId ? { ...p, productCatalogueId: airtableId } : p)));
          setIsLoading(false);
          notification.success({ message: 'Успех', description: 'Изменения сохранены!' });
        });
    }
    if (view === 'ozon') {
      editProductOzon({ id: productId, productCatalogue_id: airtableId || '' })
        .then(() => {
          setProductsOzon(productsOzon.map((p) => (p.id === productId ? { ...p, productCatalogueId: airtableId } : p)));
          setIsLoading(false);
          notification.success({ message: 'Успех', description: 'Изменения сохранены!' });
        });
    }
  };

  const filterDataSource = (newSearchValue, newSelectedShops) => {
    if (view === 'ozon') {
      setDatasource(productsOzon
        .filter((element) => newSelectedShops.length === 0 || newSelectedShops.includes(element.shopName))
        .filter((element) => (`${element.name}`).toLowerCase().indexOf(newSearchValue.toLowerCase()) >= 0
      || (`${element.supplierArticle}`).toLowerCase().indexOf(newSearchValue.toLowerCase()) >= 0));
    }
    if (view === 'wb') {
      setDatasource(productsWB
        .filter((element) => newSelectedShops.length === 0 || newSelectedShops.includes(element.shopName))
        .filter((element) => (`${element.name}`).toLowerCase().indexOf(newSearchValue.toLowerCase()) >= 0
      || (`${element.supplierArticle}`).toLowerCase().indexOf(newSearchValue.toLowerCase()) >= 0));
    }
  };

  const onChageSearch = (e) => {
    const { value } = e.target;
    filterDataSource(value, selectedShopMarketplace);
    setSearchValue(value);
  };

  const onChangeShop = (selectedShopMarketplaceArray) => {
    filterDataSource(searchValue, selectedShopMarketplaceArray);
    setSelectedShopMarketplace(selectedShopMarketplaceArray);
  };

  return (
    <AdministrationPageDesktop
      isLoading={isLoading}
      onTabClick={onTabClick}
      view={view}
      headerDate={headerDate}
      datasource={datasource}
      tableRef={tableRef}
      productsCatalogue={productsCatalogue}
      onSelectAirtable={onSelectAirtable}
      onChageSearch={onChageSearch}
      shopsMarketplace={shopsMarketplace}
      selectedShopMarketplace={selectedShopMarketplace}
      onChangeShop={onChangeShop}
    />
  );
}


export default AdministrationModule;
