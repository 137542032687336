/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Button } from 'antd';


const getColumnsDesktop = (onShow) => [
  {
    title: 'Название рассылки',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    render: (text, object) => <Button type="link" style={{ padding: 0 }} onClick={() => onShow(object)}>{text}</Button>,
  },
  {
    title: 'Дата',
    dataIndex: 'date',
    key: 'date',
    width: '10%',
    render: (text) => (text ? text.format('DD MMMM YYYY') : ''),
  },
  {
    title: 'Кол-во отправлений',
    dataIndex: 'postings',
    key: 'postings',
    width: '10%',
    render: (text) => text.length,
  },
  {
    title: 'Текст рассылки',
    dataIndex: 'text',
    key: 'text',
  },
  {
    title: 'Успешно',
    dataIndex: 'successNumber',
    key: 'successNumber',
    width: '10%',
  },
];

export default getColumnsDesktop;
