import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Input, Select, Row, Button,
} from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';


const { Option } = Select;
const { Search } = Input;
function Filters({
  onChageSearch, isInitLoading, selectedShopArray, onChangeShop, shops, onExportXLS, onSaveProductCalculations, shopsMarketplace,
  selectedShopMarketplace, onChangeBrand,
}) {
  return (
    <Col span={24}>
      <Row justify="space-between">
        <Row gutter={10}>
          <Col>
            <Select
              style={{ width: '15vw', marginBottom: '10px' }}
              disabled={isInitLoading}
              value={selectedShopArray}
              showSearch
              allowClear
              mode="multiple"
              placeholder="Бренд"
              maxTagCount="responsive"
              onChange={onChangeBrand}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {shops.map((u) => <Option key={u.id} value={u.id}>{u.name}</Option>)}
            </Select>
          </Col>
          <Col>
            <Select
              style={{ width: '20vw', marginBottom: '10px' }}
              disabled={isInitLoading}
              value={selectedShopMarketplace}
              showSearch
              allowClear
              mode="multiple"
              placeholder="Магазин"
              maxTagCount="responsive"
              onChange={onChangeShop}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {shopsMarketplace.map((u) => <Option key={u} value={u}>{u}</Option>)}
            </Select>
          </Col>
        </Row>
        <Row gutter={10}>
          {/* <Col>
            <Upload
              accept=".xls,.xlsx"
              action={null}
              fileList={[]}
              showUploadList={false}
              onChange={(e) => onUploadXls(e.file.originFileObj)}
            >
              <Button icon={<UploadOutlined />}>Загрузить XLS</Button>
            </Upload>
          </Col> */}
          <Col><Button type="primary" onClick={onSaveProductCalculations}>Применить</Button></Col>
          <Col><Button icon={<FileExcelOutlined />} onClick={onExportXLS}>Скачать</Button></Col>
          {/* <Col><Button onClick={onReplaceWithCurrentPrices}>Текущие наценки</Button></Col> */}
        </Row>
      </Row>
      <Search
        placeholder="название или артикул"
        onChange={onChageSearch}
        disabled={isInitLoading}
      />
    </Col>
  );
}

Filters.propTypes = {
  onChageSearch: PropTypes.func.isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  shops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShopArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
  onExportXLS: PropTypes.func.isRequired,
  onSaveProductCalculations: PropTypes.func.isRequired,
  shopsMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedShopMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeBrand: PropTypes.func.isRequired,
};

export default Filters;

