/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Table, Avatar, Button } from 'antd';
import formatSumWithSymbol from '../../../utils/formatSumWithSymbol';


function ProductTable({ tableDatasource, isInitLoading, onRedirectProduct }) {
  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
      render: (text, object) => <Button type="link" style={{ padding: 0 }} onClick={() => onRedirectProduct(object.id)}>{text}</Button>,
      sorter: (a, b) => a.offerId.localeCompare(b.offerId),
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Заказано, шт',
      dataIndex: 'orderQuantity',
      key: 'orderQuantity',
      sorter: (a, b) => a.orderQuantity - b.orderQuantity,
    },
    {
      title: 'Заказано, руб',
      dataIndex: 'orderSum',
      key: 'orderSum',
      render: (text) => formatSumWithSymbol(text),
      sorter: (a, b) => a.orderSum - b.orderSum,
    },
    {
      title: 'Продано, шт',
      dataIndex: 'saleQuantity',
      key: 'saleQuantity',
      sorter: (a, b) => a.saleQuantity - b.saleQuantity,
    },
    {
      title: 'Продано, руб',
      dataIndex: 'saleSum',
      key: 'saleSum',
      render: (text) => formatSumWithSymbol(text),
      sorter: (a, b) => a.saleSum - b.saleSum,
    },
  ];

  return <Table columns={columns} dataSource={tableDatasource} rowKey="id" loading={isInitLoading} />;
}

ProductTable.propTypes = {
  tableDatasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  onRedirectProduct: PropTypes.func.isRequired,
};

export default ProductTable;

