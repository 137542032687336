import React, { Component } from 'react';
import { Col } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { config } from '../../utils/config';
import ProductCataloguePageDesktop from './Desktop/ProductCataloguePageDesktop';
import {
  getAllProductsCatalogueFull, getAllOrdersOzon, getAllOrdersWB, getAllShopsCatalogue, getAllShopsOzon, getAllShopsWB, getAllProductsOzon,
  getAllProductsWB,
} from '../../utils/http';
import { routeGenerator } from '../../utils/routes';


class ProductCatalogueModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitLoading: true,
      products: [],
      isMobile: window.innerWidth <= config.mobileWidth,
      datasource: [],
      periodFrom: moment(moment().subtract(30, 'day').startOf('day').format('YYYY-MM-DD')),
      periodTo: moment(moment().startOf('day').format('YYYY-MM-DD')),
      shops: [],
      shopsMarketplace: [],
      selectedShopMarketplace: [],
      selectedShopArray: [],
      searchValue: '',
    };
  }

  componentDidMount() {
    this.getData();
  }

  parseData = (productsAirtable, productsWB, productsOzon, ordersOzon, ordersWB, periodFrom, periodTo) => {
    const parsedProducts = [];
    productsAirtable.forEach((product) => {
      const linkedProductsWb = productsWB
        .filter((p) => p.productCatalogueId === product.id)
        .map((p) => {
          const updatedProduct = {
            ...p,
            orders: ordersWB
              .filter((order) => order.productId === p.id)
              .filter((o) => o.createdAt && o.createdAt.isBetween(periodFrom, periodTo, undefined, '[]'))
              .reduce((accum, element) => accum + Number(element.quantity), 0),
          };
          return updatedProduct;
        });

      const linkedProductsOzon = productsOzon
        .filter((p) => p.productCatalogueId === product.id)
        .map((p) => {
          const updatedProduct = {
            ...p,
            orders: ordersOzon
              .filter((order) => order.productId === p.id)
              .filter((o) => o.createdAt && o.createdAt.isBetween(periodFrom, periodTo, undefined, '[]'))
              .reduce((accum, element) => accum + Number(element.quantity), 0),
          };
          return updatedProduct;
        });

      parsedProducts.push({
        ...product,
        linkedProductsWb,
        linkedProductsOzon,
      });
    });
    return parsedProducts;
  }

  getData = () => {
    const { periodFrom, periodTo } = this.state;
    this.setState({ isInitLoading: true });
    Promise.all([
      getAllProductsCatalogueFull(),
      getAllOrdersOzon({ periodFrom: periodFrom.toISOString(), periodTo: periodTo.toISOString() }),
      getAllOrdersWB({ periodFrom: periodFrom.toISOString(), periodTo: periodTo.toISOString() }),
      getAllShopsCatalogue(),
      getAllShopsOzon(),
      getAllShopsWB(),
      getAllProductsOzon(),
      getAllProductsWB(),
    ])
      .then((res) => {
        const parsedProducts = this.parseData(res[0], res[7], res[6], res[1], res[2], periodFrom, periodTo);
        this.setState({
          products: parsedProducts,
          isInitLoading: false,
          datasource: parsedProducts,
          shops: res[3],
          shopsMarketplace: [...res[4], ...res[5]],
        });
      });
  }

  onFilterData = (searchValue, selectedShopArray, selectedShopMarketplace) => {
    const { products } = this.state;
    const updatedDatasource = [...products]
      .filter((element) => element.name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    || element.inventoryNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
      .filter((p) => (selectedShopArray.length === 0 ? true : selectedShopArray.includes(p.shopId)))
      .filter((p) => (selectedShopMarketplace.length === 0
        ? true
        : selectedShopMarketplace.some((r) => [
          ...p.linkedProductsWb.map((l) => l.shopName), ...p.linkedProductsOzon.map((l) => l.shopName)].includes(r))));

    this.setState({
      searchValue,
      selectedShopArray,
      selectedShopMarketplace,
      datasource: updatedDatasource,
    });
  }

  onChageSearch = (e) => {
    const { selectedShopArray, selectedShopMarketplace } = this.state;
    const { value } = e.target;
    this.onFilterData(value, selectedShopArray, selectedShopMarketplace);
  }

  onChangeShop = (selectedShopArray) => {
    const { searchValue, selectedShopMarketplace } = this.state;
    this.onFilterData(searchValue, selectedShopArray, selectedShopMarketplace);
  }

  onChangeShopMarketplace = (selectedShopMarketplaceArray) => {
    const { searchValue, selectedShopArray } = this.state;
    this.onFilterData(searchValue, selectedShopArray, selectedShopMarketplaceArray);
  }

  onRedirectProduct = (productId) => {
    const { history } = this.props;
    history.push(routeGenerator.product(productId));
  }

  render() {
    const {
      isMobile, isInitLoading, datasource, shops, selectedShopArray, shopsMarketplace, selectedShopMarketplace,
    } = this.state;

    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <Col span={23}>
        <ProductCataloguePageDesktop
          datasource={datasource}
          isInitLoading={isInitLoading}
          onChageSearch={this.onChageSearch}
          shops={shops}
          selectedShopArray={selectedShopArray}
          onChangeShop={this.onChangeShop}
          onRedirectProduct={this.onRedirectProduct}
          shopsMarketplace={shopsMarketplace}
          selectedShopMarketplace={selectedShopMarketplace}
          onChangeShopMarketplace={this.onChangeShopMarketplace}
        />
      </Col>
    );
  }
}

ProductCatalogueModule.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default withRouter(ProductCatalogueModule);
