import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { message, notification, Col } from 'antd';
import { get } from 'lodash';
import { getUser } from '../../utils/http';
import { setLocalStorage } from '../../utils/localeStorage/storage';
import api from '../../utils/directualAPI/api';
import { config } from '../../utils/config';
import { routes } from '../../utils/routes';
import LoginPageDesktop from './Desktop/LoginPageDesktop';


class LoginModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= config.mobileWidth,
      loading: false,
    };
  }

  onLogin = (values) => {
    this.setState({ loading: true });
    api.structure().auth(values.username, values.password)
      .then((response) => {
        if (response.isError) {
          if (response.isPassError) {
            message.error('Ошибка! Проверьте правильность ввода логина или пароля');
          } else {
            message.error(response.token);
          }
          this.setState({ loading: false });
        } else {
          setLocalStorage({
            token: response.token,
            id: values.username,
          });
          this.setState({ loading: false });
          notification.success({
            message: 'Успешно авторизованы!',
          });
          const { location, history } = this.props;
          const from = get(location, 'state.from.pathname', routes.dashboard);
          history.push(from);
        }
      })
      .catch((error) => message.error(error.message));
  }

  render() {
    const { isMobile, loading } = this.state;
    if (isMobile) {
      return <div />;
    }
    return (
      <Col span={24}>
        <LoginPageDesktop loading={loading} onLogin={this.onLogin} />
      </Col>
    );
  }
}

LoginModule.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
};

export default withRouter(LoginModule);
