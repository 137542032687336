import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, Statistic,
} from 'antd';
import './AdministrationHeader.css';


function AdministrationHeader({ headerData, isLoading }) {
  return (
    <div className="administrationHeader-row">
      <Card style={{ borderRadius: '5px', width: '25%', marginRight: '1%' }}>
        <Statistic
          loading={isLoading}
          title="Всего товаров в каталоге"
          value={headerData.total}
          precision={0}
        />
      </Card>
      <Card style={{ borderRadius: '5px', width: '25%', marginRight: '1%' }}>
        <Statistic
          loading={isLoading}
          title="Товаров WB"
          value={headerData.wb}
          precision={0}
        />
      </Card>
      <Card style={{ borderRadius: '5px', width: '25%' }}>
        <Statistic
          loading={isLoading}
          title="Товаров OZON"
          value={headerData.ozon}
          precision={0}
        />
      </Card>
    </div>
  );
}

AdministrationHeader.propTypes = {
  headerData: PropTypes.shape().isRequired,
  isLoading: PropTypes.shape().isRequired,
};

export default AdministrationHeader;
