/* eslint-disable prefer-spread */
/* eslint-disable no-return-assign */
import React from 'react';
import {
  Chart,
  Tooltip,
  Interval,
  Interaction,
} from 'bizcharts';
import { Card } from 'antd';
import PropTypes from 'prop-types';


function HistoryStocksChart({ dataSource }) {
  const saleMax = Math.max.apply(Math, dataSource.map((o) => o.count));
  const tickCount = Number((saleMax / 10).toFixed());
  const scale = {
    period: {
      sync: true,
    },
    count: {
      min: 0,
      max: 10 * tickCount,
    },
  };
  return (
    <Card style={{ borderRadius: '5px', width: '100%', margin: '20px 0px' }}>
      <div style={{
        fontSize: '24px', color: '#212121', marginLeft: '16px', marginBottom: '20px',
      }}
      >
        История остатков
      </div>
      <Chart height={400} padding={[20, 80, 50, 80]} data={dataSource} autoFit scale={scale}>
        <Interval
          adjust={[
            {
              type: 'dodge',
              marginRatio: 0,
            },
          ]}
          color={['name', (nameVal) => {
            if (nameVal === 'Остатки, Ozon') {
              return '#6495f9';
            }
            if (nameVal === 'Остатки, WB') {
              return '#86128f';
            }
            if (nameVal === 'Остатки, Airtable') {
              return '#fbb400';
            }
            return 'red';
          }]}
          position="period*count"
        />
        <Interaction type="active-region" />
        <Tooltip shared />
      </Chart>
    </Card>
  );
}

HistoryStocksChart.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};


export default HistoryStocksChart;
