/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-cond-assign */
/* eslint-disable no-nested-ternary */
import moment from 'moment';


const ExcelJS = require('exceljs');


const shops = ['Ozon Valben (ID 198640)', 'WB Valben (ID 304554)', 'Ozon БМП (ID 22009)', 'Ozon Играй Гуляй (ID 520793)', 'WB Играй Гуляй (ID 875879)', 'WB БМП (ID 106712)'];

function toColumnName(num) {
  let ret = '';
  let a = 1;
  let b = 1;
  for (ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}

const fields = [
  {
    key: 'brand',
    name: 'Бренд',
    width: 30,
  },
  {
    key: 'offerId',
    name: 'Артикул',
    width: 30,
  },
  {
    key: 'name',
    name: 'Название',
    width: 60,
  },
  {
    key: 'selfCost',
    name: 'Себестоимость',
    width: 30,
  },
  {
    key: 'shops',
    name: '',
    fields: [
      {
        key: 'price',
        name: 'Цена',
        width: 30,
      },
      {
        key: 'priceCalculated',
        name: 'Расчетная цена',
        width: 30,
      },
      {
        key: 'commissionsTotal',
        name: 'Сборы',
        width: 30,
      },
      {
        key: 'margin',
        name: 'Наценка',
        width: 30,
      },
      {
        key: 'profit',
        name: 'Прибыль',
        width: 30,
      },
    ],
  },
];


const prepareXlsData = (rawData) => {
  const result = {
    fields,
    objects: [],
  };
  rawData.forEach((el, index) => {
    const shopsValues = [];
    shops.forEach((shopName) => {
      const linkedProduct = el.linkedProducts.find((p) => p.shopName === shopName);
      const shopsValuesObj = {
        price: linkedProduct?.price,
        priceCalculated: linkedProduct?.priceCalculated,
        commissionsTotal: linkedProduct?.commissionsTotal,
        margin: linkedProduct?.margin,
        profit: linkedProduct?.profit,
      };
      shopsValues.push(shopsValuesObj);
    });
    const obj = {
      id: el.id,
      index: index + 1,
      brand: el.shopValue,
      offerId: el.inventoryNumber,
      name: el.name,
      selfCost: el.netCost,
      shops: shopsValues,
    };
    result.objects.push(obj);
  });
  return result;
};


const createTotalSheet = (data, worksheet) => {
  // стиль столбцов и название шапки
  data.fields.forEach((field, index) => {
    if (field.key !== 'shops') {
      worksheet.getColumn(index + 1).font = { name: 'Montserrat' };
      worksheet.getColumn(index + 1).width = field.width;

      const cell = worksheet.getCell((`${toColumnName(index + 1)}1`));
      cell.value = field.name;
      cell.alignment = { vertical: 'middle' };
      cell.style = { font: { bold: true } };
    } else {
      shops.forEach((shop, ind) => {
        field.fields.forEach((f, i) => {
          worksheet.getColumn(index + i + ind * field.fields.length + 1).font = { name: 'Montserrat' };
          worksheet.getColumn(index + i + ind * field.fields.length + 1).width = f.width;

          const cell = worksheet.getCell((`${toColumnName(index + i + ind * field.fields.length + 1)}1`));
          cell.value = `${f.name} ${shop}`;
          cell.alignment = { vertical: 'middle' };
          cell.style = { font: { bold: true } };
        });
      });
    }
  });

  // создаем строчки
  data.objects.forEach((el) => {
    const rowXls = worksheet.getRow(el.index + 1);
    data.fields.forEach((field, index) => {
      if (field.key !== 'shops') {
        const fieldXls = rowXls.getCell(index + 1);
        fieldXls.value = el[field.key];
        fieldXls.alignment = {
          vertical: 'middle',
          wrapText: true,
        };
      } else {
        Array(shops.length).fill(0).forEach((_, ind) => {
          field.fields.forEach((f, i) => {
            const fieldXls = rowXls.getCell(index + i + ind * field.fields.length + 1);
            fieldXls.value = el.shops[ind]?.[f.key];
            fieldXls.alignment = {
              vertical: 'middle',
              wrapText: true,
            };
          });
        });
      }
    });
  });
};

const createXLSDocument = async (data) => {
  const workbook = new ExcelJS.Workbook();
  workbook.creator = 'Admin';
  workbook.lastModifiedBy = 'Администратор';
  workbook.created = new Date();
  workbook.modified = new Date();
  workbook.lastPrinted = new Date();

  const worksheet = workbook.addWorksheet('Отчет');
  createTotalSheet(data, worksheet);

  const buffer = await workbook.xlsx.writeBuffer();
  return buffer;
};

const arrayBufferToBase64 = (Arraybuffer) => {
  let binary = '';
  const bytes = new Uint8Array(Arraybuffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i += 1) {
    binary += String.fromCharCode(bytes[i]);
  }
  const base64String = window.btoa(binary);
  const linkSource = `data:application/xls;base64,${base64String}`;
  const downloadLink = document.createElement('a');
  const fileName = 'Отчет.xls';

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};

const onExportXLS = async (rawData) => {
  const data = prepareXlsData(rawData);
  const buffer = await createXLSDocument(data);
  arrayBufferToBase64(buffer);
};


export default onExportXLS;
