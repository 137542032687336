/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Avatar, Col, Row, Input, Popover, Button,
} from 'antd';
import ozonLogo from '../../../assets/icons/ozon.jpeg';
import wbLogo from '../../../assets/icons/wb.jpeg';


const getColumnsDesktop = (onRecalculateObject, onRedirectProduct) => [
  {
    title: 'Артикул',
    dataIndex: 'inventoryNumber',
    key: 'inventoryNumber',
    width: 150,
    render: (text, object) => <Button type="link" style={{ padding: 0 }} onClick={() => onRedirectProduct(object.id)}>{text}</Button>,
  },
  {
    title: '',
    dataIndex: 'imageURL',
    key: 'imageURL',
    width: 100,
    render: (text) => <Avatar shape="square" src={text} size={50} />,
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    width: 300,
  },
  {
    title: 'Себестоимость',
    dataIndex: 'netCost',
    key: 'netCost',
    width: 200,
    render: (text) => `${text.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`,
  },
  {
    title: '',
    dataIndex: 'mpLogos',
    key: 'mpLogos',
    width: 80,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
            <Col><img src={p.shop === 'Ozon' ? ozonLogo : wbLogo} alt="логотип" style={{ width: '40px', height: '40px' }} /></Col>
          </Row>
        ))}
      </Col>
    ),
  },
  {
    title: 'Магазин',
    dataIndex: 'mpLogos',
    key: 'mpLogos',
    width: 220,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
            <Col style={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '40px', lineHeight: '40px',
            }}
            >
              {p.shopName}
            </Col>
          </Row>
        ))}
      </Col>
    ),
  },
  {
    title: 'Текущая цена',
    dataIndex: 'price',
    key: 'price',
    width: 150,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
            <Col style={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '40px', lineHeight: '40px',
            }}
            >
              {`${(p.price || '').toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}
            </Col>
          </Row>
        ))}
      </Col>
    ),
  },
  {
    title: 'Расчетная цена',
    dataIndex: 'priceCalculated',
    key: 'priceCalculated',
    width: 150,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
            <Col style={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '40px', lineHeight: '40px',
            }}
            >
              <Input
                style={{ width: '100%' }}
                defaultValue={p.priceCalculated}
                onChange={(e) => onRecalculateObject(e.target.value, p.otherCosts, object, p.shop, p)}
              />
            </Col>
          </Row>
        ))}
      </Col>
    ),
  },
  {
    title: 'Расходы',
    dataIndex: 'otherCosts',
    key: 'otherCosts',
    width: 150,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
            <Col style={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '40px', lineHeight: '40px',
            }}
            >
              <Input
                style={{ width: '100%' }}
                defaultValue={p.otherCosts}
                onChange={(e) => onRecalculateObject(p.priceCalculated, e.target.value, object, p.shop, p)}
              />
            </Col>
          </Row>
        ))}
      </Col>
    ),
  },
  {
    title: 'Наценка',
    dataIndex: 'margin',
    key: 'margin',
    width: 150,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
            <Col style={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '40px', lineHeight: '40px',
            }}
            >
              {`${p.margin || 0}%`}
            </Col>
          </Row>
        ))}
      </Col>
    ),
  },
  {
    title: 'Прибыль',
    dataIndex: 'profit',
    key: 'profit',
    width: 150,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} key={p.id} style={{ marginTop: '10px' }}>
            <Col style={{
              textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '40px', lineHeight: '40px',
            }}
            >
              {`${(p.profit || '0').toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}
            </Col>
          </Row>
        ))}
      </Col>
    ),
  },
  {
    title: 'Сборы',
    dataIndex: 'commissions',
    key: 'commissions',
    width: 150,
    render: (_, object) => (
      <Col span={24} style={{ marginTop: '-10px' }}>
        {object.linkedProducts.map((p) => (
          <Row wrap={false} style={{ marginTop: '10px' }}>
            <Popover
              content={(
                <Col span={24} style={{ width: '13vw' }}>
                  <Row justify="space-between">
                    <Col>Комиссия МП:</Col>
                    <Col>{`${p.commissionsValue.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Доставка:</Col>
                    <Col>{`${p.commissionsLogistics.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Хранение:</Col>
                    <Col>{`${p.commissionsStorage.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Продвижение:</Col>
                    <Col>{`${p.commissionsMarketing.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Эквайринг:</Col>
                    <Col>{`${p.bankComission.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                  <Row justify="space-between">
                    <Col>Обратная доставка:</Col>
                    <Col>{`${p.commissionsLogisticsBack.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}</Col>
                  </Row>
                </Col>
)}
              title="Сборы на МП"
            >
              <Col style={{
                color: '#1890ff', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', height: '40px', lineHeight: '40px',
              }}
              >
                {`${p.commissionsTotal.toLocaleString(undefined, { minimumFractionDigits: 2 })}  ₽`}
              </Col>
            </Popover>
          </Row>
        ))}
      </Col>
    ),
  },
];

export default getColumnsDesktop;
