const formulaWB = (priceCalculated, otherCosts, commissionPercent, {
  length, height, width, weight, selfCost,
}) => {
  let isKGT = false; // Проверяем крупногоборитность (в см и кг)
  if (length > 120 || height > 120 || width > 120 || length + height + width > 200 || weight > 25) {
    isKGT = true;
  }
  const volume = (length * height * width) / 1000; // Объем
  const commissionsValue = (commissionPercent / 100) * priceCalculated; // Комиссия МП
  let commissionsLogistics = 0; // Доставка
  if (volume <= 3 && !isKGT) {
    commissionsLogistics = 81;
  } else if (volume >= 3 && !isKGT) {
    commissionsLogistics = 81 + 9 * (volume - 3);
  } else if (isKGT && (81 + 9 * (volume - 3)) < 1000) {
    commissionsLogistics = 1000;
  } else if (isKGT && (81 + 9 * (volume - 3)) >= 1000) {
    commissionsLogistics = 81 + 9 * (volume - 3);
  }
  let commissionsStorage = 0; // Хранение
  if (volume < 3) {
    commissionsStorage = 14 * 0.1; // 25 - оборачиваемость
  } else {
    commissionsStorage = 14 * (0.63 + 0.072 * (volume - 3));
  }
  const bankComission = 0.015 * priceCalculated; // Эквайринг
  const commissionsMarketing = 0.02 * priceCalculated; // Продвижение
  let commissionsLogisticsBack = 0; // Обратная доставка
  if (isKGT) {
    commissionsLogisticsBack = 33 + 1000;
  } else {
    commissionsLogisticsBack = 33;
  }
  commissionsLogisticsBack *= 0.05;
  const commissionsTotal = commissionsMarketing
   + commissionsValue + commissionsLogistics + commissionsStorage + bankComission + commissionsLogisticsBack; // Общая комиссия
  const profit = priceCalculated - selfCost - otherCosts - commissionsTotal; // Прибыль
  const marginToSelfCostFBO = profit / (selfCost + otherCosts); // Наценка к себестоимости
  const margin = (priceCalculated / (selfCost + otherCosts + commissionsTotal) - 1) * 100;// Наценка

  return {
    margin: parseFloat(margin.toFixed(2)),
    commissionsValue: parseFloat(commissionsValue.toFixed(2)),
    commissionsLogistics: parseFloat(commissionsLogistics.toFixed(2)),
    commissionsStorage: parseFloat(commissionsStorage.toFixed(2)),
    bankComission: parseFloat(bankComission.toFixed(2)),
    commissionsLogisticsBack: parseFloat(commissionsLogisticsBack.toFixed(2)),
    commissionsTotal: parseFloat(commissionsTotal.toFixed(2)),
    profit: parseFloat(profit.toFixed(2)),
    marginToSelfCostFBO: parseFloat(marginToSelfCostFBO.toFixed(2)),
    commissionsMarketing: parseFloat(commissionsMarketing.toFixed(2)),
    priceCalculated,
    otherCosts,
  };
};

export default formulaWB;


// selfCost - берем из Airtable
// размеры тянутся из Airtable
// priceCalculated - вводим на сайте
// otherCosts - вводим на сайте

// commissionPercent - из справочника коммисий по категории (справочник ручной). commissionPercent на товаре

