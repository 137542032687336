/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import getColumnsDesktop from '../utils/getColumnsDesktop';


function MailingTable({
  datasource, loading, onShow,
}) {
  return (
    <Table
      dataSource={datasource}
      columns={getColumnsDesktop(onShow)}
      style={{ backgroundColor: '#fff', width: '100%', height: '65vh' }}
      loading={loading}
      scroll={{ y: '60vh' }}
      rowKey="id"
      pagination={false}
    />
  );
}

MailingTable.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  loading: PropTypes.bool.isRequired,
  onShow: PropTypes.func.isRequired,
};

export default MailingTable;

