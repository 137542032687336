import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card, Statistic,
} from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import './DashboardPageDesktop.css';
import Chart from '../Components/Chart';
import ProductTable from '../Components/Table';
import Filters from '../Components/Filters';


function DashboardPageDesktop({
  tableDatasource, chartDatasource, orderQuantity, orderSum, saleQuantity, saleSum, profit, periodFrom, periodTo, onChangePeriod,
  isInitLoading, onChageSearch, shops, selectedShopArray, onChangeShop, selectedMarketplaces, onChangeMarketplace, onRedirectProduct,
}) {
  return (
    <Col className="dashboardPageDesktop-wrapper">
      <Row className="dashboardPageDesktop-title">Дашборд</Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Filters
            periodFrom={periodFrom}
            periodTo={periodTo}
            onChangePeriod={onChangePeriod}
            isInitLoading={isInitLoading}
            onChageSearch={onChageSearch}
            shops={shops}
            selectedShopArray={selectedShopArray}
            onChangeShop={onChangeShop}
            selectedMarketplaces={selectedMarketplaces}
            onChangeMarketplace={onChangeMarketplace}
          />
        </Card>
      </Row>
      <Row gutter={10}>
        <Col span={4}>
          <Card style={{ borderRadius: '5px' }}>
            <Statistic title="Заказы, шт" value={orderQuantity.now} valueStyle={{ fontSize: '16px' }} loading={isInitLoading} />
            <Statistic
              loading={isInitLoading}
              value={orderQuantity.changeValue}
              precision={0}
              valueStyle={{ color: orderQuantity.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
              prefix={orderQuantity.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              suffix={`(${orderQuantity.changePercent}%)`}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ borderRadius: '5px' }}>
            <Statistic title="Заказы, руб" value={orderSum.now} valueStyle={{ fontSize: '16px' }} loading={isInitLoading} />
            <Statistic
              loading={isInitLoading}
              value={orderSum.changeValue}
              precision={0}
              valueStyle={{ color: orderSum.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
              prefix={orderSum.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              suffix={`(${orderSum.changePercent}%)`}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ borderRadius: '5px' }}>
            <Statistic title="Продажи, шт" value={saleQuantity.now} valueStyle={{ fontSize: '16px' }} loading={isInitLoading} />
            <Statistic
              loading={isInitLoading}
              value={saleQuantity.changeValue}
              precision={0}
              valueStyle={{ color: saleQuantity.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
              prefix={saleQuantity.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              suffix={`(${saleQuantity.changePercent}%)`}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ borderRadius: '5px' }}>
            <Statistic title="Продажи, руб" value={saleSum.now} valueStyle={{ fontSize: '16px' }} loading={isInitLoading} />
            <Statistic
              loading={isInitLoading}
              value={saleSum.changeValue}
              precision={0}
              valueStyle={{ color: saleSum.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
              prefix={saleSum.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              suffix={`(${saleSum.changePercent}%)`}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ borderRadius: '5px' }}>
            <Statistic title="Прибыль, руб" value={profit.now} valueStyle={{ fontSize: '16px' }} loading={isInitLoading} />
            <Statistic
              loading={isInitLoading}
              value={profit.changeValue}
              precision={0}
              valueStyle={{ color: profit.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
              prefix={profit.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
              suffix={`(${profit.changePercent}%)`}
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card style={{ borderRadius: '5px' }}>
            <Statistic
              loading={isInitLoading}
              title="Возвраты"
              value={0}
              precision={0}
            />
          </Card>
        </Col>
      </Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Chart chartDatasource={chartDatasource} />
        </Card>
      </Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <ProductTable tableDatasource={tableDatasource} isInitLoading={isInitLoading} onRedirectProduct={onRedirectProduct} />
        </Card>
      </Row>
    </Col>
  );
}

DashboardPageDesktop.propTypes = {
  tableDatasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  chartDatasource: PropTypes.shape().isRequired,
  orderQuantity: PropTypes.shape().isRequired,
  orderSum: PropTypes.shape().isRequired,
  saleQuantity: PropTypes.shape().isRequired,
  saleSum: PropTypes.shape().isRequired,
  profit: PropTypes.shape().isRequired,
  periodTo: PropTypes.shape().isRequired,
  periodFrom: PropTypes.shape().isRequired,
  onChangePeriod: PropTypes.func.isRequired,
  isInitLoading: PropTypes.bool.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  shops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShopArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
  selectedMarketplaces: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeMarketplace: PropTypes.func.isRequired,
  onRedirectProduct: PropTypes.func.isRequired,
};


export default DashboardPageDesktop;
