export const logisticsOzon = [
  { min: 0, max: 1.9, value: 58 },
  { min: 1.901, max: 2.9, value: 61 },
  { min: 2.901, max: 4.9, value: 63 },
  { min: 4.901, max: 5.9, value: 67 },
  { min: 5.901, max: 6.9, value: 69 },
  { min: 6.901, max: 7.9, value: 71 },
  { min: 7.901, max: 8.4, value: 73 },
  { min: 8.401, max: 8.9, value: 75 },
  { min: 8.901, max: 9.4, value: 76 },
  { min: 9.401, max: 9.9, value: 77 },
  { min: 9.901, max: 14.9, value: 85 },
  { min: 14.901, max: 19.9, value: 111 },
  { min: 19.901, max: 24.9, value: 126 },
  { min: 24.901, max: 29.9, value: 141 },
  { min: 29.901, max: 34.9, value: 166 },
  { min: 34.901, max: 39.9, value: 191 },
  { min: 39.901, max: 44.9, value: 216 },
  { min: 44.901, max: 49.9, value: 231 },
  { min: 49.901, max: 54.9, value: 271 },
  { min: 54.901, max: 59.9, value: 296 },
  { min: 59.901, max: 64.9, value: 321 },
  { min: 64.901, max: 69.9, value: 356 },
  { min: 69.901, max: 74.9, value: 376 },
  { min: 74.901, max: 99.9, value: 406 },
  { min: 99.901, max: 124.9, value: 531 },
  { min: 124.901, max: 149.9, value: 706 },
  { min: 149.901, max: 174.9, value: 906 },
  { min: 174.901, max: 99999999, value: 1106 },
];
