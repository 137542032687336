/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Card, Button,
} from 'antd';


function Comment({ productComments, isLoading, editProductComment }) {
  const columns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      render: (text, object) => <a onClick={() => editProductComment(object)}>{text}</a>,
    },
    {
      title: 'Автор',
      dataIndex: 'author',
      key: 'author',
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
    },
  ];

  return (
    <Card style={{
      borderRadius: '5px', width: '100%', padding: '0px 0px 20px', margin: '20px 0px 0px 0px ',
    }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '24px', color: '#212121', marginLeft: '16px' }}>Комментарии</div>
        <Button type="primary" onClick={() => editProductComment({})}>Добавить</Button>
      </div>
      <Table
        columns={columns}
        dataSource={productComments}
        rowKey="id"
        loading={isLoading}
        pagination={false}
      />
    </Card>
  );
}

Comment.propTypes = {
  productComments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  editProductComment: PropTypes.func.isRequired,
};


export default Comment;

