import React, { Component } from 'react';
import moment from 'moment';
import ru from 'moment/locale/ru';
import { ConfigProvider, Col, Row } from 'antd';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ru_RU from 'antd/es/locale/ru_RU';
import PrivateRoute from './utils/privateRoute';
import { routes } from './utils/routes';
import SideBar from './components/SideBar/SideBar';
import LoginModule from './pages/Login/LoginModule';
import DashboardModule from './pages/Dashboard/DashboardModule';
import ProductCatalogueModule from './pages/ProductCatalogue/ProductCatalogueModule';
import AdministrationModule from './pages/Administration/AdministrationModule';
import PriceCalculatorModule from './pages/PriceCalculator/PriceCalculatorModule';
import ProductModule from './pages/Product/ProductModule';
import CalculationModule from './pages/Calculation/CalculationModule';
import MailingModule from './pages/Mailing/MailingModule';
import TurnoverModule from './pages/Turnover/TurnoverModule';


moment.updateLocale('ru', ru);
export default class App extends Component {
  render() {
    return (
      <ConfigProvider locale={ru_RU}>
        <Router>
          <Col span={24}>
            <Row>
              <SideBar />
              <Switch>
                <Route key="login" path={routes.login} component={LoginModule} />
                <Redirect exact from={routes.home} to={routes.dashboard} />
                <PrivateRoute path={routes.dashboard}>
                  <DashboardModule />
                </PrivateRoute>
                <PrivateRoute path={routes.productCatalogue}>
                  <ProductCatalogueModule />
                </PrivateRoute>
                <PrivateRoute path={routes.administration}>
                  <AdministrationModule />
                </PrivateRoute>
                <PrivateRoute path={routes.priceCalculator}>
                  <PriceCalculatorModule />
                </PrivateRoute>
                <PrivateRoute path={routes.product}>
                  <ProductModule />
                </PrivateRoute>
                <PrivateRoute path={routes.calculation}>
                  <CalculationModule />
                </PrivateRoute>
                <PrivateRoute path={routes.mailing}>
                  <MailingModule />
                </PrivateRoute>
                <PrivateRoute path={routes.turnover}>
                  <TurnoverModule />
                </PrivateRoute>
              </Switch>
            </Row>
          </Col>
        </Router>
      </ConfigProvider>
    );
  }
}


