const parseWidgetData = (filteredOrders, filteredOrdersOld, filteredTransactions, filteredTransactionsOld) => {
  const orderQuantityNow = filteredOrders.reduce((p, c) => p + Number(c.quantity), 0);
  const orderQuantityBefore = filteredOrdersOld.reduce((p, c) => p + Number(c.quantity), 0);
  const orderSumNow = filteredOrders.reduce((p, c) => p + c.clientOrderSum, 0);
  const orderSumBefore = filteredOrdersOld.reduce((p, c) => p + c.clientOrderSum, 0);
  const saleQuantityNow = filteredTransactions.reduce((p, c) => p + Number(c.quantity), 0);
  const saleQuantitBefore = filteredTransactionsOld.reduce((p, c) => p + Number(c.quantity), 0);
  const saleSumNow = filteredTransactions.reduce((p, c) => p + Number(c.amount), 0);
  const saleSumBefore = filteredTransactionsOld.reduce((p, c) => p + Number(c.amount), 0);
  return {
    orderQuantity: {
      now: orderQuantityNow.toFixed(0),
      changeValue: Number(orderQuantityNow - orderQuantityBefore),
      changePercent: (((orderQuantityNow - orderQuantityBefore) / orderQuantityBefore) * 100).toFixed(0),
    },
    orderSum: {
      now: orderSumNow.toFixed(0),
      changeValue: Number(orderSumNow - orderSumBefore),
      changePercent: (((orderSumNow - orderSumBefore) / orderSumBefore) * 100).toFixed(0),
    },
    saleQuantity: {
      now: saleQuantityNow.toFixed(0),
      changeValue: Number(saleQuantityNow - saleQuantitBefore),
      changePercent: (((saleQuantityNow - saleQuantitBefore) / saleQuantitBefore) * 100).toFixed(0),
    },
    saleSum: {
      now: saleSumNow.toFixed(0),
      changeValue: Number(saleSumNow - saleSumBefore),
      changePercent: (((saleSumNow - saleSumBefore) / saleSumBefore) * 100).toFixed(0),
    },
  };
};

export default parseWidgetData;
