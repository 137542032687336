import { logisticsOzon } from './cataloguesOzon';


const formulaOzon = (priceCalculated, otherCosts, commissionPercent, {
  length, height, width, weight, selfCost,
}) => {
  const volumeWeight = Math.max(parseFloat(((length * height * width) / 5000).toFixed(1)), weight);

  const commissionsValue = commissionPercent * priceCalculated; // Комиссия МП
  const bankComission = 0.015 * priceCalculated; // Эквайринг
  const commissionsMarketing = 0.1 * priceCalculated; // Продвижение
  const commissionsStorage = 0; // Хранение

  const commissionsLogisticsPrepare = 0;// Обработка отправления

  const commissionsLogisticsLogistics = logisticsOzon.find((c) => volumeWeight >= c.min && volumeWeight <= c.max)?.value || 0; // Логистика

  const commissionsLogisticsLastMileCalculated = priceCalculated * 0.06;
  const commissionsLogisticsLastMile = Math.min(Math.max(58, commissionsLogisticsLastMileCalculated), 1106);// Последняя миля

  const commissionsLogistics = commissionsLogisticsPrepare + commissionsLogisticsLogistics + commissionsLogisticsLastMile; // Доставка

  const commissionsLogisticsBackDecline = 2.5; // Обработка отмены
  const commissionsLogisticsBackLogistics = commissionsLogisticsLogistics * 0.05; // Обратная логистика
  const commissionsLogisticsBack = commissionsLogisticsBackDecline + commissionsLogisticsBackLogistics; // Обратная доставка

  const commissionsTotal = commissionsMarketing
   + commissionsValue + commissionsLogistics + commissionsStorage + bankComission + commissionsLogisticsBack; // Общая комиссия
  const profit = priceCalculated - selfCost - otherCosts - commissionsTotal; // Прибыль
  const marginToSelfCostFBO = profit / (selfCost + otherCosts); // Наценка к себестоимости
  const margin = (priceCalculated / (selfCost + otherCosts + commissionsTotal) - 1) * 100;// Наценка

  return {
    margin: parseFloat(margin.toFixed(2)),
    commissionsValue: parseFloat(commissionsValue.toFixed(2)),
    commissionsLogistics: parseFloat(commissionsLogistics.toFixed(2)),
    commissionsStorage: parseFloat(commissionsStorage.toFixed(2)),
    bankComission: parseFloat(bankComission.toFixed(2)),
    commissionsLogisticsBack: parseFloat(commissionsLogisticsBack.toFixed(2)),
    commissionsTotal: parseFloat(commissionsTotal.toFixed(2)),
    profit: parseFloat(profit.toFixed(2)),
    marginToSelfCostFBO: parseFloat(marginToSelfCostFBO.toFixed(2)),
    commissionsMarketing: parseFloat(commissionsMarketing.toFixed(2)),
    priceCalculated,
    otherCosts,
  };
};

export default formulaOzon;

// selfCost - берем из Airtable
// размеры тянутся из Airtable
// priceCalculated - вводим на сайте
// otherCosts - вводим на сайте
