/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';


function OrderButton({
  orderDirection,
}) {
  return (
    <div
      style={{
        display: 'flex', flexDirection: 'column', cursor: 'pointer', marginLeft: '5px', fontSize: '10px',
      }}
    >
      <CaretUpOutlined
        style={{ color: orderDirection === 'asc' ? 'red' : '' }}
      />
      <CaretDownOutlined
        style={{ color: orderDirection === 'desc' ? 'red' : '' }}
      />
    </div>
  );
}

OrderButton.propTypes = {
  orderDirection: PropTypes.string,
};

OrderButton.defaultProps = {
  orderDirection: null,
};

export default OrderButton;
