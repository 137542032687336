/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import './SideBarButton.css';


const sideBarButton = ({
  icon, onClick, route, history,
}) => {
  function getIsActive() {
    return {
      // background: '#edf1f7',
      color: '#1890ff',
      borderLeft: '7px solid #1890ff',
      // borderRight: '7px solid #edf1f7',
    };
  }

  return (
    <Row
      align="middle"
      justify="center"
      gutter={8}
      className="sideBarButton-wrapper"
      onClick={() => onClick(route)}
      style={route === history.location.pathname && getIsActive(route === history.location.pathname)}
    >
      <Col className="sideBarButton-icon">{icon}</Col>
    </Row>
  );
};

sideBarButton.propTypes = {
  icon: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  route: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
};

export default sideBarButton;
