/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table, Avatar, Col, Row, Card, Button, Modal, Input, Checkbox,
} from 'antd';
import ozonLogo from '../../../assets/icons/ozon.jpeg';
import wbLogo from '../../../assets/icons/wb.jpeg';


const { Search } = Input;
function Linked({
  product, isLoading, onAddNewLinkModal, allProductsFiltered, onSearchProductForLink, isShowAddLinkModal,
  onLinkCheckBoxChange, onSaveAddNewLink,
}) {
  const columns = [
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
    // {
    //   title: '',
    //   dataIndex: 'mpLogos',
    //   key: 'mpLogos',
    //   render: () => (
    //     <Col span={24}>
    //       <Row wrap={false}>
    //         <Col><img src={ozonLogo} alt="логотип" style={{ width: '30px', height: '30px', marginRight: '10px' }} /></Col>
    //       </Row>
    //       <Row style={{ marginTop: '10px' }} wrap={false}>
    //         <Col><img src={wbLogo} alt="логотип" style={{ width: '30px', height: '30px', marginRight: '10px' }} /></Col>
    //       </Row>
    //     </Col>
    //   ),
    // },
    // {
    //   title: 'Заказано, шт',
    //   dataIndex: 'orders',
    //   key: 'orders',
    //   render: (_, object) => (
    //     <Col span={24}>
    //       <Row wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.ordersOzon}</Col>
    //       </Row>
    //       <Row style={{ marginTop: '10px' }} wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.ordersWB}</Col>
    //       </Row>
    //     </Col>
    //   ),
    // },
    // {
    //   title: 'Заказано, руб',
    //   dataIndex: 'ordersSum',
    //   key: 'ordersSum',
    //   render: (_, object) => (
    //     <Col span={24}>
    //       <Row wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.ordersSumOzon}</Col>
    //       </Row>
    //       <Row style={{ marginTop: '10px' }} wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.ordersSumWB}</Col>
    //       </Row>
    //     </Col>
    //   ),
    // },
    // {
    //   title: 'Продано, шт',
    //   dataIndex: 'sales',
    //   key: 'sales',
    //   render: (_, object) => (
    //     <Col span={24}>
    //       <Row wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.salesOzon}</Col>
    //       </Row>
    //       <Row style={{ marginTop: '10px' }} wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.salesWB}</Col>
    //       </Row>
    //     </Col>
    //   ),
    // },
    // {
    //   title: 'Продано, руб',
    //   dataIndex: 'ordersSum',
    //   key: 'ordersSum',
    //   render: (_, object) => (
    //     <Col span={24}>
    //       <Row wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.salesSumOzon}</Col>
    //       </Row>
    //       <Row style={{ marginTop: '10px' }} wrap={false}>
    //         <Col style={{ lineHeight: '30px' }}>{object.salesSumWB}</Col>
    //       </Row>
    //     </Col>
    //   ),
    // },
  ];

  const addNewLinkColumns = [
    {
      title: '',
      dataIndex: 'check',
      key: 'check',
      width: '3%',
      render: (_, object) => (
        <Checkbox
          onChange={(e) => onLinkCheckBoxChange(object.id, e.target.checked)}
          defaultChecked={product.linkedProducts.map((p) => p.id).includes(object.id)}
        />
      ),
    },
    {
      title: 'Артикул',
      dataIndex: 'inventoryNumber',
      key: 'inventoryNumber',
      width: '15%',
    },
    {
      title: '',
      dataIndex: 'imageURL',
      key: 'imageURL',
      width: '10%',
      render: (text) => <Avatar shape="square" src={text} size={50} />,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
    },
  ];
  return (
    <Card style={{
      borderRadius: '5px', width: '100%', padding: '0px 0px 20px', margin: '20px 0px',
    }}
    >
      <div style={{ fontSize: '24px', color: '#212121', marginLeft: '16px' }}>Связанные товары</div>
      <Table columns={columns} dataSource={product ? product.linkedProducts : []} rowKey="id" loading={isLoading} pagination={false} />
      <Row justify="end"><Button type="primary" style={{ marginTop: '20px' }} onClick={onAddNewLinkModal}>Изменить</Button></Row>
      {isShowAddLinkModal
      && (
      <Modal
        title="Связывание товаров"
        onCancel={onAddNewLinkModal}
        visible
        width="80vw"
        bodyStyle={{ padding: '20px' }}
        maskClosable
        footer={[
          <Button type="primary" onClick={onSaveAddNewLink}>Сохранить</Button>,
        ]}
      >
        <Search
          placeholder="название или артикул"
          onChange={onSearchProductForLink}
        />
        <Table
          columns={addNewLinkColumns}
          dataSource={allProductsFiltered}
          rowKey="id"
          loading={isLoading}
          pagination={false}
          scroll={{ y: '50vh' }}
        />
      </Modal>
      )}
    </Card>
  );
}

Linked.propTypes = {
  product: PropTypes.shape(),
  isLoading: PropTypes.bool.isRequired,
  allProductsFiltered: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAddNewLinkModal: PropTypes.func.isRequired,
  onSearchProductForLink: PropTypes.func.isRequired,
  isShowAddLinkModal: PropTypes.bool.isRequired,
  onLinkCheckBoxChange: PropTypes.func.isRequired,
  onSaveAddNewLink: PropTypes.func.isRequired,
};

Linked.defaultProps = {
  product: null,
};

export default Linked;

