/* eslint-disable max-len */
import React, { Component } from 'react';
import { Col, notification } from 'antd';
import moment from 'moment';
import Papa from 'papaparse';
import { config } from '../../utils/config';
import MailingPageDesktop from './Desktop/MailingPageDesktop';
import {
  getAllShopsOzon, getAllMailingListsOzon, editMailingListOzon,
} from '../../utils/http';


class MailingModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInitLoading: true,
      isMobile: window.innerWidth <= config.mobileWidth,
      allMailingLists: [],
      datasource: [],
      loading: false,
      selectedMailing: null,
      apiKeysCatalogue: [],
      postingList: null,
    };
    this.formRef = React.createRef();
    this.setFileParseResultValue = this.setFileParseResultValue.bind(this);
  }

  componentDidMount() {
    Promise.all([getAllShopsOzon(), getAllMailingListsOzon()])
      .then((result) => this.setState({
        isInitLoading: false,
        allMailingLists: result[1],
        datasource: result[1],
        apiKeysCatalogue: result[0],
      }));
  }


  onChageSearch = (e) => {
    const { value } = e.target;
    if (value === '') {
      this.setState((prevState) => ({
        datasource: prevState.allMailingLists,
      }));
    } else {
      this.setState((prevState) => ({
        datasource: prevState.allMailingLists
          .filter((element) => element.name.toLowerCase().indexOf(value.toLowerCase()) >= 0),
      }));
    }
  }

  onShowSelectedMailing = (selectedMailing) => {
    this.setState({ selectedMailing });
  }

  onSendMailing = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const { postingList } = this.state;
        if (!postingList) {
          notification.error({ description: 'Загрузите csv' });
          return;
        }
        editMailingListOzon({
          name: values.name,
          date: moment(),
          text: values.text,
          shopOzon_id: values.apiKey,
          orderOzon_ids: postingList.join(','),
        })
          .then((result) => {
            const newObj = {
              id: result.result[0].id,
              name: values.name,
              date: moment(),
              text: values.text,
              apiKey: values.apiKey,
              postings: postingList,
            };
            this.setState((prevState) => ({
              allMailingLists: [...prevState.allMailingLists, newObj],
              datasource: [...prevState.datasource, newObj],
              selectedMailing: null,
              postingList: null,
            }));
          });
      });
  }

  setFileParseResultValue(result) {
    const { data } = result;
    this.setState({ postingList: data.map((d) => d[0]) });
  }

  onFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: this.setFileParseResultValue,
      });
    }
    this.setState({ postingList: null });
  };


  render() {
    const {
      isMobile, isInitLoading, datasource, loading, selectedMailing, apiKeysCatalogue,
    } = this.state;
    if (isMobile) {
      return (
        <div />
      );
    }
    return (
      <Col span={23}>
        <MailingPageDesktop
          isInitLoading={isInitLoading}
          datasource={datasource}
          loading={loading}
          onChageSearch={this.onChageSearch}
          onShowSelectedMailing={this.onShowSelectedMailing}
          selectedMailing={selectedMailing}
          onSendMailing={this.onSendMailing}
          formRef={this.formRef}
          apiKeysCatalogue={apiKeysCatalogue}
          onFileUpload={this.onFileUpload}
        />
      </Col>
    );
  }
}

export default MailingModule;
