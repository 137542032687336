import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card, Statistic,
} from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';


function Statistics({
  isLoading, orderQuantity, saleSum, saleQuantity, orderSum,
}) {
  return (
    <Row gutter={10} style={{ margin: '20px 0px' }}>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic title="Заказы, шт" value={orderQuantity.now} valueStyle={{ fontSize: '16px' }} loading={isLoading} />
          <Statistic
            loading={isLoading}
            value={orderQuantity.changeValue}
            precision={0}
            valueStyle={{ color: orderQuantity.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
            prefix={orderQuantity.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            suffix={`(${orderQuantity.changePercent || 0}%)`}
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic title="Заказы, руб" value={orderSum.now} valueStyle={{ fontSize: '16px' }} loading={isLoading} />
          <Statistic
            loading={isLoading}
            value={orderSum.changeValue}
            precision={0}
            valueStyle={{ color: orderSum.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
            prefix={orderSum.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            suffix={`(${orderSum.changePercent || 0}%)`}
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic title="Продажи, шт" value={saleQuantity.now} valueStyle={{ fontSize: '16px' }} loading={isLoading} />
          <Statistic
            loading={isLoading}
            value={saleQuantity.changeValue}
            precision={0}
            valueStyle={{ color: saleQuantity.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
            prefix={saleQuantity.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            suffix={`(${saleQuantity.changePercent || 0}%)`}
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic title="Продажи, руб" value={saleSum.now} valueStyle={{ fontSize: '16px' }} loading={isLoading} />
          <Statistic
            loading={isLoading}
            value={saleSum.changeValue}
            precision={0}
            valueStyle={{ color: saleSum.changeValue >= 0 ? '#3f8600' : '#cf1322', fontSize: '12px' }}
            prefix={saleSum.changeValue >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            suffix={`(${saleSum.changePercent || 0}%)`}
          />
        </Card>
      </Col>
      {/* <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic
            loading={isLoading}
            title="Прибыль"
            value={0}
            precision={0}
            valueStyle={{ color: '#3f8600' }}
            suffix="₽"
          />
        </Card>
      </Col>
      <Col span={4}>
        <Card style={{ borderRadius: '5px' }}>
          <Statistic
            loading={isLoading}
            title="Возвраты"
            value={0}
            precision={0}
          />
        </Card>
      </Col> */}
    </Row>
  );
}

Statistics.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  orderQuantity: PropTypes.shape().isRequired,
  saleSum: PropTypes.shape().isRequired,
  saleQuantity: PropTypes.shape().isRequired,
  orderSum: PropTypes.shape().isRequired,
};


export default Statistics;

