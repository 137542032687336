import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, Row, Card,
} from 'antd';
import Table from '../Components/Table';
import Filters from '../Components/Filters';
import './PriceCalculatorPageDesktop.css';


function PriceCalculatorPageDesktop({
  isLoading, datasource, onChageSearch, shops, selectedShops, onChangeShop, onRecalculateObject, onSaveProductCalculations,
  onExportXLS, onRedirectProduct, shopsMarketplace, selectedShopMarketplace, onChangeBrand,
}) {
  return (
    <Col className="priceCalculatorPageDesktop-wrapper">
      <Row className="priceCalculatorPageDesktop-title">Калькулятор цен</Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Filters
            onChageSearch={onChageSearch}
            isLoading={isLoading}
            shops={shops}
            selectedShopArray={selectedShops}
            onChangeShop={onChangeShop}
            onExportXLS={onExportXLS}
            onSaveProductCalculations={onSaveProductCalculations}
            shopsMarketplace={shopsMarketplace}
            selectedShopMarketplace={selectedShopMarketplace}
            onChangeBrand={onChangeBrand}
            // onReplaceWithCurrentPrices={onReplaceWithCurrentPrices}
            // onUploadXls={onUploadXls}
          />
        </Card>
      </Row>
      <Row style={{ margin: '20px 0px' }}>
        <Card style={{ borderRadius: '5px', width: '100%' }}>
          <Table
            datasource={datasource}
            isLoading={isLoading}
            onRecalculateObject={onRecalculateObject}
            onRedirectProduct={onRedirectProduct}
          />
        </Card>
      </Row>
    </Col>
  );
}

PriceCalculatorPageDesktop.propTypes = {
  datasource: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onChageSearch: PropTypes.func.isRequired,
  shops: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  selectedShops: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeShop: PropTypes.func.isRequired,
  onRecalculateObject: PropTypes.func.isRequired,
  onSaveProductCalculations: PropTypes.func.isRequired,
  onExportXLS: PropTypes.func.isRequired,
  onRedirectProduct: PropTypes.func.isRequired,
  shopsMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedShopMarketplace: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeBrand: PropTypes.func.isRequired,
};

export default PriceCalculatorPageDesktop;
