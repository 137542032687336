const categories = [
  { name: 'Красота и здоровье', value: 0.1 },
  { name: 'Косметическая техника', value: 0.1 },
  { name: 'Профессиональные аппараты для косметологии', value: 0.09 },
  { name: 'Одежда, обувь и аксессуары', value: 0.12 },
  { name: 'Ювелирные украшения', value: 0.09 },
  { name: 'Аксессуары для электроники', value: 0.15 },
  { name: 'Аудиотехника и аксессуары к аудио- и видеотехнике', value: 0.07 },
  { name: 'Карты памяти, флешки, диски', value: 0.07 },
  { name: 'Комплектующие для ПК и ноутбуков', value: 0.05 },
  { name: 'Компьютеризированное оборудование, игровые приставки, фотоаппараты', value: 0.06 },
  { name: 'Периферийные устройства для компьютеров, ПО, расходные материалы', value: 0.07 },
  { name: 'Мониторы', value: 0.06 },
  { name: 'Стационарные ПК', value: 0.04 },
  { name: 'Ноутбуки', value: 0.03 },
  { name: 'Смартфоны Apple', value: 0.03 },
  { name: 'Смартфоны и планшеты', value: 0.04 },
  { name: 'Телевизоры', value: 0.06 },
  { name: 'Крупная встраиваемая бытовая техника', value: 0.07 },
  { name: 'Невстраиваемая крупная бытовая техника', value: 0.05 },
  { name: 'Малая бытовая техника', value: 0.07 },
  { name: 'Профессиональное медицинское оборудование', value: 0.04 },
  { name: 'Цифровые товары', value: 0.1 },
  { name: 'Дом и сад', value: 0.12 },
  { name: 'Строительство и ремонт', value: 0.1 },
  { name: 'Мебель', value: 0.09 },
  { name: 'Автомобили и мототехника', value: 0.04 },
  { name: 'Автотовары', value: 0.1 },
  { name: 'Шины', value: 0.06 },
  { name: 'Товары для спорта и отдыха', value: 0.11 },
  { name: 'Велосипеды, лодки', value: 0.08 },
  { name: 'Кардиотренажеры и спортивные часы', value: 0.08 },
  { name: 'Книги', value: 0.15 },
  { name: 'Аптека', value: 0.11 },
  { name: 'Витамины и БАДы', value: 0.1 },
  { name: 'Ортопедия', value: 0.1 },
  { name: 'Парафармацевтика', value: 0.09 },
  { name: 'Профессиональный уход за полостью рта', value: 0.09 },
  { name: 'Спортивное питание', value: 0.09 },
  { name: 'Контактные линзы', value: 0.06 },
  { name: 'Средства для реабилитации', value: 0.06 },
  { name: 'Товары для взрослых', value: 0.15 },
  { name: 'Электронные сигареты и системы нагревания', value: 0.15 },
  { name: 'Бытовая химия', value: 0.05 },
  { name: 'Личная гигиена', value: 0.06 },
  { name: 'Бритвы и лезвия', value: 0.05 },
  { name: 'Продукты питания', value: 0.05 },
  { name: 'Свежие продукты питания', value: 0.12 },
  { name: 'Детская электроника, мебель, аксессуары', value: 0.08 },
  { name: 'Коляски и автокресла', value: 0.08 },
  { name: 'Детская посуда', value: 0.1 },
  { name: 'Гигиена детская', value: 0.08 },
  { name: 'Текстиль детский', value: 0.1 },
  { name: 'Спорт детский', value: 0.11 },
  { name: 'Игрушки', value: 0.1 },
  { name: 'Хобби, творчество и канцелярия', value: 0.09 },
  { name: 'Зоокорма и товары для фермерского хозяйства', value: 0.05 },
  { name: 'Гигиена и уход для животных', value: 0.05 },
  { name: 'Одежда для животных, товары для транспортировки и выгула', value: 0.09 },
  { name: 'Организация пространства для животного', value: 0.09 },
  { name: 'Благотворительность', value: 0.01 },
  { name: 'Подарочные сертификаты продавцов', value: 0.05 },
];

export default categories;
