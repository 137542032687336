/* eslint-disable no-loop-func */
import moment from 'moment';
import { orderBy } from 'lodash';


const getChartDatasource = (filteredOrders, filteredTransactions, periodFrom, periodTo) => {
  const resultBars = [];
  const resultLineOrder = [];
  const resultLineSale = [];
  const startDate = moment(periodFrom);
  while (periodTo.isAfter(startDate)) {
    resultBars.push({
      period: moment(startDate).format('DD.MM.YY'),
      name: 'Продаж, руб',
      count: Number((filteredTransactions.filter((o) => o.transactionDate.isSame(moment(startDate).startOf('day'))).reduce((p, c) => p + c.amount, 0)).toFixed(0)),
    });
    resultBars.push({
      period: moment(startDate).format('DD.MM.YY'),
      name: 'Заказов, руб',
      count: Number(filteredOrders.filter((o) => o.createdAt.isSame(startDate, 'day')).reduce((p, c) => p + c.clientOrderSum, 0).toFixed(0)),
    });
    resultLineOrder.push({
      period: moment(startDate).format('DD.MM.YY'),
      orderCount: Number(filteredOrders.filter((o) => o.createdAt.isSame(startDate, 'day')).reduce((p, c) => p + c.quantity, 0).toFixed(0)),
    });
    resultLineSale.push({
      period: moment(startDate).format('DD.MM.YY'),
      saleCount: Number((filteredTransactions.filter((o) => o.transactionDate.isSame(startDate, 'day')).reduce((p, c) => p + c.quantity, 0)).toFixed(0)),
    });
    startDate.add(1, 'day');
  }
  return {
    resultBars: orderBy(resultBars, 'name', 'asc'),
    resultLineOrder,
    resultLineSale,
  };
};

export default getChartDatasource;
