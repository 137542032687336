/* eslint-disable max-len */
import React, { Component } from 'react';
import {
  Col, Row, Form, Input, Select, notification, InputNumber, Steps, Button,
} from 'antd';
import categories from '../utils/categoryCalculation';
import logisticsCoefficientsFBO from '../utils/logisticsCoefficientsFBO';
import logisticsCoefficientsFBS from '../utils/logisticsCoefficientsFBS';
import fbsWarehouse from '../utils/fbsWarehouse';
import fbsCourierTarifs from '../utils/fbsCourierTarifs';


const { Option } = Select;
const { Step } = Steps;
class CalculationPageDesktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      calculation: {
        width: 23.5,
        height: 13.3,
        length: 13,
        weight: 1.2,
        costSelf: 3000,
        costBeforeMP: 100,
        costFinal: 5000,
        buyOutPercent: 95,
        // numbersSold: 1,
        fbsCourierAmount: 100,
      },
    };
    this.formRef = React.createRef();
  }

  onChangeTransportSceme = (transportSceme) => {
    this.setState((prevState) => ({
      calculation: { ...prevState.calculation, transportSceme },
    }));
  }

  onNextClick = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        const newValues = {};

        newValues.volume = values.length * values.height * values.width;
        newValues.volumeWeight = Math.max(newValues.volume / 5000, values.weight);
        newValues.marginPercents = (values.costFinal - values.costSelf - values.costBeforeMP) / (values.costSelf + values.costBeforeMP);
        newValues.marginValue = values.costFinal - values.costSelf - values.costBeforeMP;
        newValues.comissionPercent = categories.find((c) => c.name === values.categoryCalculation).value;
        newValues.comissionValue = values.costFinal * newValues.comissionPercent;
        newValues.bankComission = values.costFinal * 0.02;

        // FBO
        newValues.packageTypeFBO = 'Не подходит';
        if (values.length <= 120 && values.height <= 70 && values.width <= 80 && values.weight <= 25) {
          newValues.packageTypeFBO = 'Стандарт';
        }
        if (values.length > 120 && values.length <= 180 && values.height <= 60 && values.width < 60 && values.weight <= 25) {
          newValues.packageTypeFBO = 'Легкий крупногабарит';
        }
        if (values.length > 120 && values.length <= 200 && values.height <= 30 && values.width <= 30 && values.weight <= 5) {
          newValues.packageTypeFBO = 'Легкий длинномер';
        }
        if (values.length > 220 || values.height > 70 || values.width > 80 || values.weight > 80) {
          newValues.packageTypeFBO = 'Не подходит';
        }
        if ((values.height <= 70) && (values.length > 120 && values.length <= 220) && (values.width <= 80) && (values.weight <= 80)) {
          newValues.kgtFBO = true;
        }
        const logisticsCoefficientFBO = logisticsCoefficientsFBO.find((c) => newValues.volumeWeight >= c.minWeight && newValues.volumeWeight <= c.maxWeight);
        newValues.logisticsPercentFBO = logisticsCoefficientFBO.percent;
        newValues.logisticsValueFBO = values.costFinal * newValues.logisticsPercentFBO;
        newValues.logisticsValueFBO = Math.min(Math.max(newValues.logisticsValueFBO, logisticsCoefficientFBO.minValue), logisticsCoefficientFBO.maxValue);
        newValues.lastMilePercentFBO = 0.05;
        newValues.lastMileValueFBO = newValues.lastMilePercentFBO * values.costFinal;
        newValues.lastMileValueFBO = Math.min(Math.max(newValues.lastMileValueFBO, 20), 250);
        newValues.returnFBO = 50 * ((100 - values.buyOutPercent) / 100);
        newValues.returnLogisticsValueFBO = newValues.logisticsValueFBO * ((100 - values.buyOutPercent) / 100);

        newValues.costAfterMPFBO = newValues.comissionValue + newValues.bankComission + newValues.logisticsValueFBO + newValues.lastMileValueFBO + newValues.returnFBO + newValues.returnLogisticsValueFBO;
        newValues.profitFBO = values.costFinal - values.costSelf - values.costBeforeMP - newValues.costAfterMPFBO;
        newValues.marginToSelfCostFBO = (newValues.profitFBO / (values.costSelf + values.costBeforeMP)) * 100;
        newValues.marginFBO = (newValues.profitFBO / values.costFinal) * 100;

        // FBS
        newValues.packageTypeFBS = 'Не подходит';
        if (values.length <= 180 && values.height <= 80 && values.width <= 80 && values.weight <= 25) {
          newValues.packageTypeFBS = 'Стандарт';
        }
        if (values.length > 250 || values.height > 220 || values.width > 120 || values.weight > 125) {
          newValues.packageTypeFBS = 'Не подходит';
        }
        if ((values.height > 80 && values.height <= 220) || (values.length > 120 && values.length <= 250) || (values.width > 80 && values.width <= 120) || (values.weight > 25 && values.weight <= 125)) {
          newValues.kgtFBS = true;
        }
        const logisticsCoefficientFBS = logisticsCoefficientsFBS.find((c) => newValues.volumeWeight >= c.minWeight && newValues.volumeWeight <= c.maxWeight);
        newValues.logisticsPercentFBS = logisticsCoefficientFBS.percent;
        newValues.logisticsValueFBS = values.costFinal * newValues.logisticsPercentFBS;
        newValues.logisticsValueFBS = Math.min(Math.max(newValues.logisticsValueFBS, logisticsCoefficientFBS.minValue), logisticsCoefficientFBS.maxValue);
        newValues.lastMilePercentFBS = 0.05;
        newValues.lastMileValueFBS = newValues.lastMilePercentFBS * values.costFinal;
        newValues.lastMileValueFBS = Math.min(Math.max(newValues.lastMileValueFBO, 20), 250);
        newValues.logisticsCourierCall = fbsCourierTarifs.find((t) => values.fbsCourierAmount >= t.minAmount && values.fbsCourierAmount <= t.maxAmount).value / values.fbsCourierAmount;
        if (newValues.packageTypeFBS === 'Стандарт') {
          if (values.fbsCourierAmount >= 1 && values.fbsCourierAmount <= 10) {
            newValues.logisticsCourierPackage = 0;
          } else {
            newValues.logisticsCourierPackage = 25;
          }
        }
        if (newValues.kgtFBS) {
          newValues.logisticsCourierPackage = 11 * newValues.volumeWeight;
          newValues.lastMileValueFBS = 0;
        }
        if (newValues.packageTypeFBS === 'Не подходит') {
          newValues.logisticsCourierPackage = 'Не подходит';
        }
        newValues.logisticsCourierTotal = newValues.logisticsCourierPackage !== 'Не подходит' ? newValues.logisticsCourierPackage + newValues.logisticsCourierCall : 0;
        newValues.packagingSendValueFBS = fbsWarehouse.find((f) => f.name === values.fbsWarehouse).value * values.fbsCourierAmount;
        newValues.returnFBS = 40 * ((100 - values.buyOutPercent) / 100);
        newValues.returnLogisticsValueFBS = newValues.logisticsValueFBS * ((100 - values.buyOutPercent) / 100);

        newValues.costAfterMPFBSCourier = newValues.comissionValue + newValues.bankComission + newValues.logisticsCourierTotal + newValues.logisticsValueFBS + newValues.lastMileValueFBS + newValues.returnFBS + newValues.returnLogisticsValueFBS;
        newValues.profitFBSCourier = values.costFinal - values.costSelf - values.costBeforeMP - newValues.costAfterMPFBSCourier;
        newValues.marginToSelfCostFBSCourier = (newValues.profitFBSCourier / (values.costSelf + values.costBeforeMP)) * 100;
        newValues.marginFBSCourier = (newValues.profitFBSCourier / values.costFinal) * 100;

        newValues.costAfterMPFBSPVZ = newValues.comissionValue + newValues.bankComission + newValues.packagingSendValueFBS + newValues.logisticsValueFBS + newValues.lastMileValueFBS + newValues.returnFBS + newValues.returnLogisticsValueFBS;
        newValues.profitFBSPVZ = values.costFinal - values.costSelf - values.costBeforeMP - newValues.costAfterMPFBSPVZ;
        newValues.marginToSelfCostFBSPVZ = (newValues.profitFBSPVZ / (values.costSelf + values.costBeforeMP)) * 100;
        newValues.marginFBSPVZ = (newValues.profitFBSPVZ / values.costFinal) * 100;


        this.setState((prevState) => ({
          step: prevState.step + 1,
          calculation: { ...prevState.calculation, ...values, ...newValues },
        }));
      })
      .catch((error) => {
        notification.error({ message: `Не сохранено. Заполните обязательные поля ${error}` });
      });
  }

  render() {
    const { step, calculation } = this.state;
    return (
      <Col className="priceCalculatorPageDesktop-wrapper" span={24}>
        <Row className="priceCalculatorPageDesktop-title">Расчет</Row>
        <Row style={{ marginBottom: '40px', width: '30%' }}>
          <Steps size="small" current={step}>
            <Step title="Общая информация" />
            <Step title="Расчет" />
          </Steps>
        </Row>
        <Row style={{ width: '100%' }}>
          {step === 0 && (
          <Form ref={this.formRef} layout="vertical" style={{ width: '100%' }}>
            <Row style={{ width: '100%' }} gutter={100}>
              <Col span={12}>
                <Form.Item
                  name="length"
                  initialValue={calculation.length}
                  label="Длина, см:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="height"
                  initialValue={calculation.height}
                  label="Высота, см:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="width"
                  initialValue={calculation.width}
                  label="Ширина, см:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="weight"
                  initialValue={calculation.weight}
                  label="Вес товара, кг:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="categoryCalculation"
                  label="Категория для расчета комиссии:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.categoryCalculation}
                >
                  <Select
                    style={{ width: '100%' }}
                  >
                    {categories.map((u) => <Option key={u.name} value={u.name}>{u.name}</Option>)}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="costSelf"
                  label="Себестоимость, руб:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.costSelf}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="costBeforeMP"
                  label="Расходы до МП, руб:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.costBeforeMP}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="costFinal"
                  label="Финальная цена, руб:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.costFinal}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                {/* <Form.Item
                  name="numbersSold"
                  label="Продажи в месяц, шт:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.numbersSold}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item> */}
                <Form.Item
                  name="fbsWarehouse"
                  label="Точка для FBS:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.fbsWarehouse}
                >
                  <Select
                    style={{ width: '100%' }}
                  >
                    {fbsWarehouse.map((u) => <Option key={u.name} value={u.name}>{u.name}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="fbsCourierAmount"
                  label="Кол-во отправлений курьером:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.fbsCourierAmount}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  name="buyOutPercent"
                  label="Процент выкупа, %:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.buyOutPercent}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          )}

          {step === 1 && (
          <Form ref={this.formRef} layout="vertical" style={{ width: '100%' }}>
            <Row style={{ width: '100%' }} gutter={100}>
              <Col span={6}>
                <Row style={{ height: '80px', fontSize: '18px', color: '#212121' }}>Общая инфа по продукту, которая не зависит от схемы</Row>
                <Form.Item
                  name="volume"
                  label="Объем товара:"
                  initialValue={calculation.volume}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="volumeWeight"
                  label="Объемный вес:"
                  initialValue={calculation.volumeWeight}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginValue"
                  label="Наценка:"
                  initialValue={calculation.marginValue}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginPercents"
                  label="Наценка, %:"
                  initialValue={calculation.marginPercents}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="comissionPercent"
                  label="Комиссия, %:"
                  initialValue={calculation.comissionPercent}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="comissionValue"
                  label="Комиссия, руб:"
                  initialValue={calculation.comissionValue}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="bankComission"
                  label="Эквайринг, руб:"
                  initialValue={calculation.bankComission}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="length"
                  initialValue={calculation.length}
                  label="Длина, см:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="height"
                  initialValue={calculation.height}
                  label="Высота, см:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="width"
                  initialValue={calculation.width}
                  label="Ширина, см:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="weight"
                  initialValue={calculation.weight}
                  label="Вес товара, кг:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="categoryCalculation"
                  label="Категория для расчета комиссии:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.categoryCalculation}
                >
                  <Select
                    style={{ width: '100%' }}
                    disabled
                  >
                    {categories.map((u) => <Option key={u.name} value={u.name}>{u.name}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="costSelf"
                  label="Себестоимость, руб:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.costSelf}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="costBeforeMP"
                  label="Расходы до МП, руб:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.costBeforeMP}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="costFinal"
                  label="Финальная цена, руб:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.costFinal}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="numbersSold"
                  label="Продажи в месяц, шт:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.numbersSold}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="fbsWarehouse"
                  label="Точка для FBS:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.fbsWarehouse}
                >
                  <Select
                    style={{ width: '100%' }}
                    disabled
                  >
                    {fbsWarehouse.map((u) => <Option key={u.name} value={u.name}>{u.name}</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="fbsCourierAmount"
                  label="Кол-во отправлений курьером:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.fbsCourierAmount}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
                <Form.Item
                  name="buyOutPercent"
                  label="Процент выкупа, %:"
                  rules={[{ required: true, message: 'Поле обязательно для заполнения' }]}
                  initialValue={calculation.buyOutPercent}
                >
                  <InputNumber style={{ width: '100%' }} disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Row style={{ height: '80px', fontSize: '18px', color: '#212121' }}>Схема FBO</Row>
                <Form.Item
                  name="calculation.packageTypeFBO"
                  label="Тип габаритов:"
                  initialValue={calculation.packageTypeFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="kgtFBO"
                  label="КГТ:"
                  initialValue={calculation.kgtFBO ? 'Да' : 'Нет'}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsPercentFBO"
                  label="Коэффициент логистики FBO:"
                  initialValue={calculation.logisticsPercentFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsValueFBO"
                  label="Стоимость логистики FBO:"
                  initialValue={calculation.logisticsValueFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="sent"
                  label="Обработка отправления:"
                  initialValue={0}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="lastMileValueFBO"
                  label="Последняя миля:"
                  initialValue={calculation.lastMileValueFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="returnFBO"
                  label="Обработка отмены:"
                  initialValue={calculation.returnFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="returnLogisticsValueFBO"
                  label="Обратная логистика:"
                  initialValue={calculation.returnLogisticsValueFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="costAfterMPFBO"
                  label="Расходы на МП:"
                  initialValue={calculation.costAfterMPFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="profitFBO"
                  label="Прибыль:"
                  initialValue={calculation.profitFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginToSelfCostFBO"
                  label="Наценка к себестоимости:"
                  initialValue={calculation.marginToSelfCostFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginFBO"
                  label="Маржинальность:"
                  initialValue={calculation.marginFBO}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Row style={{ height: '80px', fontSize: '18px', color: '#212121' }}>Схема FBS (курьер)</Row>
                <Form.Item
                  name="packageTypeFBS"
                  label="Тип габаритов:"
                  initialValue={calculation.packageTypeFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="kgtFBS"
                  label="КГТ:"
                  initialValue={calculation.kgtFBS ? 'Да' : 'Нет'}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsPercentFBS"
                  label="Коэффициент логистики FBS:"
                  initialValue={calculation.logisticsPercentFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsValueFBS"
                  label="Стоимость логисики FBS:"
                  initialValue={calculation.logisticsValueFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsCourierTotal"
                  label="Обработка отправления:"
                  initialValue={calculation.logisticsCourierTotal}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="lastMileValueFBS"
                  label="Последняя миля:"
                  initialValue={calculation.lastMileValueFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="returnFBS"
                  label="Обработка отмены:"
                  initialValue={calculation.returnFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="returnLogisticsValueFBS"
                  label="Обратная логистика:"
                  initialValue={calculation.returnLogisticsValueFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="costAfterMPFBSCourier"
                  label="Расходы на МП:"
                  initialValue={calculation.costAfterMPFBSCourier}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="profitFBSCourier"
                  label="Прибыль:"
                  initialValue={calculation.profitFBSCourier}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginToSelfCostFBSCourier"
                  label="Наценка к себестоимости:"
                  initialValue={calculation.marginToSelfCostFBSCourier}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginFBSCourier"
                  label="Маржинальность:"
                  initialValue={calculation.marginFBSCourier}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                {/* <Form.Item
                  name="logisticsCourierCall"
                  label="Вызов курьера:"
                  initialValue={calculation.logisticsCourierCall}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsCourierPackage"
                  label="За отправление:"
                  initialValue={calculation.logisticsCourierPackage}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item> */}
              </Col>
              <Col span={6}>
                <Row style={{ height: '80px', fontSize: '18px', color: '#212121' }}>Схема FBS (ПВЗ)</Row>
                <Form.Item
                  name="packageTypeFBS"
                  label="Тип габаритов:"
                  initialValue={calculation.packageTypeFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="kgtFBS"
                  label="КГТ:"
                  initialValue={calculation.kgtFBS ? 'Да' : 'Нет'}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsPercentFBS"
                  label="Коэффициент логистики FBS:"
                  initialValue={calculation.logisticsPercentFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="logisticsValueFBS"
                  label="Стоимость логисики FBS:"
                  initialValue={calculation.logisticsValueFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="packagingSendValueFBS"
                  label="Обработка отправления:"
                  initialValue={calculation.packagingSendValueFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="lastMileValueFBS"
                  label="Последняя миля:"
                  initialValue={calculation.lastMileValueFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="returnFBS"
                  label="Обработка отмены:"
                  initialValue={calculation.returnFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="returnLogisticsValueFBS"
                  label="Обратная логистика:"
                  initialValue={calculation.returnLogisticsValueFBS}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="costAfterMPFBSPVZ"
                  label="Расходы на МП:"
                  initialValue={calculation.costAfterMPFBSPVZ}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="profitFBSPVZ"
                  label="Прибыль:"
                  initialValue={calculation.profitFBSPVZ}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginToSelfCostFBSPVZ"
                  label="Наценка к себестоимости:"
                  initialValue={calculation.marginToSelfCostFBSPVZ}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
                <Form.Item
                  name="marginFBSPVZ"
                  label="Маржинальность:"
                  initialValue={calculation.marginFBSPVZ}
                >
                  <Input style={{ width: '100%', backgroundColor: '#fff' }} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          )}

          <Row gutter={10} style={{ marginTop: '40px' }}>
            <Col><Button onClick={() => this.setState((prevState) => ({ step: prevState.step - 1 }))}>Назад</Button></Col>
            <Col><Button type="primary" onClick={this.onNextClick}>Далее</Button></Col>
          </Row>
        </Row>
      </Col>
    );
  }
}


export default CalculationPageDesktop;
