/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import md5 from 'md5';
import { get, groupBy } from 'lodash';
import { Col, notification } from 'antd';
import { withRouter } from 'react-router';
import ProductPageDesktop from './Desktop/ProductPageDesktop';
import {
  getAllProductsCatalogueFull, getAllOrdersOzon, getAllTransactionsOzon, getAllOrdersWB, getAllTransactionsWB,
  editProductCatalogue, getProductOzonHistory, getProductWBHistory, getProductCatalogueHistory, getAllShopsWB,
  getAllProductsOzon, getAllProductsWB, getAllShopsOzon, getProductComment, editProductComment,
} from '../../utils/http';
import parseWidgetData from './utils/parseWidgetData';
import getChartDatasource from './utils/getChartDatasource';
import parseData from './utils/parseData';
import prepareHistoryData from './utils/prepareHistoryData';
import getUserId from '../../utils/localeStorage/getUserId';
import CommentModal from './Components/CommentModal';


function ProductModule({ history }) {
  const [isLoading, setIsloading] = useState(true);
  const [periodFromMonth] = useState(moment(moment().subtract(30, 'day').startOf('day').format('YYYY-MM-DD')));
  const [periodFrom, setPeriodFrom] = useState(moment(moment().subtract(7, 'day').startOf('day').format('YYYY-MM-DD')));
  const [periodTo, setPeriodTo] = useState(moment(moment().startOf('day').format('YYYY-MM-DD')));
  const [selectedMarketplaces, setSelectedMarketplaces] = useState(['Ozon', 'Wildberries']);
  const [shopsMarketplace, setShopsMarketplace] = useState([]);
  const [selectedShopMarketplace, setSelectedShopMarketplace] = useState([]);
  const [product, setProduct] = useState({ linkedProductsPeriod: [], linkedProductsMonth: [] });
  const [widgetData, setWidgetData] = useState({});
  const [isShowAddLinkModal, setIsShowAddLinkModal] = useState(false);
  const [allProductsFiltered, setAllProductsFiltered] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [productHistoryStocks, setProductHistoryStocks] = useState([]);
  const [productHistoryPrice, setProductHistoryPrice] = useState([]);
  const [linkCheckBoxChangeArray, setLinkCheckBoxChangeArray] = useState([]);
  const [productComments, setProductComments] = useState([]);
  const [productCommentInEdit, setProductCommentInEdit] = useState(null);
  const productCommentRef = useRef();
  const [orders, setOrders] = useState({
    ordersOzonNew: [],
    ordersOzonOld: [],
    ordersOzonMonth: [],
    ordersWbNew: [],
    ordersWbOld: [],
    ordersWbMonth: [],
  });
  const [transactions, setTransactions] = useState({
    transactionsOzonNew: [],
    transactionsOzonOld: [],
    transactionsWbNew: [],
    transactionsWbOld: [],
  });
  const [productsCatalogue, setProductsCatalogue] = useState({
    productsCatalogueOzon: [],
    productsCatalogueWb: [],
  });
  const [productHistory, setProductHistory] = useState({
    historyOzon: [],
    historyWb: [],
    historyAirtable: [],
  });
  const [chartDatasource, setChartDatasource] = useState({
    resultBars: [],
    resultLineOrder: [],
    resultLineSale: [],
  });

  const parseGetProductWBHistoryResult = (data) => {
    const result = [];
    const groupedByShopName = groupBy(data, 'shopName');
    const shopNameKeys = Object.keys(groupedByShopName);
    shopNameKeys.forEach((shopName) => {
      const shopNamePack = groupedByShopName[shopName];
      const groupedByDate = groupBy(shopNamePack, 'date');
      const dateKeys = Object.keys(groupedByDate);
      dateKeys.forEach((date) => {
        const datePack = groupedByDate[date];
        const stocksPresent = datePack.reduce((acc, obj) => acc + obj.stocksPresent, 0);
        result.push({
          stocksPresent,
          shop: 'WB',
          date,
          shopName,
          price: datePack[0].price,
        });
      });
    });
    return result;
  };

  const prepareStateData = (innerProduct, innerOrders, innerTransactions, innerProductsCatalogue, innerHistory, innperSelectedMarketplaces,
    inerSelectedShopMarketplace, innerPeriodFrom, innerPeriodTo, innerPeriodFromMonth) => {
    const allOrdersNewFiltered = [...innerOrders.ordersOzonNew, ...innerOrders.ordersWbNew].filter(((o) => innperSelectedMarketplaces.includes(o.shop)));
    const allOrdersOldFiltered = [...innerOrders.ordersOzonOld, ...innerOrders.ordersWbOld].filter(((o) => innperSelectedMarketplaces.includes(o.shop)));
    const allTransactionsNewFiltered = [...innerTransactions.transactionsOzonNew, ...innerTransactions.transactionsWbNew].filter(((o) => innperSelectedMarketplaces.includes(o.shop)));
    const allTransactionsOldFiltered = [...innerTransactions.transactionsOzonOld, ...innerTransactions.transactionsWbOld].filter(((o) => innperSelectedMarketplaces.includes(o.shop)));

    const parsedWBHistory = parseGetProductWBHistoryResult(innerHistory.historyWb);

    const newWidgetData = parseWidgetData(allOrdersNewFiltered, allOrdersOldFiltered, allTransactionsNewFiltered, allTransactionsOldFiltered);
    const newChartDatasource = getChartDatasource(allOrdersNewFiltered, allTransactionsNewFiltered, innerPeriodFrom, innerPeriodTo);
    const updatedProduct = parseData(
      innerProduct,
      innerProductsCatalogue.productsCatalogueWb,
      innerProductsCatalogue.productsCatalogueOzon,
      innerOrders.ordersOzonNew,
      innerOrders.ordersWbNew,
      innerOrders.ordersOzonMonth,
      innerOrders.ordersWbMonth,
      innerPeriodFrom,
      innerPeriodTo,
      innerPeriodFromMonth,
    );
    const { updatedProductHistoryPrice, updatedProductHistoryStocks } = prepareHistoryData([...innerHistory.historyOzon, ...parsedWBHistory, ...innerHistory.historyAirtable]);

    return {
      newWidgetData,
      newChartDatasource,
      updatedProduct,
      updatedProductHistoryPrice,
      updatedProductHistoryStocks,
    };
  };

  useEffect(() => {
    const productId = get(history, 'location.pathname', '//').split('/')[2];
    getAllProductsCatalogueFull()
      .then((products) => {
        const currentProduct = products.find((p) => p.id === productId);
        const productIds = `${productId},${currentProduct.linkedProducts.map((p) => p.id).join(',')}`;
        Promise.all([
          getAllOrdersOzon({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productIds }),
          getAllOrdersOzon({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
          getAllOrdersOzon({ periodFrom: periodFromMonth.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productIds }),
          getAllOrdersWB({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productIds }),
          getAllOrdersWB({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
          getAllOrdersWB({ periodFrom: periodFromMonth.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productIds }),
          getAllTransactionsOzon({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productIds }),
          getAllTransactionsOzon({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
          getAllTransactionsWB({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productIds }),
          getAllTransactionsWB({ periodFrom: moment(periodFrom).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(periodTo).subtract(periodTo.diff(periodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
          getAllProductsOzon(),
          getAllProductsWB(),
          getAllShopsOzon(),
          getAllShopsWB(),
          getProductComment({ productId }),
        ])
          .then((response) => {
            const productWbIds = response[11].filter((p) => p.productCatalogueId === currentProduct.id).map((p) => p.id).join(',');
            const productOzonIds = response[10].filter((p) => p.productCatalogueId === currentProduct.id).map((p) => p.id).join(',');
            Promise.all([
              getProductOzonHistory({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productOzonIds }),
              getProductWBHistory({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productWbIds }),
              getProductCatalogueHistory({ periodFrom: periodFrom.format('YYYY-MM-DD'), periodTo: periodTo.format('YYYY-MM-DD'), productId }),
            ])
              .then((resp) => {
                const innerOrders = {
                  ordersOzonNew: response[0],
                  ordersOzonOld: response[1],
                  ordersOzonMonth: response[2],
                  ordersWbNew: response[3],
                  ordersWbOld: response[4],
                  ordersWbMonth: response[5],
                };
                const innerTransactions = {
                  transactionsOzonNew: response[6],
                  transactionsOzonOld: response[7],
                  transactionsWbNew: response[8],
                  transactionsWbOld: response[9],
                };
                const innerProductsCatalogue = {
                  productsCatalogueOzon: response[10],
                  productsCatalogueWb: response[11],
                };
                const innerHistory = {
                  historyOzon: resp[0],
                  historyWb: resp[1],
                  historyAirtable: resp[2],
                };

                const {
                  newWidgetData,
                  newChartDatasource,
                  updatedProduct,
                  updatedProductHistoryPrice,
                  updatedProductHistoryStocks,
                } = prepareStateData(currentProduct, innerOrders, innerTransactions, innerProductsCatalogue, innerHistory, selectedMarketplaces,
                  selectedShopMarketplace, periodFrom, periodTo, periodFromMonth);


                setOrders(innerOrders);
                setTransactions(innerTransactions);
                setProductsCatalogue(innerProductsCatalogue);
                setProductHistory(innerHistory);
                setWidgetData(newWidgetData);
                setProduct(updatedProduct);
                setChartDatasource(newChartDatasource);
                setShopsMarketplace([...response[12], ...response[13]]);
                setProductHistoryStocks(updatedProductHistoryStocks);
                setProductHistoryPrice(updatedProductHistoryPrice);
                setIsloading(false);
                setAllProducts(products);
                setAllProductsFiltered(products);
                setProductComments(response[14]);
              });
          });
      });
  }, []);

  const onChangePeriod = (newPeriodFrom, newPeriodTo) => {
    setIsloading(true);
    const productIds = `${product.id},${product.linkedProducts.map((p) => p.id).join(',')}`;
    const productWbIds = productsCatalogue.productsCatalogueWb.filter((p) => p.productCatalogueId === product.id).map((p) => p.id).join(',');
    const productOzonIds = productsCatalogue.productsCatalogueOzon.filter((p) => p.productCatalogueId === product.id).map((p) => p.id).join(',');
    Promise.all([
      getAllOrdersOzon({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD'), productIds }),
      getAllOrdersOzon({ periodFrom: moment(newPeriodFrom).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(newPeriodTo).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
      getAllOrdersWB({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD'), productIds }),
      getAllOrdersWB({ periodFrom: moment(newPeriodFrom).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(newPeriodTo).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
      getAllTransactionsOzon({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD'), productIds }),
      getAllTransactionsOzon({ periodFrom: moment(newPeriodFrom).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(newPeriodTo).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
      getAllTransactionsWB({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD'), productIds }),
      getAllTransactionsWB({ periodFrom: moment(newPeriodFrom).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), periodTo: moment(newPeriodTo).subtract(newPeriodTo.diff(newPeriodFrom, 'days'), 'day').format('YYYY-MM-DD'), productIds }),
      getProductOzonHistory({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD'), productOzonIds }),
      getProductWBHistory({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD'), productWbIds }),
      getProductCatalogueHistory({ periodFrom: newPeriodFrom.format('YYYY-MM-DD'), periodTo: newPeriodTo.format('YYYY-MM-DD'), productId: product.id }),
    ])
      .then((resp) => {
        const innerOrders = {
          ordersOzonNew: resp[0],
          ordersOzonOld: resp[1],
          ordersOzonMonth: orders.ordersOzonMonth,
          ordersWbNew: resp[2],
          ordersWbOld: resp[3],
          ordersWbMonth: orders.ordersWbMonth,
        };
        const innerTransactions = {
          transactionsOzonNew: resp[4],
          transactionsOzonOld: resp[5],
          transactionsWbNew: resp[6],
          transactionsWbOld: resp[7],
        };
        const innerHistory = {
          historyOzon: resp[8],
          historyWb: resp[9],
          historyAirtable: resp[10],
        };

        const {
          newWidgetData,
          newChartDatasource,
          updatedProduct,
          updatedProductHistoryPrice,
          updatedProductHistoryStocks,
        } = prepareStateData(product, innerOrders, innerTransactions, productsCatalogue, innerHistory, selectedMarketplaces,
          selectedShopMarketplace, newPeriodFrom, newPeriodTo, periodFromMonth);


        setOrders(innerOrders);
        setTransactions(innerTransactions);
        setProductHistory(innerHistory);
        setWidgetData(newWidgetData);
        setProduct(updatedProduct);
        setChartDatasource(newChartDatasource);
        setProductHistoryStocks(updatedProductHistoryStocks);
        setProductHistoryPrice(updatedProductHistoryPrice);
        setPeriodFrom(newPeriodFrom);
        setPeriodTo(newPeriodTo);
        setIsloading(false);
      });
  };

  const onChangeMarketplace = (newSelectedMarketplaces) => {
    const {
      newWidgetData,
      newChartDatasource,
      updatedProduct,
      updatedProductHistoryPrice,
      updatedProductHistoryStocks,
    } = prepareStateData(product, orders, transactions, productsCatalogue, productHistory, newSelectedMarketplaces,
      selectedShopMarketplace, periodFrom, periodTo, periodFromMonth);
    setWidgetData(newWidgetData);
    setProduct(updatedProduct);
    setChartDatasource(newChartDatasource);
    setProductHistoryStocks(updatedProductHistoryStocks);
    setProductHistoryPrice(updatedProductHistoryPrice);
    setSelectedMarketplaces(newSelectedMarketplaces);
  };

  const onChangeShopMarketplace = () => {

  };

  const onAddNewLinkModal = () => setIsShowAddLinkModal(!isShowAddLinkModal);

  const onSearchProductForLink = (e) => {
    const { value } = e.target;
    setAllProductsFiltered(allProducts.filter((element) => element.inventoryNumber.toLowerCase().indexOf(value.toLowerCase()) >= 0
      || element.name.toLowerCase().indexOf(value.toLowerCase()) >= 0));
  };

  const onLinkCheckBoxChange = (productId, checked) => {
    const isInarray = !!linkCheckBoxChangeArray.find((p) => p.productId === productId);
    if (isInarray) {
      setLinkCheckBoxChangeArray(linkCheckBoxChangeArray.map((p) => (p.productId === productId ? { ...p, checked } : p)));
    } else {
      setLinkCheckBoxChangeArray([...linkCheckBoxChangeArray, { productId, checked }]);
    }
  };

  const onSaveAddNewLink = () => {
    setIsloading(true);
    if (linkCheckBoxChangeArray.length !== 0) {
      const toAddArrayIds = linkCheckBoxChangeArray.filter((p) => p.checked).map((p) => p.productId);
      const toRemoveArrayIds = linkCheckBoxChangeArray.filter((p) => !p.checked).map((p) => p.productId);
      const toAddArrayWithInfo = allProducts.filter((p) => toAddArrayIds.includes(p.id));
      const toRemoveArrayWithInfo = allProducts.filter((p) => toRemoveArrayIds.includes(p.id));
      const toSaveArray = [];
      toSaveArray.push({
        id: product.id,
        linkedProducts_ids: [...product.linkedProducts.filter((p) => !toRemoveArrayIds.includes(p.id)).map((p) => p.id), ...toAddArrayIds].join(','),
      });

      if (toAddArrayWithInfo.length > 0) {
        toSaveArray.push(...toAddArrayWithInfo.map((p) => ({
          id: p.id,
          linkedProducts_ids: p.linkedProducts.length > 0 ? [...p.linkedProducts.map((lp) => lp.id), product.id].join(',') : product.id,
        })));
      }
      if (toRemoveArrayWithInfo.length > 0) {
        toSaveArray.push(...toRemoveArrayWithInfo.map((p) => ({
          id: p.id,
          linkedProducts_ids: p.linkedProducts.length > 0 ? p.linkedProducts.filter((pr) => pr.id !== product.id).map((lp) => lp.id).join(',') : '',
        })));
      }
      editProductCatalogue(toSaveArray)
        .then(() => {
          if (toAddArrayIds.length > 0) {
            setIsShowAddLinkModal(false);
            setIsloading(true);
            setLinkCheckBoxChangeArray([]);
            setProduct({
              ...product,
              linkedProducts: [...product.linkedProducts.filter((p) => !toRemoveArrayIds.includes(p.id)), ...toAddArrayWithInfo],
            });
            notification.success({ message: 'Успех', description: 'Изменения сохранены!' });
          } else {
            setIsShowAddLinkModal(false);
            setIsloading(true);
            setLinkCheckBoxChangeArray([]);
            setProduct({
              ...product,
              linkedProducts: [...product.linkedProducts.filter((p) => !toRemoveArrayIds.includes(p.id)), ...toAddArrayWithInfo],
            });
            notification.success({ message: 'Успех', description: 'Изменения сохранены!' });
          }
        });
    } else {
      setIsShowAddLinkModal(false);
      setIsloading(true);
      setLinkCheckBoxChangeArray([]);
    }
  };

  const editProductCommentModal = (productComment) => setProductCommentInEdit(productComment);
  const cancelProductComment = () => setProductCommentInEdit(null);
  const saveProductComment = () => {
    setIsloading(true);
    const values = productCommentRef.current.getFieldsValue();
    const arrayToSave = [];
    if (values.products === 'solo') {
      arrayToSave.push({
        comment: values.comment,
        date: values.date ? values.date.format('YYYY-MM-DD') : null,
        author_id: getUserId(),
        product_id: product.id,
        id: md5(new Date() + Math.random()),
      });
    } else {
      product.linkedProducts.forEach((pr) => arrayToSave.push({
        comment: values.comment,
        date: values.date ? values.date.format('YYYY-MM-DD') : null,
        author_id: getUserId(),
        product_id: pr.id,
        id: md5(new Date() + Math.random()),
      }));
    }
    editProductComment(arrayToSave)
      .then(() => {
        setIsloading(false);
        setProductCommentInEdit(null);
        setProductComments(values.id
          ? productComments.map((p) => (arrayToSave.includes(p.id) ? arrayToSave.find((a) => a.id === p.id) : p))
          : [...productComments, ...arrayToSave.filter((a) => a.product_id === product.id)]);
      });
  };

  return (
    <Col span={23}>
      <ProductPageDesktop
        periodFrom={periodFrom}
        periodTo={periodTo}
        isLoading={isLoading}
        onChangePeriod={onChangePeriod}
        selectedMarketplaces={selectedMarketplaces}
        onChangeMarketplace={onChangeMarketplace}
        shopsMarketplace={shopsMarketplace}
        selectedShopMarketplace={selectedShopMarketplace}
        onChangeShopMarketplace={onChangeShopMarketplace}
        product={product}
        widgetData={widgetData}
        chartDatasource={chartDatasource}
        onAddNewLinkModal={onAddNewLinkModal}
        allProductsFiltered={allProductsFiltered}
        onSearchProductForLink={onSearchProductForLink}
        isShowAddLinkModal={isShowAddLinkModal}
        onLinkCheckBoxChange={onLinkCheckBoxChange}
        onSaveAddNewLink={onSaveAddNewLink}
        productHistoryStocks={productHistoryStocks}
        productHistoryPrice={productHistoryPrice}
        productComments={productComments}
        editProductComment={editProductCommentModal}
      />
      {productCommentInEdit && (
      <CommentModal
        cancelProductComment={cancelProductComment}
        saveProductComment={saveProductComment}
        isLoading={isLoading}
        productCommentInEdit={productCommentInEdit}
        productCommentRef={productCommentRef}
      />
      )}
    </Col>
  );
}

ProductModule.propTypes = {
  history: PropTypes.shape().isRequired,
};


export default withRouter(ProductModule);

